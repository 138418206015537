enum SectionViewControllerType
{    
    None,
    Product = "99000000-0000-1111-1111-000000000001",    
    ProductCategory = "99000000-0000-1111-1111-000000000002",
    Menus = "99000000-0000-1111-1111-000000000003",
    ProductModifiers = "99000000-0000-1111-1111-000000000004",
    POSInvoices = "99000000-0000-1111-1111-000000000005",
    POSTickets = "99000000-0000-1111-1111-000000000006",
    Logs = "99000000-0000-1111-1111-000000000007",
    CashDesk = "99000000-0000-1111-1111-000000000008",
    StockTemplates = "99000000-0000-1111-1111-000000000009",
    Client = "99000000-0000-1111-1111-000000000010",
    Worker = "99000000-0000-1111-1111-000000000011",
    Supplier = "99000000-0000-1111-1111-000000000012",
    Bookings = "99000000-0000-1111-1111-000000000013",
    NoShow = "99000000-0000-1111-1111-000000000014",
    SalesSummary = "99000000-0000-1111-1111-000000000015",
    ReportBuilder = "99000000-0000-1111-1111-000000000016",
    Stock = "99000000-0000-1111-1111-000000000017",
    WarehouseOrder = "99000000-0000-1111-1111-000000000018",
    AdvancedReports = "99000000-0000-1111-1111-000000000019",
    //Lapsopay = "99000000-0000-1111-1111-000000000020",
    PartyEvents = "99000000-0000-1111-1111-000000000021",
    Offers = "99000000-0000-1111-1111-000000000022",
    Guests = "99000000-0000-1111-1111-000000000023",
    SupplierReturnNote = "99000000-0000-1111-1111-000000000024",
    BookingIntermediary = "99000000-0000-1111-1111-000000000025",
    Events = "99000000-0000-1111-1111-000000000026",
    SupplierNote = "99000000-0000-1111-1111-000000000027",
    InventoryNote = "99000000-0000-1111-1111-000000000028",
    MovementNote = "99000000-0000-1111-1111-000000000029",
    WarehouseDelivery = "99000000-0000-1111-1111-000000000030",
    GuestLists = "99000000-0000-1111-1111-000000000031",
    PurchaseInvoices = "99000000-0000-1111-1111-000000000032",
    Settings = "99000000-0000-1111-1111-000000000033",
    //EDITOR ="99000000-0000-1111-1111-000000000034",
    OutputNote = "99000000-0000-1111-1111-000000000035",
    SupportTickets = "99000000-0000-1111-1111-000000000036",
    //BUDGET = "99000000-0000-1111-1111-000000000037",
    DeliveryNote = "99000000-0000-1111-1111-000000000038",
    ApplicationLicenses = "99000000-0000-1111-1111-000000000039",
    BusinessLicenses = "99000000-0000-1111-1111-000000000086",
    DBBackup = "99000000-0000-1111-1111-000000000040",
    DBTool = "99000000-0000-1111-1111-000000000041",
    OperationSummary = "99000000-0000-1111-1111-000000000042",
    WorkerAdmin = "99000000-0000-1111-1111-000000000043",
    //DOCUMENT TRACKING = "99000000-0000-1111-1111-000000000044",
    //VIP CARDS = "99000000-0000-1111-1111-000000000045",
    //VIP RESPONSIBLES = "99000000-0000-1111-1111-000000000046",
    Parties = "99000000-0000-1111-1111-000000000047",
    WarehouseInput = "99000000-0000-1111-1111-000000000048",
    Cards = "99000000-0000-1111-1111-000000000049",
    DBMapping = "99000000-0000-1111-1111-000000000050",
    DBSync = "99000000-0000-1111-1111-000000000051",
    SyncLog = "99000000-0000-1111-1111-000000000052",
    ProductAdmin = "99000000-0000-1111-1111-000000000053",
    UserAccess = "99000000-0000-1111-1111-000000000054",
    LowStockOrder = "99000000-0000-1111-1111-000000000055",
    SupplierOrder = "99000000-0000-1111-1111-000000000056",
    DeliveryOrder = "99000000-0000-1111-1111-000000000057",
    BankAccount = "99000000-0000-1111-1111-000000000058",
    DashBoard = "99000000-0000-1111-1111-000000000059",
    StockFlowsSummary = "99000000-0000-1111-1111-000000000060",
    OrdersSummary = "99000000-0000-1111-1111-000000000061",
    ProductMixSummary = "99000000-0000-1111-1111-000000000062",
    ClientHistorySummary = "99000000-0000-1111-1111-000000000063",
	//ReportsAccess = "99000000-0000-1111-1111-000000000064",
    WorkerSessionTool = "99000000-0000-1111-1111-000000000064",
    RoomSchedule = "99000000-0000-1111-1111-000000000065",
    RoomBooking = "99000000-0000-1111-1111-000000000066",
    DBImport = "99000000-0000-1111-1111-000000000067",
    DeliveryRoutes = "99000000-0000-1111-1111-000000000068",
    ProductRates = "99000000-0000-1111-1111-000000000069",
    ProductPresets = "99000000-0000-1111-1111-000000000070",
    Partners = "99000000-0000-1111-1111-000000000071",
    BuyOrder = "99000000-0000-1111-1111-000000000072",
    OnlineOrders = "99000000-0000-1111-1111-000000000073",
    Places = "99000000-0000-1111-1111-000000000074",
    OnlinePlatforms = "99000000-0000-1111-1111-000000000075",
    TableMap = "99000000-0000-1111-1111-000000000076",
    AppConfiguration = "99000000-0000-1111-1111-000000000077",
    WorkerClockIn = "99000000-0000-1111-1111-000000000078",
    Distributors = "99000000-0000-1111-1111-000000000079",
    Resellers = "99000000-0000-1111-1111-000000000080",
    Agents = "99000000-0000-1111-1111-000000000081",
    SalesInvoices = "99000000-0000-1111-1111-000000000082",
    DBConsumptionLog = "99000000-0000-1111-1111-000000000083",
    InputNote = "99000000-0000-1111-1111-000000000084",
    AppConsumptionProfile = "99000000-0000-1111-1111-000000000085",
    Expenses = "99000000-0000-1111-1111-000000000087",
    Loans = "99000000-0000-1111-1111-000000000088",
    SalaryPayroll = "99000000-0000-1111-1111-000000000089",
    LedgerAccountReconciliation = "99000000-0000-1111-1111-000000000090",
    PaymentInstallments = "99000000-0000-1111-1111-000000000091",
    PendingPayments = "99000000-0000-1111-1111-000000000092",
    WineCellarProducts = "99000000-0000-1111-1111-000000000093",
}

class SectionHelper extends MIOObject
{
    static get sectionCount() { return 93 };

    private static _sharedInstance: SectionHelper = null;
    static sharedInstance(): SectionHelper {

        if (this._sharedInstance == null) {
            this._sharedInstance = new SectionHelper();
            this._sharedInstance.init();
        }

        return this._sharedInstance;
    }

    // init(){
    //     super.init();
    //     MIONotificationCenter.defaultCenter().addObserver(this, "WorkspaceDidChange", function(this:SectionHelper, notification:MIONotification){            
    //         this.reset();
    //     });
    // }

    private sectionListViewController:SectionListViewController = null;
    sectionsViewController(){
        if (this.sectionListViewController != null) return this.sectionListViewController;

        this.sectionListViewController = new SectionListViewController("section-list-view");
        this.sectionListViewController.initWithResource("layout/sections/SectionListView.html");
        this.sectionListViewController.modalPresentationStyle = MUIModalPresentationStyle.Custom;

        return this.sectionListViewController;
    }

    sectionViewControllerForType(type):MUIViewController {

        switch (type) {
            case SectionViewControllerType.DashBoard:           return this.dashBoardViewController();
            case SectionViewControllerType.OperationSummary:    return this.operationSummaryViewController();
            case SectionViewControllerType.SalesSummary:        return this.salesSummaryViewController();
            case SectionViewControllerType.StockFlowsSummary:   return this.stockFlowsSummaryViewController();
            case SectionViewControllerType.OrdersSummary:       return this.ordersSummaryViewController();
            case SectionViewControllerType.ProductMixSummary:   return this.productMixSummaryViewController();    
            case SectionViewControllerType.ClientHistorySummary: return this.clientHistorySummaryViewController();  
            case SectionViewControllerType.AdvancedReports:     return this.advancedReportsViewController();
            case SectionViewControllerType.Logs:                return this.logsViewController();

            // ACOUNTING
            case SectionViewControllerType.CashDesk:            return this.cashdeskViewController();
            case SectionViewControllerType.POSTickets:          return this.ticketsViewController();
            case SectionViewControllerType.POSInvoices:         return this.issuedInvoicesViewController();
            case SectionViewControllerType.SalesInvoices:       return this.salesInvoicesViewController();
            case SectionViewControllerType.PurchaseInvoices:    return this.purchaseInvoicesViewController();
            case SectionViewControllerType.BankAccount:         return this.banksViewController();
            case SectionViewControllerType.LedgerAccountReconciliation: return this.ledgerAccountReconciliationViewController();
            case SectionViewControllerType.PaymentInstallments: return this.paymentInstallmentsViewController();
            case SectionViewControllerType.PendingPayments:     return this.pendingPaymentsViewController();
            case SectionViewControllerType.Loans:               return this.loansViewController();
                
            // USERS
            case SectionViewControllerType.Client:              return this.clientsViewController();            
            case SectionViewControllerType.Worker:              return this.workersViewController();
            case SectionViewControllerType.WorkerAdmin:         return this.workersAdminViewController();
            case SectionViewControllerType.UserAccess:          return this.userAccessViewController();
            case SectionViewControllerType.Cards:               return this.cardsViewController();			
			case SectionViewControllerType.WorkerSessionTool:   return this.workerSessionToolViewController();
            case SectionViewControllerType.Partners:            return this.partnersViewController();    
            case SectionViewControllerType.WorkerClockIn:       return this.clockinViewController();

            // PRODUCTS            
            case SectionViewControllerType.Product:             return this.productsViewController();
            case SectionViewControllerType.ProductCategory:     return this.categoriesViewController();            
            case SectionViewControllerType.ProductModifiers:    return this.productModifiersViewController();
            case SectionViewControllerType.Menus:               return this.menusViewController();
            case SectionViewControllerType.ProductAdmin:        return this.productComponentsViewController();
            case SectionViewControllerType.Offers:              return this.offersViewController();
            case SectionViewControllerType.ProductRates:        return this.productRatesViewController();
            case SectionViewControllerType.ProductPresets:      return this.productPresetsViewController();
            case SectionViewControllerType.WineCellarProducts:  return this.wineCellarProductsViewController();

            // BOOKINGS
            case SectionViewControllerType.Bookings:            return this.bookingsViewController();
            case SectionViewControllerType.NoShow:              return this.noShowViewController();
            case SectionViewControllerType.Guests:              return this.guestsViewController();
            case SectionViewControllerType.GuestLists:          return this.guestListViewController();
            case SectionViewControllerType.BookingIntermediary: return this.bookingIntermediaryViewController();
            case SectionViewControllerType.Events:              return this.eventsViewController();
            case SectionViewControllerType.Parties:             return this.partiesViewController();
            case SectionViewControllerType.PartyEvents:         return this.partyEventsViewController();

            // STOCK
            case SectionViewControllerType.Supplier:            return this.suppliersViewController();
            case SectionViewControllerType.Stock:               return this.stockViewController();            
            case SectionViewControllerType.InventoryNote:       return this.inventoryNotesViewController();
            case SectionViewControllerType.StockTemplates:      return this.inventoryTemplateViewController();        
            case SectionViewControllerType.MovementNote:        return this.movementNotesViewController();
            case SectionViewControllerType.OutputNote:          return this.outputNotesViewController();
            case SectionViewControllerType.InputNote:           return this.inputNotesViewController();
            case SectionViewControllerType.LowStockOrder:       return this.lowStockProductViewController();
            case SectionViewControllerType.BuyOrder:            return this.buyOrdersViewController();
            case SectionViewControllerType.SupplierOrder:       return this.supplierOrdersViewController();
            case SectionViewControllerType.SupplierNote:        return this.supplierNotesViewController();
            case SectionViewControllerType.SupplierReturnNote:  return this.supplierReturnNotesViewController();
            case SectionViewControllerType.DeliveryNote:        return this.deliveryNotesViewController();
            case SectionViewControllerType.WarehouseOrder:      return this.warehouseOrdersViewController();
            case SectionViewControllerType.WarehouseDelivery:   return this.warehouseDeliveryViewController();
            case SectionViewControllerType.WarehouseInput:      return this.internalReturnOrderViewController();                       

            // ONLINE
            case SectionViewControllerType.DeliveryRoutes:      return this.deliveryRoutesController();
            case SectionViewControllerType.OnlinePlatforms:     return this.onlinePlatformsViewController();
            case SectionViewControllerType.OnlineOrders:        return this.onlineOrdersViewController();

            // CONFIGURATION
            case SectionViewControllerType.Settings:            return this.settingsViewController();
            case SectionViewControllerType.Places:              return this.placesViewController();
            case SectionViewControllerType.DBBackup:            return this.dbBackupToolViewController();
            case SectionViewControllerType.DBTool:              return this.dbToolViewController();
            case SectionViewControllerType.DBMapping:           return this.dbMappingViewController();
            case SectionViewControllerType.DBSync:              return this.dbSyncViewController();
            case SectionViewControllerType.SyncLog:             return this.syncLogViewController();
            case SectionViewControllerType.DBImport:            return this.dbImportViewController();
            case SectionViewControllerType.DBConsumptionLog:    return this.dbConsumptionLogViewController();
            case SectionViewControllerType.ReportBuilder:       return this.reportBuilderViewController();
            case SectionViewControllerType.AppConfiguration:    return this.appConfigurationViewController();
            case SectionViewControllerType.TableMap:            return this.tableMapViewController();
            case SectionViewControllerType.AppConsumptionProfile: return this.appConsumptionProfileViewController();

            // DUAL LINK
            case SectionViewControllerType.ApplicationLicenses: return this.licensesViewController();
            case SectionViewControllerType.BusinessLicenses:    return this.businessLicensesViewController();
            case SectionViewControllerType.SupportTickets:      return this.supportTicketsViewController();

            // ROOMS
            case SectionViewControllerType.RoomSchedule:        return this.roomScheduleController();
            case SectionViewControllerType.RoomBooking:         return this.roomBookingController();

            // INTERMEDIARIES
            case SectionViewControllerType.Distributors:        return this.distributorsViewController();
            case SectionViewControllerType.Agents:              return this.agentsViewController();
        }

        let vc = new BaseViewController("section-none-view");        
        vc.initWithResource("layout/base/SectionNoneView.html");

        return vc;
    }

    reset(){
        this._dashboardViewController = null;        
        this._advancedReportsViewController = null;
        this._operationSummaryViewController = null;
        this._salesSummaryViewController = null;
        this._productMixSummaryViewController = null;
        this._stockflowsSummaryViewController = null;
        this._ordersSummaryViewController = null;
        this._clientHistorySummaryViewController = null;
        this._logViewController = null;
        this._cashdeskViewController = null;
        this._ticketsViewController = null;
        this._issuedInvoiceViewController = null;
        this._clientViewController = null;
        this._workersViewController = null;
		this._workersAdminViewController = null;
		this._workerSessionToolViewController = null;
        this._userAccessViewController = null;
        //this._reportsAccessViewController = null;
        this._cardListViewController = null;
        this._productViewController = null;
        this._productModifiersViewController = null;
        this._menusViewController = null;
        this._categoriesViewController = null;
        this._productComponentViewController = null;
        this._offerViewController = null;
        this._bookingViewController = null;
        this._noShowViewController = null;
        this._guestViewController = null;
        this._guestListViewController = null;
        this._bookingIntermediaryViewController = null;
        this._eventsViewController = null;
        this._partyViewController = null;
        this._supplierViewController = null;
        this._onlineOrdersViewController = null;
        this._onlinePlatformsViewController = null;
        this._deliveryRoutesController = null;
        this._stockViewController = null;
        this._inventoryNoteViewController = null;
        this._movementNoteViewController = null;
        this._outputNoteViewController = null;
        this._lowStockProductsViewController = null;
        this._supplierOrderViewController = null;
        this._supplierNoteViewController = null;
        this._deliveryNoteViewController = null;
        this._warehouseDeliveryViewController = null;
        this._warehouseOrderViewController = null;
        this._appConsumptionProfileViewController = null;
        this._settingsViewController = null;
        this._dbBackupToolViewController = null;
        this._dbToolViewController = null;
        this._dbSyncViewController = null;
        this._dbMappingViewController = null;
        this._syncLogViewController = null;
        this._dbImportViewController = null;
        this._dbConsumptionLogViewController = null;
        this._reportBuilderViewController = null;
        this._licensesViewController = null;
        this._productRatesViewController = null;
        this._productPresetsViewController = null;
        // this._installmentsViewcontroller = null;
        this._pendingPaymentsViewController = null;
        this._paymentIntallmentsViewController = null;
        this._wineCellarProductsViewController = null;
    }

    get canSeeDashBoard():boolean{
        let permissions = SettingsHelper.sharedInstance().permisisons;
        let value = permissions[3]; // Dashboard
        return (value == "1");
    }

    private _dashboardViewController:MUINavigationController= null;
    private dashBoardViewController() {
        if (this._dashboardViewController != null) return this._dashboardViewController;

        let vc = new ReportViewController("report-detail-view");
        vc.initWithResource("layout/reports/ReportDetailView.html");
        vc.title = MIOLocalizeString("DASHBOARD", "DASHBOARD");
        vc.reportScriptPath = "python/0_dashboard.py";
        vc.showSectionButton = true;

        // let vc = new DashboardViewController('dashboard-view');
        // vc.initWithResource('layout/dashboard/DashboardView.html');

        // let vc = new ReportViewController("report-detail-view");
        // vc.initWithResource("layout/reports/ReportDetailView.html");
        // vc.title = MIOLocalizeString("DASHBOARD", "DASHBOARD");
        // //this._operationSummaryViewController.reportID = "C1E32789-9434-41C2-8364-2D6D1CF951DD";
        // vc.reportScriptPath = "python/dashboard.py";
        // vc.showSectionButton = true;

        let nc = new MUINavigationController();
        nc.initWithRootViewController(vc);

        this._dashboardViewController = nc;
        return this._dashboardViewController;
    }


    private summaryReportViewController(title:string, id:string) : ReportViewController {
        let vc = new ReportViewController("report-detail-view");
        vc.initWithResource("layout/reports/ReportDetailView.html");
        vc.title = MIOLocalizeString(title, title);
        vc.reportID = id;
        vc.showSectionButton = true;

        return vc;
    }

    private _operationSummaryViewController:MUINavigationController = null;
    private operationSummaryViewController(){
        if (this._operationSummaryViewController != null) return this._operationSummaryViewController;

        let vc = this.summaryReportViewController("OPERATION SUMMARY", "99000000-0000-2222-1111-000000000001");        
        this._operationSummaryViewController = new MUINavigationController();
        this._operationSummaryViewController.initWithRootViewController(vc);

        return this._operationSummaryViewController;
    }

    private _salesSummaryViewController:ReportViewController = null;
    private salesSummaryViewController(){
        if (this._salesSummaryViewController != null) return this._salesSummaryViewController;

        let vc = this.summaryReportViewController("SALES SUMMARY", "99000000-0000-2222-1111-000000000002");        
        this._salesSummaryViewController = vc;
        
        return this._salesSummaryViewController;
    }

    private _stockflowsSummaryViewController:MUINavigationController = null;
    private stockFlowsSummaryViewController(){
        if (this._stockflowsSummaryViewController != null) return this._stockflowsSummaryViewController;

        let vc = this.summaryReportViewController("STOCKFLOWS SUMMARY", "99000000-0000-2222-1111-000000000003");
        this._stockflowsSummaryViewController = new MUINavigationController();
        this._stockflowsSummaryViewController.initWithRootViewController(vc);
        
        return this._stockflowsSummaryViewController;
    }

    private _ordersSummaryViewController:ReportViewController = null;
    private ordersSummaryViewController(){
        if (this._ordersSummaryViewController != null) return this._ordersSummaryViewController;

        let vc = this.summaryReportViewController("ORDERS SUMMARY", "99000000-0000-2222-1111-000000000004");
        this._ordersSummaryViewController = vc;
        
        return this._ordersSummaryViewController;
    }

    private _productMixSummaryViewController:MUINavigationController = null;
    private productMixSummaryViewController(){
        if (this._productMixSummaryViewController != null) return this._productMixSummaryViewController;

        let vc = this.summaryReportViewController("PRODUCT MIX SUMMARY", "99000000-0000-2222-1111-000000000005");
        this._productMixSummaryViewController = new MUINavigationController();
        this._productMixSummaryViewController.initWithRootViewController(vc);
        
        return this._productMixSummaryViewController;
    }

    private _clientHistorySummaryViewController:MUINavigationController = null;
    private clientHistorySummaryViewController(){
        if (this._clientHistorySummaryViewController != null) return this._clientHistorySummaryViewController;

        let vc = this.summaryReportViewController("CLIENT HISTORY SUMMARY", "99000000-0000-2222-1111-000000000006");        
        this._clientHistorySummaryViewController = new MUINavigationController();
        this._clientHistorySummaryViewController.initWithRootViewController(vc);

        return this._clientHistorySummaryViewController;
	}
	
	private _workerSessionToolViewController:MUIViewController = null;
	private workerSessionToolViewController() {
		if (this._workerSessionToolViewController != null) return this._workerSessionToolViewController;

		this._workerSessionToolViewController = new ReportViewController("report-summary-view");
        this._workerSessionToolViewController.initWithResource("layout/workers/WorkerSessionToolView.html");
                
        return this._workersViewController;
	}    

    private _advancedReportsViewController = null;
    private advancedReportsViewController() {
        if (this._advancedReportsViewController != null) return this._advancedReportsViewController;
        
        let mvc = new ReportListViewController("reports-list-view");
        mvc.initWithResource("layout/reports/ReportsListView.html");

        let dvc = AppHelper.sharedInstance().emptyViewControllerForIndentifier("Report", NoItemSelectedViewControllerType.Report);        

        this._advancedReportsViewController = new MUISplitViewController();
        this._advancedReportsViewController.init();    
        this._advancedReportsViewController.setMasterViewController(mvc);
        this._advancedReportsViewController.setDetailViewController(dvc);    

        return this._advancedReportsViewController;
    }

    private _logViewController:LogViewController = null;
    private logsViewController():LogViewController{
        if (this._logViewController != null) return this._logViewController;

        if (MIOCoreIsPhone() == true) {
            this._logViewController = new LogViewController("log-view_phone");
            this._logViewController.initWithResource("layout/log/LogView_phone.html");    
        }
        else {
            this._logViewController = new LogViewController("log-view");
            this._logViewController.initWithResource("layout/log/LogView.html");    
        }

        return this._logViewController;
    }

    //
    // ACOUNTING
    // 

    private _cashdeskViewController = null;    
    private cashdeskViewController(){        
        if (this._cashdeskViewController != null) return this._cashdeskViewController;

        let mvc = new CashDeskListViewController("cash-desk-list-view");
        mvc.initWithResource("layout/cashdesk/CashDeskListView.html");

        // let dvc = AppHelper.sharedInstance().emptyViewControllerForIndentifier("CashDesk", NoItemSelectedViewControllerType.CashDesk);
        let vc = new CashDeskDetailViewController("cash-desk-detail-view");
        vc.initWithResource("layout/cashdesk/CashDeskDetailView.html");            

        let dvc = new MUINavigationController();
        dvc.initWithRootViewController(vc);

        this._cashdeskViewController = new MUISplitViewController();
        this._cashdeskViewController.init();     
        this._cashdeskViewController.setMasterViewController(mvc);
        this._cashdeskViewController.setDetailViewController(dvc);            
        
        return this._cashdeskViewController;
    }

    private _ticketsViewController = null;    
    private ticketsViewController(){
        if (this._ticketsViewController != null) return this._ticketsViewController;
        
        let mvc = ArchivedDocumentListViewController.newInstance();
        mvc.documentType = ArchivedDocumentType.receipt;

        let dvc = AppHelper.sharedInstance().emptyViewControllerForIndentifier("Ticket", NoItemSelectedViewControllerType.Ticket);

        this._ticketsViewController = new MUISplitViewController();
        this._ticketsViewController.init();
        this._ticketsViewController.setMasterViewController(mvc);
        this._ticketsViewController.setDetailViewController(dvc);

        return this._ticketsViewController;
    }

    private _issuedInvoiceViewController:MUISplitViewController = null;    
    private issuedInvoicesViewController(){    
        if (this._issuedInvoiceViewController != null) return this._issuedInvoiceViewController;
        
        let mvc = ArchivedDocumentListViewController.newInstance();
        mvc.documentType = ArchivedDocumentType.invoice;

        let dvc = AppHelper.sharedInstance().emptyViewControllerForIndentifier("Invoice", NoItemSelectedViewControllerType.Invoice);

        this._issuedInvoiceViewController = new MUISplitViewController();
        this._issuedInvoiceViewController.init();
        this._issuedInvoiceViewController.setMasterViewController(mvc);
        this._issuedInvoiceViewController.setDetailViewController(dvc);

        return this._issuedInvoiceViewController;
    }

    private _salesInvoicesViewController:MUISplitViewController = null;    
    private salesInvoicesViewController(){    
        if (this._salesInvoicesViewController != null) return this._salesInvoicesViewController;
        
        let mvc = SalesInvoiceListViewController.newInstance();
        let dvc = AppHelper.sharedInstance().emptyViewControllerForIndentifier("Invoice", NoItemSelectedViewControllerType.Invoice);

        this._salesInvoicesViewController = new MUISplitViewController();
        this._salesInvoicesViewController.init();
        this._salesInvoicesViewController.setMasterViewController(mvc);
        this._salesInvoicesViewController.setDetailViewController(dvc);        

        return this._salesInvoicesViewController;
    }

    private _purchaseInvoicesViewController:MUISplitViewController = null;    
    private purchaseInvoicesViewController(){    
        if (this._purchaseInvoicesViewController != null) return this._purchaseInvoicesViewController;
        
        let mvc = PurchaseInvoiceListViewController.newInstance();
        let dvc = AppHelper.sharedInstance().emptyViewControllerForIndentifier("Invoice", NoItemSelectedViewControllerType.Invoice);

        this._purchaseInvoicesViewController = new MUISplitViewController();
        this._purchaseInvoicesViewController.init();
        this._purchaseInvoicesViewController.setMasterViewController(mvc);
        this._purchaseInvoicesViewController.setDetailViewController(dvc);        

        return this._purchaseInvoicesViewController;
    }

    private _banksViewController:MUISplitViewController = null;    
    private banksViewController(){    
        if (this._banksViewController != null) return this._banksViewController;
        
        let mvc = BankListViewController.newInstance();
        let dvc = AppHelper.sharedInstance().emptyViewControllerForIndentifier("BankAccount", NoItemSelectedViewControllerType.BankAccount);

        this._banksViewController = new MUISplitViewController();
        this._banksViewController.init();
        this._banksViewController.setMasterViewController(mvc);
        this._banksViewController.setDetailViewController(dvc);        

        return this._banksViewController;
    }
    
    private ledgerAccountReconciliationViewController(){
        return LedgerAccountReconciliationViewController.newInstance();
    }
    
    private paymentInstallmentsViewController() : PaymentInstallmentViewController {                 
        return PaymentInstallmentViewController.newInstance();
    }
    
    private pendingPaymentsViewController() : MUIViewController {
        return PendingPaymentsViewController.newInstance();
    }

    private loansViewController() : MUISplitViewController 
    {                
        let mvc = LoanListViewController.newInstance();
        let dvc = AppHelper.sharedInstance().emptyViewControllerForIndentifier("Loan", NoItemSelectedViewControllerType.Loan);    
        return this.newSplitViewController(mvc, dvc);
    }
    
    private onlineOrdersViewController() : MUISplitViewController
    {        
        let mvc = new OnlineOrderListViewController("online-order-list-view");
        mvc.initWithResource("layout/online_orders/OnlineOrderListView.html");

        let dvc = AppHelper.sharedInstance().emptyViewControllerForIndentifier("OnlineOrder", NoItemSelectedViewControllerType.OnlineOrder);
        
        return this.newSplitViewController(mvc, dvc);
    }


    private _onlinePlatformsViewController:OnlinePlatformViewController = null;    
    private onlinePlatformsViewController() {
        if (this._onlinePlatformsViewController != null) return this._onlinePlatformsViewController;

        this._onlinePlatformsViewController = new OnlinePlatformViewController('online-platform-view');
        this._onlinePlatformsViewController.initWithResource("layout/online_platforms/OnlinePlatformView.html");

        return this._onlinePlatformsViewController;
    }


    private newSplitViewController(masterViewController:MUIViewController, detailViewController:MUIViewController):MUISplitViewController 
    {
        let vc = new MUISplitViewController();
        vc.init();
        vc.setMasterViewController(masterViewController);
        vc.setDetailViewController(detailViewController);

        return vc;
    }

    //
    // USERS
    //

    private _clientViewController:MUISplitViewController = null;    
    private clientsViewController(){
        if (this._clientViewController != null) return this._clientViewController;

        let mvc = new ClientsListViewController("clients-list-view");
        mvc.initWithResource("layout/clients/ClientsListView.html");
        
        let dvc = AppHelper.sharedInstance().emptyViewControllerForIndentifier("Client", NoItemSelectedViewControllerType.Client);

        this._clientViewController = new MUISplitViewController();
        this._clientViewController.init();
        this._clientViewController.setMasterViewController(mvc);
        this._clientViewController.setDetailViewController(dvc);

        return this._clientViewController;
    }

    private _workersViewController:MUISplitViewController = null;    
    private workersViewController(){
        if (this._workersViewController != null) return this._workersViewController;
        
        let mvc = new WorkerListViewController("workers-list-view");
        mvc.initWithResource("layout/workers/WorkersListView.html");

        let dvc = AppHelper.sharedInstance().emptyViewControllerForIndentifier("Workers", NoItemSelectedViewControllerType.Worker);

        this._workersViewController = new MUISplitViewController();
        this._workersViewController.init();
        this._workersViewController.setMasterViewController(mvc);
        this._workersViewController.setDetailViewController(dvc);
                
        return this._workersViewController;
    }

    private _workersAdminViewController:WorkerAdminViewController = null;    
    private workersAdminViewController() {
        if (this._workersAdminViewController != null) return this._workersAdminViewController;

        this._workersAdminViewController = new WorkerAdminViewController('worker-admin-view');
        this._workersAdminViewController.initWithResource("layout/workers/WorkerAdminView.html");

        return this._workersAdminViewController;
    }

    private _userAccessViewController:UserAccessViewController = null;    
    private userAccessViewController(){
        if (this._userAccessViewController != null) return this._userAccessViewController;

        this._userAccessViewController = UserAccessViewController.newInstance();
        return this._userAccessViewController;
    }

    /*private _reportsAccessViewController:ReportsAccessViewController = null;    
    private reportsAccessViewController(){
        if (this._reportsAccessViewController != null) return this._reportsAccessViewController;

        this._reportsAccessViewController = new ReportsAccessViewController('users-view');
        this._reportsAccessViewController.initWithResource("layout/users/UsersView.html");        

        return this._reportsAccessViewController;
    }*/

    private _cardListViewController:CardListViewController = null;    
    private cardsViewController() {
        if (this._cardListViewController != null) return this._cardListViewController;

        this._cardListViewController = new CardListViewController('cards-list-view');
        this._cardListViewController.initWithResource("layout/cards/CardsListView.html");

        return this._cardListViewController;
    }
    
    private _partnersViewController:MUISplitViewController = null;    
    private partnersViewController(){
        if (this._partnersViewController != null) return this._partnersViewController;
        
        let mvc = new PartnerListViewController("partner-list-view");
        mvc.initWithResource("layout/partner/PartnerListView.html");

        let dvc = AppHelper.sharedInstance().emptyViewControllerForIndentifier("Partner", NoItemSelectedViewControllerType.Party);

        this._partnersViewController = new MUISplitViewController();
        this._partnersViewController.init();
        this._partnersViewController.setMasterViewController(mvc);
        this._partnersViewController.setDetailViewController(dvc);
                
        return this._partnersViewController;
    }


    //
    // PRODUCTS
    //

    private _productViewController:MUISplitViewController = null;    
    private productsViewController(){
        if (this._productViewController != null) return this._productViewController;

        let mvc = new ProductListViewController("products-list-view");
        mvc.initWithResource("layout/products/ProductsListView.html");

        let nc = new MUINavigationController();
        nc.initWithRootViewController(mvc);

        let dvc = AppHelper.sharedInstance().emptyViewControllerForIndentifier("Product", NoItemSelectedViewControllerType.Product);

        this._productViewController = new MUISplitViewController();
        this._productViewController.init();
        this._productViewController.setMasterViewController(nc);
        this._productViewController.setDetailViewController(dvc);

        return this._productViewController;        
    }

    private _categoriesViewController:MUISplitViewController = null;    
    private categoriesViewController() {        
        if (this._categoriesViewController != null) return this._categoriesViewController;

        let mvc = new ProductCategoryListViewController("product-category-list-view");
        mvc.initWithResource("layout/productcategory/ProductCategoryListView.html");

        let dvc = AppHelper.sharedInstance().emptyViewControllerForIndentifier("ProductCategory", NoItemSelectedViewControllerType.ProductCategory);

        this._categoriesViewController = new MUISplitViewController();
        this._categoriesViewController.init();
        this._categoriesViewController.setMasterViewController(mvc);
        this._categoriesViewController.setDetailViewController(dvc);

        return this._categoriesViewController;
    }

    private _productModifiersViewController:MUISplitViewController = null;    
    private productModifiersViewController() {        
        if (this._productModifiersViewController != null) return this._productModifiersViewController;
        
        let nc = new MUINavigationController();
        nc.initWithRootViewController( ProductModifiersListViewController.newInstance() );

        let dvc = AppHelper.sharedInstance().emptyViewControllerForIndentifier("Product modifiers", NoItemSelectedViewControllerType.ProductModifiers);

        this._productModifiersViewController = new MUISplitViewController();
        this._productModifiersViewController.init();
        this._productModifiersViewController.setMasterViewController(nc);
        this._productModifiersViewController.setDetailViewController(dvc);

        return this._productModifiersViewController;

    }

    private _menusViewController:MUISplitViewController = null;    
    private menusViewController() {        
        if (this._menusViewController != null) return this._menusViewController;

        let mvc = MenuListViewController.newInstance();        

        let nc = new MUINavigationController();
        nc.initWithRootViewController(mvc);

        let dvc = AppHelper.sharedInstance().emptyViewControllerForIndentifier("Menus", NoItemSelectedViewControllerType.Menus);

        this._menusViewController = new MUISplitViewController();
        this._menusViewController.init();
        this._menusViewController.setMasterViewController(nc);
        this._menusViewController.setDetailViewController(dvc);

        return this._menusViewController;

    }

    private _productComponentViewController:ProductAdminListViewController = null;    
    private productComponentsViewController(){
        if (this._productComponentViewController != null) return this._productComponentViewController;

        this._productComponentViewController = new ProductAdminListViewController('product-admin-view');
        this._productComponentViewController.initWithResource("layout/productadmin/ProductAdminView.html");

        return this._productComponentViewController;
    }

    private _offerViewController:MUISplitViewController = null;    
    private offersViewController(){
        if (this._offerViewController != null) return this._offerViewController;
        
        let mvc = new OffersListViewController("offer-list-view");
        mvc.initWithResource("layout/offers/OffersListView.html");

        let dvc = AppHelper.sharedInstance().emptyViewControllerForIndentifier("Offer", NoItemSelectedViewControllerType.Offer);

        this._offerViewController = new MUISplitViewController();
        this._offerViewController.init();
        this._offerViewController.setMasterViewController(mvc);
        this._offerViewController.setDetailViewController(dvc);

        return this._offerViewController;
    }

    private _productRatesViewController:MUISplitViewController = null;    
    private productRatesViewController(){
        if (this._productRatesViewController != null) return this._productRatesViewController;

        let mvc = new ProductRatesListViewController("product-rates-list-view");
        mvc.initWithResource("layout/productrates/ProductRatesListView.html");

        let nc = new MUINavigationController();
        nc.initWithRootViewController(mvc);

        let dvc = AppHelper.sharedInstance().emptyViewControllerForIndentifier("ProductRates", NoItemSelectedViewControllerType.ProductRates);

        this._productRatesViewController = new MUISplitViewController();
        this._productRatesViewController.init();
        this._productRatesViewController.setMasterViewController(nc);
        this._productRatesViewController.setDetailViewController(dvc);

        return this._productRatesViewController;        
    }

    private _productPresetsViewController:MUISplitViewController = null;    
    private productPresetsViewController(){
        if (this._productPresetsViewController != null) return this._productPresetsViewController;

        let mvc = new ProductPresetListViewController("product-presets-list-view");
        mvc.initWithResource("layout/productpresets/ProductPresetsListView.html");

        let dvc = AppHelper.sharedInstance().emptyViewControllerForIndentifier("ProductPresets", NoItemSelectedViewControllerType.ProductPresets);

        this._productPresetsViewController = new MUISplitViewController();
        this._productPresetsViewController.init();
        this._productPresetsViewController.setMasterViewController(mvc);
        this._productPresetsViewController.setDetailViewController(dvc);

        return this._productPresetsViewController;        
    }

    //
    // BOOKINGS
    //

    private _bookingViewController:BookingsViewController = null;
    private bookingsViewController() {
        if (this._bookingViewController != null) return this._bookingViewController;

        this._bookingViewController = new BookingsViewController();
        this._bookingViewController.init()
        
        return this._bookingViewController;
    }

    private _noShowViewController:NoShowViewController = null;
    private noShowViewController() {
        if (this._noShowViewController != null) return this._noShowViewController;

        this._noShowViewController = new NoShowViewController("noshow-view");
        this._noShowViewController.initWithResource("layout/bookings/noshow/NoShowView.html");

        return this._noShowViewController;
    }

    private _guestViewController:GuestViewController = null;
    private guestsViewController() {
        if (this._guestViewController != null) return this._guestViewController;

        this._guestViewController = new GuestViewController("guest-view");
        this._guestViewController.initWithResource("layout/bookings/guest/GuestView.html");

        return this._guestViewController;
    }

    private _guestListViewController:MUISplitViewController = null;
    private guestListViewController() {
        if (this._guestListViewController != null) return this._guestListViewController;

        let mvc = new GuestlistListViewController("guestlist-list-view");
        mvc.initWithResource("layout/bookings/guestlist/GuestlistListView.html");

        let dvc = AppHelper.sharedInstance().emptyViewControllerForIndentifier("GuestList", NoItemSelectedViewControllerType.GuestList);

        this._guestListViewController = new MUISplitViewController("guestlist-split-controller");
        this._guestListViewController.init();
        this._guestListViewController.setMasterViewController(mvc);
        this._guestListViewController.setDetailViewController(dvc);

        return this._guestListViewController;
    }
        
    private _bookingIntermediaryViewController:MUISplitViewController = null;        
    private bookingIntermediaryViewController() {
        if (this._bookingIntermediaryViewController != null) return this._bookingIntermediaryViewController;

        let mvc = BookingIntermediaryListViewController.newInstance();        
        let dvc = AppHelper.sharedInstance().emptyViewControllerForIndentifier("BookingIntermediary", NoItemSelectedViewControllerType.BookingIntermediary)        

        this._bookingIntermediaryViewController = new MUISplitViewController("booking-intermediary-split-controller");
        this._bookingIntermediaryViewController.init();
        this._bookingIntermediaryViewController.setMasterViewController(mvc);
        this._bookingIntermediaryViewController.setDetailViewController(dvc);

        return this._bookingIntermediaryViewController;
    }

    private _eventsViewController:MUISplitViewController = null;
    private eventsViewController() {
        if (this._eventsViewController != null) return this._eventsViewController;

        let mvc = new EventListViewController("event-list-view");
        mvc.initWithResource("layout/event/EventListView.html");

        let dvc = AppHelper.sharedInstance().emptyViewControllerForIndentifier("Events", NoItemSelectedViewControllerType.Events);

        this._eventsViewController = new MUISplitViewController("event-split-controller");
        this._eventsViewController.init();
        this._eventsViewController.setMasterViewController(mvc);
        this._eventsViewController.setDetailViewController(dvc);

        return this._eventsViewController;
    }

    private _partyViewController:MUISplitViewController = null;    
    private partiesViewController() {
        if (this._partyViewController != null) return this._partyViewController;

        let mvc = new PartyListViewController('party-list-view');
        mvc.initWithResource("layout/party/PartyListView.html");

        let dvc = AppHelper.sharedInstance().emptyViewControllerForIndentifier("Parties", NoItemSelectedViewControllerType.Party);        

        this._partyViewController = new MUISplitViewController();
        this._partyViewController.init();
        this._partyViewController.setMasterViewController(mvc);
        this._partyViewController.setDetailViewController(dvc);

        return this._partyViewController;
    }

    private _partyEventsViewController:MUISplitViewController = null;    
    private partyEventsViewController() {
        if (this._partyEventsViewController != null) return this._partyEventsViewController;

        let mvc = PartyEventListViewController.newInstance();
        let dvc = AppHelper.sharedInstance().emptyViewControllerForIndentifier("PartyEvents", NoItemSelectedViewControllerType.Party);

        this._partyEventsViewController = new MUISplitViewController();
        this._partyEventsViewController.init();
        this._partyEventsViewController.setMasterViewController(mvc);
        this._partyEventsViewController.setDetailViewController(dvc);

        return this._partyEventsViewController;
    }


    //
    // ONLINE
    //

    private _deliveryRoutesController:MUISplitViewController = null;    
    private deliveryRoutesController(){
        if (this._deliveryRoutesController != null) return this._deliveryRoutesController;

        let mvc = new DeliveryRoutesListViewController("delivery-routes-list-view");
        mvc.initWithResource("layout/deliveryroutes/DeliveryRoutesListView.html");

        let nc = new MUINavigationController();
        nc.initWithRootViewController(mvc);

        let dvc = AppHelper.sharedInstance().emptyViewControllerForIndentifier("DeliveryRoutes", NoItemSelectedViewControllerType.DeliveryRoutes);

        this._deliveryRoutesController = new MUISplitViewController();
        this._deliveryRoutesController.init();
        this._deliveryRoutesController.setMasterViewController(nc);
        this._deliveryRoutesController.setDetailViewController(dvc);

        return this._deliveryRoutesController;        
    }

    //
    // STOCK
    //

    private _supplierViewController:MUISplitViewController = null;    
    private suppliersViewController() {        
        if (this._supplierViewController != null) return this._supplierViewController;

        let mvc = new SupplierListViewController("supplier-list-view");
        mvc.initWithResource("layout/supplier/SupplierListView.html");

        let dvc = AppHelper.sharedInstance().emptyViewControllerForIndentifier("Supplier", NoItemSelectedViewControllerType.Supplier);

        this._supplierViewController = new MUISplitViewController();
        this._supplierViewController.init();
        this._supplierViewController.setMasterViewController(mvc);
        this._supplierViewController.setDetailViewController(dvc);

        return this._supplierViewController;
    }
    
    private _stockProductsViewController:MUISplitViewController = null;    
    private stockProductsViewController(){
        if (this._stockProductsViewController != null) return this._stockProductsViewController;

        let mvc = new ProductListViewController("products-list-view");
        mvc.initWithResource("layout/products/ProductsListView.html");
        mvc.productType = ProductType.Stock;

        let nc = new MUINavigationController();
        nc.initWithRootViewController(mvc);

        let dvc = AppHelper.sharedInstance().emptyViewControllerForIndentifier("Product", NoItemSelectedViewControllerType.Product);

        this._stockProductsViewController = new MUISplitViewController();
        this._stockProductsViewController.init();
        this._stockProductsViewController.setMasterViewController(nc);
        this._stockProductsViewController.setDetailViewController(dvc);

        return this._stockProductsViewController;        
    }

    private _stockViewController = null;
    private stockViewController() {      
        if (this._stockViewController != null) return this._stockViewController;
        
        // let vc = new StockViewController("stock-view");
        // vc.initWithResource("layout/stock/StockView.html");

        // this._stockViewController = new MUINavigationController('stock_nav_controller');
        // this._stockViewController.initWithRootViewController(vc);

        let vc = ReportViewController.newInstance();
        vc.title = MIOLocalizeString("STOCK SUMMARY", "STOCK SUMMARY");
        vc.reportScriptPath = "python/1_stock_current_summary.py";
        vc.showSectionButton = true;

        // let vc = new DashboardViewController('dashboard-view');
        // vc.initWithResource('layout/dashboard/DashboardView.html');

        // let vc = new ReportViewController("report-detail-view");
        // vc.initWithResource("layout/reports/ReportDetailView.html");
        // vc.title = MIOLocalizeString("DASHBOARD", "DASHBOARD");
        // //this._operationSummaryViewController.reportID = "C1E32789-9434-41C2-8364-2D6D1CF951DD";
        // vc.reportScriptPath = "python/dashboard.py";
        // vc.showSectionButton = true;

        let nc = new MUINavigationController();
        nc.initWithRootViewController(vc);

        this._stockViewController = nc;
        return this._stockViewController;
    }

    private _inventoryNoteViewController:MUISplitViewController = null;    
    private inventoryNotesViewController() {        
        if (this._inventoryNoteViewController != null) return this._inventoryNoteViewController;

        let mvc = new InventoryNoteListViewController("inventory-note-list-view");
        mvc.initWithResource("layout/inventorynote/InventoryNoteListView.html");

        let dvc = AppHelper.sharedInstance().emptyViewControllerForIndentifier("Inventory", NoItemSelectedViewControllerType.InventoryNote);

        this._inventoryNoteViewController = new MUISplitViewController();
        this._inventoryNoteViewController.init();
        this._inventoryNoteViewController.setMasterViewController(mvc);
        this._inventoryNoteViewController.setDetailViewController(dvc);

        return this._inventoryNoteViewController;
    }

    private _stockTemplateViewController:MUISplitViewController = null;    
    private inventoryTemplateViewController() {        
        if (this._stockTemplateViewController != null) return this._stockTemplateViewController;

        let mvc = new StockTemplateListViewController("stock-template-list-view");
        mvc.initWithResource("layout/stock_template/StockTemplateListView.html");

        let dvc = AppHelper.sharedInstance().emptyViewControllerForIndentifier("StockTemplate", NoItemSelectedViewControllerType.StockTemplate);

        this._stockTemplateViewController = new MUISplitViewController();
        this._stockTemplateViewController.init();
        this._stockTemplateViewController.setMasterViewController(mvc);
        this._stockTemplateViewController.setDetailViewController(dvc);

        return this._stockTemplateViewController;
    }

    private _movementNoteViewController:MUISplitViewController = null;    
    private movementNotesViewController() {        
        if (this._movementNoteViewController != null) return this._movementNoteViewController;

        let mvc = new MovementNoteListViewController("movement-note-list-view");
        mvc.initWithResource("layout/movementnote/MovementNoteListView.html");

        let dvc = AppHelper.sharedInstance().emptyViewControllerForIndentifier("MovementNote", NoItemSelectedViewControllerType.MovementNote);

        this._movementNoteViewController = new MUISplitViewController();        
        this._movementNoteViewController.init();
        this._movementNoteViewController.setMasterViewController(mvc);
        this._movementNoteViewController.setDetailViewController(dvc);

        return this._movementNoteViewController;
    }

    private _outputNoteViewController:MUISplitViewController = null;    
    private outputNotesViewController() {        
        if (this._outputNoteViewController != null) return this._outputNoteViewController;

        let mvc = new OutputNoteListViewController("output-note-list-view");
        mvc.initWithResource("layout/outputnote/OutputNoteListView.html");

        let dvc = AppHelper.sharedInstance().emptyViewControllerForIndentifier("OutputNote", NoItemSelectedViewControllerType.OutputNote);        

        this._outputNoteViewController = new MUISplitViewController();
        this._outputNoteViewController.init();
        this._outputNoteViewController.setMasterViewController(mvc);
        this._outputNoteViewController.setDetailViewController(dvc);

        return this._outputNoteViewController;
    }

    private _lowStockProductsViewController:MUISplitViewController = null;    
    private lowStockProductViewController(){
        if (this._lowStockProductsViewController != null) return this._lowStockProductsViewController;

        let mvc = new LowStockListViewController("low-stock-products-list-view");
        mvc.initWithResource("layout/lowstockproducts/LowStockProductsListView.html");

        let dvc = AppHelper.sharedInstance().emptyViewControllerForIndentifier("LowStockProducts", NoItemSelectedViewControllerType.LowStockProducts);

        this._lowStockProductsViewController = new MUISplitViewController();
        this._lowStockProductsViewController.init();
        this._lowStockProductsViewController.setMasterViewController(mvc);
        this._lowStockProductsViewController.setDetailViewController(dvc);

        return this._lowStockProductsViewController;
    }

    private _buyOrdersViewController:MUISplitViewController = null;    
    private buyOrdersViewController(){
        if (this._buyOrdersViewController != null) return this._buyOrdersViewController;

        let mvc = new BuyOrderListViewController("buy-order-list-view");
        mvc.initWithResource("layout/stock_buy_orders/BuyOrderListView.html");

        let dvc = AppHelper.sharedInstance().emptyViewControllerForIndentifier("BuyOrders", NoItemSelectedViewControllerType.BuyOrder);

        this._buyOrdersViewController = new MUISplitViewController();
        this._buyOrdersViewController.init();
        this._buyOrdersViewController.setMasterViewController(mvc);
        this._buyOrdersViewController.setDetailViewController(dvc);

        return this._buyOrdersViewController;
    }

    private _supplierOrderViewController = null;    
    private supplierOrdersViewController(){
        if (this._supplierOrderViewController != null) return this._supplierOrderViewController;

        let mvc = new SupplierOrderListViewController("supplier-order-list-view");
        mvc.initWithResource("layout/supplierorder/SupplierOrderListView.html");

        let dvc = AppHelper.sharedInstance().emptyViewControllerForIndentifier("SupplierOrder", NoItemSelectedViewControllerType.SupplierOrder);

        this._supplierOrderViewController = new MUISplitViewController();
        this._supplierOrderViewController.init();
        this._supplierOrderViewController.setMasterViewController(mvc);
        this._supplierOrderViewController.setDetailViewController(dvc);

        return this._supplierOrderViewController;
    }

    private _supplierNoteViewController:MUISplitViewController = null;    
    private supplierNotesViewController() {
        if (this._supplierNoteViewController != null) return this._supplierNoteViewController;

        let mvc = new SupplierNoteListViewController('supplier-note-list-view');
        mvc.initWithResource('layout/suppliernote/SupplierNoteListView.html');

        let dvc = AppHelper.sharedInstance().emptyViewControllerForIndentifier("SupplierNote", NoItemSelectedViewControllerType.SupplierNote);        

        this._supplierNoteViewController = new MUISplitViewController();
        this._supplierNoteViewController.init();        
        this._supplierNoteViewController.setMasterViewController(mvc);
        this._supplierNoteViewController.setDetailViewController(dvc);
        
        return this._supplierNoteViewController;
    }

    private _supplierReturnNoteViewController:MUISplitViewController = null;    
    private supplierReturnNotesViewController() {
        if (this._supplierReturnNoteViewController != null) return this._supplierReturnNoteViewController;

        let mvc = new SupplierReturnNoteListViewController('supplier-return-note-list-view');
        mvc.initWithResource('layout/supplierreturnnote/SupplierReturnNoteListView.html');

        let dvc = AppHelper.sharedInstance().emptyViewControllerForIndentifier("SupplierNote", NoItemSelectedViewControllerType.SupplierNote);        

        this._supplierReturnNoteViewController = new MUISplitViewController();
        this._supplierReturnNoteViewController.init();        
        this._supplierReturnNoteViewController.setMasterViewController(mvc);
        this._supplierReturnNoteViewController.setDetailViewController(dvc);
        
        return this._supplierReturnNoteViewController;
    }

    private _deliveryNoteViewController:MUISplitViewController = null;    
    private deliveryNotesViewController() {     
        if (this._deliveryNoteViewController != null) return this._deliveryNoteViewController;

        let mvc = new DeliveryNoteListViewController("delivery-note-list-view");
        mvc.initWithResource("layout/deliverynote/DeliveryNoteListView.html");

        let dvc = AppHelper.sharedInstance().emptyViewControllerForIndentifier("DeliveryNote", NoItemSelectedViewControllerType.DeliveryNote)

        this._deliveryNoteViewController = new MUISplitViewController();
        this._deliveryNoteViewController.init();
        this._deliveryNoteViewController.setMasterViewController(mvc);
        this._deliveryNoteViewController.setDetailViewController(dvc);

        return this._deliveryNoteViewController;
    }

    private _warehouseOrderViewController:MUISplitViewController = null;    
    private warehouseOrdersViewController() {        
        if (this._warehouseOrderViewController != null) return this._warehouseOrderViewController;

        let mvc = WarehouseOrderListViewController.newInstance();
        let dvc = AppHelper.sharedInstance().emptyViewControllerForIndentifier("WarehouseOrder", NoItemSelectedViewControllerType.StockOrder);

        this._warehouseOrderViewController = new MUISplitViewController();
        this._warehouseOrderViewController.init();
        this._warehouseOrderViewController.setMasterViewController(mvc);
        this._warehouseOrderViewController.setDetailViewController(dvc);

        return this._warehouseOrderViewController;
    }

    private _warehouseDeliveryViewController:MUISplitViewController = null;    
    private warehouseDeliveryViewController() {        
        if (this._warehouseDeliveryViewController != null) return this._warehouseDeliveryViewController;
        
        let mvc = WarehouseDeliveryListViewController.newInstance();        
        let dvc = AppHelper.sharedInstance().emptyViewControllerForIndentifier("WarehouseDelivery", NoItemSelectedViewControllerType.StockOrder);        

        this._warehouseDeliveryViewController = new MUISplitViewController();
        this._warehouseDeliveryViewController.init();
        this._warehouseDeliveryViewController.setMasterViewController(mvc);
        this._warehouseDeliveryViewController.setDetailViewController(dvc);

        return this._warehouseDeliveryViewController;
    }

    private _warehouseInputViewController:MUISplitViewController = null;    
    private warehouseInputViewController() {        
        if (this._warehouseInputViewController != null) return this._warehouseInputViewController;
        
        let mvc = WarehouseDeliveryListViewController.newInstance();        
        let dvc = AppHelper.sharedInstance().emptyViewControllerForIndentifier("WarehouseInput", NoItemSelectedViewControllerType.StockOrder);        

        this._warehouseInputViewController = new MUISplitViewController();
        this._warehouseInputViewController.init();
        this._warehouseInputViewController.setMasterViewController(mvc);
        this._warehouseInputViewController.setDetailViewController(dvc);

        return this._warehouseInputViewController;
    }



    private _internalReturnOrderViewController:MUISplitViewController = null;    
    private internalReturnOrderViewController() {        
        if (this._internalReturnOrderViewController != null) return this._internalReturnOrderViewController;
        
        let irvc = new InternalReturnOrderListViewController('internal-return-order-list-view');
        irvc.initWithResource("layout/returnorder/InternalReturnOrderListView.html");

        let dvc = AppHelper.sharedInstance().emptyViewControllerForIndentifier("InternalReturnOrderNoSelectedItem", NoItemSelectedViewControllerType.InternalReturnOrder);        

        this._internalReturnOrderViewController = new MUISplitViewController();
        this._internalReturnOrderViewController.init();
        this._internalReturnOrderViewController.setMasterViewController(irvc);
        this._internalReturnOrderViewController.setDetailViewController(dvc);

        return this._internalReturnOrderViewController;
    }

    private _inputNoteViewController:MUISplitViewController = null;    
    private inputNotesViewController() {        
        if (this._inputNoteViewController != null) return this._inputNoteViewController;

        let mvc = InputNoteListViewController.newInstance();
        let dvc = AppHelper.sharedInstance().emptyViewControllerForIndentifier("InputNote", NoItemSelectedViewControllerType.InputNote);

        this._inputNoteViewController = new MUISplitViewController();
        this._inputNoteViewController.init();
        this._inputNoteViewController.setMasterViewController(mvc);
        this._inputNoteViewController.setDetailViewController(dvc);

        return this._inputNoteViewController;
    }

    // 
    // CONFIGURATION
    //

    private _settingsViewController:MUISplitViewController = null;    
    private settingsViewController() {
        if (this._settingsViewController != null) return this._settingsViewController;

        let mvc = new SetupViewController("setup-view");
        mvc.initWithResource("layout/setup/SetupView.html");

        let dvc = AppHelper.sharedInstance().emptyViewControllerForIndentifier("Setup", NoItemSelectedViewControllerType.Setup);        

        this._settingsViewController = new MUISplitViewController();
        this._settingsViewController.init();
        this._settingsViewController.setMasterViewController(mvc);
        this._settingsViewController.setDetailViewController(dvc);

        return this._settingsViewController;
    }

    private _placesViewController:PlaceListViewController = null;    
    private placesViewController(){
        if (this._placesViewController != null) return this._placesViewController;
        
        this._placesViewController = new PlaceListViewController('place-list-view');
        this._placesViewController.initWithResource("layout/places/PlaceListView.html");

        return this._placesViewController;
    }


    private _dbBackupToolViewController:DBBackupToolViewController = null;
    private dbBackupToolViewController() {
        if (this._dbBackupToolViewController != null) return this._dbBackupToolViewController;

        this._dbBackupToolViewController = new DBBackupToolViewController('db-backup-tool-view');
        this._dbBackupToolViewController.initWithResource("layout/dbtool/DBBackupToolView.html");

        return this._dbBackupToolViewController;
    }    

    private _dbToolViewController = null;
    private dbToolViewController() {
        if (this._dbToolViewController != null) return this._dbToolViewController;

        let vc = new DBToolViewController('db-tool-view');
        vc.initWithResource("layout/dbtool/DBToolView.html");

        this._dbToolViewController = new MUINavigationController();
        this._dbToolViewController.initWithRootViewController(vc);

        return this._dbToolViewController;  
    }

    private _dbSyncViewController:DBSyncViewController = null;    
    private dbSyncViewController(){
        if (this._dbSyncViewController != null) return this._dbSyncViewController;

        this._dbSyncViewController = new DBSyncViewController('db-sync-view');
        this._dbSyncViewController.initWithResource("layout/dbtool/DBSyncView.html");

        return this._dbSyncViewController;
    }

    private _dbMappingViewController:DBMappingViewController = null;    
    private dbMappingViewController(){
        if (this._dbMappingViewController != null) return this._dbMappingViewController;
        
        this._dbMappingViewController = new DBMappingViewController('db-mappings-view');
        this._dbMappingViewController.initWithResource("layout/dbtool/DBMappingsView.html");

        return this._dbMappingViewController;
    }

    private _syncLogViewController:MUINavigationController = null;    
    private syncLogViewController(){
        if (this._syncLogViewController != null) return this._syncLogViewController;

        let vc = new SyncLogViewController('sync-log-view');
        vc.initWithResource("layout/dbtool/SyncLogView.html");

        let nc = new MUINavigationController();
        nc.initWithRootViewController(vc);

        this._syncLogViewController = nc;
        return this._syncLogViewController;
    }
    
    private _dbImportViewController:DBImportViewController = null;    
    private dbImportViewController(){
        if (this._dbImportViewController != null) return this._dbImportViewController;

        this._dbImportViewController = new DBImportViewController('import-view');
        this._dbImportViewController.initWithResource("layout/dbtool/DBImportView.html");

        return this._dbImportViewController;
    }

    private _dbConsumptionLogViewController:DBConsumptionLogViewController = null;    
    private dbConsumptionLogViewController(){
        if (this._dbConsumptionLogViewController != null) return this._dbConsumptionLogViewController;

        this._dbConsumptionLogViewController = DBConsumptionLogViewController.newInstance();

        return this._dbConsumptionLogViewController;
    }


    private _reportBuilderViewController:MUISplitViewController = null;
    private reportBuilderViewController(){
        if (this._reportBuilderViewController != null) return this._reportBuilderViewController;

        let mvc = new ReportScriptListViewController("report-files-list-view");
        mvc.initWithResource("layout/reports/ReportFilesListView.html");

        let dvc = AppHelper.sharedInstance().emptyViewControllerForIndentifier("ReportBuilder", NoItemSelectedViewControllerType.ReportBuilder);

        this._reportBuilderViewController = new MUISplitViewController();
        this._reportBuilderViewController.init();
        this._reportBuilderViewController.setMasterViewController(mvc);
        this._reportBuilderViewController.setDetailViewController(dvc);

        return this._reportBuilderViewController;
    }

    //
    // DUAL LINK
    // 

    private _businessLicensesViewController:MUISplitViewController = null;
    private businessLicensesViewController():MUISplitViewController{
        if (this._businessLicensesViewController != null) return this._businessLicensesViewController;

        let mvc = new LicensesListViewController("licenses-list-view");
        mvc.initWithResource("layout/licenses/LicensesListView.html");

        let dvc = AppHelper.sharedInstance().emptyViewControllerForIndentifier("Licenses", NoItemSelectedViewControllerType.License);        

        this._businessLicensesViewController = new MUISplitViewController("licenses_split_controller");
        this._businessLicensesViewController.init();        
        this._businessLicensesViewController.setMasterViewController(mvc);
        this._businessLicensesViewController.setDetailViewController(dvc);

        return this._businessLicensesViewController;
    }

    private _licensesViewController:ApplicationLicenseListViewController = null;
    private licensesViewController():ApplicationLicenseListViewController { 
        if (this._licensesViewController != null) return this._licensesViewController;

        this._licensesViewController = ApplicationLicenseListViewController.newInstance();
        return this._licensesViewController;
    }

    private _supportTicketViewController:MUISplitViewController = null;
    private supportTicketsViewController():MUISplitViewController{
        if (this._supportTicketViewController != null) return this._supportTicketViewController;

        let mvc = new SupportTicketListViewController("support-ticket-list-view");
        mvc.initWithResource("layout/supportticket/SupportTicketListView.html");

        let dvc = AppHelper.sharedInstance().emptyViewControllerForIndentifier("SupportTickets", NoItemSelectedViewControllerType.SupportTickets);        

        this._supportTicketViewController = new MUISplitViewController();
        this._supportTicketViewController.init();        
        this._supportTicketViewController.setMasterViewController(mvc);
        this._supportTicketViewController.setDetailViewController(dvc);        

        return this._supportTicketViewController;
    }

    private _roomScheduleController:RoomScheduleViewController = null;
    private roomScheduleController():RoomScheduleViewController{
        if (this._roomScheduleController != null) return this._roomScheduleController;

        this._roomScheduleController = new RoomScheduleViewController("room-schedule-view");
        this._roomScheduleController.initWithResource("layout/rooms/RoomScheduleView.html");

        return this._roomScheduleController;
    }

    private _roomBookingController:RoomBookingListViewController = null;
    private roomBookingController():RoomBookingListViewController{
        if (this._roomBookingController != null) return this._roomBookingController;

        this._roomBookingController = new RoomBookingListViewController("rooms-booking-list-view");
        this._roomBookingController.initWithResource("layout/rooms/RoomsBookingListView.html");

        return this._roomBookingController;
    }

    private _tableMapViewController:TableMapViewController = null;
    private tableMapViewController():TableMapViewController {
        if (this._tableMapViewController != null) return this._tableMapViewController;

        this._tableMapViewController = new TableMapViewController("table-map-view");
        this._tableMapViewController.initWithResource("layout/tablemap/TableMapView.html");

        return this._tableMapViewController;
    }
    
    private _appConfigurationViewController:MUISplitViewController = null;
    private appConfigurationViewController():MUISplitViewController {
        if (this._appConfigurationViewController != null) return this._appConfigurationViewController;

        let mvc = ApplicationListViewController.newInstance();
        let dvc = AppHelper.sharedInstance().emptyViewControllerForIndentifier("AppConfiguration", NoItemSelectedViewControllerType.AppConfiguration);        

        this._appConfigurationViewController = new MUISplitViewController();
        this._appConfigurationViewController.init();        
        this._appConfigurationViewController.setMasterViewController(mvc);
        this._appConfigurationViewController.setDetailViewController(dvc);        

        return this._appConfigurationViewController;        
    }    

    private _clockinViewController:MUIViewController = null;
    private clockinViewController():MUIViewController {
        if (this._clockinViewController != null) return this._clockinViewController;

        let vc = WorkerClockInViewController.newInstance();

        return this._clockinViewController;        
    }

    private _distributorsViewController:MUISplitViewController = null;    
    private distributorsViewController(){
        if (this._distributorsViewController != null) return this._distributorsViewController;

        let mvc = DistributorListViewController.newInstance();
        let dvc = AppHelper.sharedInstance().emptyViewControllerForIndentifier("Distributor", NoItemSelectedViewControllerType.Distributor);

        this._distributorsViewController = new MUISplitViewController();
        this._distributorsViewController.init();
        this._distributorsViewController.setMasterViewController(mvc);
        this._distributorsViewController.setDetailViewController(dvc);

        return this._distributorsViewController;
    }

    private _agentsViewController:AgentListViewController = null;    
    private agentsViewController(){
        if (this._agentsViewController != null) return this._agentsViewController;

        this._agentsViewController = AgentListViewController.newInstance();
        return this._agentsViewController;
    }

    private _appConsumptionProfileViewController:MUISplitViewController = null;
    private appConsumptionProfileViewController():MUISplitViewController {
        if (this._appConsumptionProfileViewController != null) return this._appConsumptionProfileViewController;

        let mvc = ConsumptionProfileListViewController.newInstance();
        let dvc = AppHelper.sharedInstance().emptyViewControllerForIndentifier("AppConsumptionProfile", NoItemSelectedViewControllerType.AppConsumptionProfile);        

        this._appConsumptionProfileViewController = new MUISplitViewController();
        this._appConsumptionProfileViewController.init();        
        this._appConsumptionProfileViewController.setMasterViewController(mvc);
        this._appConsumptionProfileViewController.setDetailViewController(dvc);        

        return this._appConsumptionProfileViewController;        
    }

    /*
    private _installmentsViewcontroller:InstallmentsViewController = null;
    private installmentsViewcontroller():InstallmentsViewController { 
        if (this._installmentsViewcontroller != null) return this._installmentsViewcontroller;

        this._installmentsViewcontroller = InstallmentsViewController.newInstance();
        return this._installmentsViewcontroller;
    }
*/
    private _wineCellarProductsViewController:WineCellarProductsViewController = null;
    private wineCellarProductsViewController():WineCellarProductsViewController { 
        if (this._wineCellarProductsViewController != null) return this._wineCellarProductsViewController;

        this._wineCellarProductsViewController = WineCellarProductsViewController.newInstance();
        return this._wineCellarProductsViewController;
    }
}
