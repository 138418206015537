
/// <reference path="../notes/NoteDetailHeaderView.ts" />

class BuyOrderDetailHeaderView extends NoteDetailHeaderView
{
    private documentIDLabel:MUILabel = null;    

    private referenceTextField:MUITextField = null;
    private referenceLabel:MUILabel = null;

    private documentDateLabel:MUILabel = null;
    private documentDateTextField:MUITextField = null;
    private documentDateButton:MUIButton = null;

    private validationDateLabel:MUILabel = null;
    private validationDateTextField:MUITextField = null;
    private validationDateButton:MUIButton = null;

    private dtf = MUIWebApplication.sharedInstance().delegate.dateTimeFormatter as MIODateFormatter;

    awakeFromHTML(){
        super.awakeFromHTML();
        
        this.documentIDLabel = MUIOutlet(this, "document-id-lbl", "MUILabel");

        this.referenceTextField = MUIOutlet(this, "reference-tf", "MUITextField");
        if (this.referenceTextField != null) this.referenceTextField.setOnChangeText(this, function (control, value){
            this.stockNote.externalReference = value;
        });
        this.referenceLabel = MUIOutlet(this, "reference-lbl", "MUILabel");        

        this.documentDateLabel = MUIOutlet(this, "document-date-lbl", "MUILabel");
        this.documentDateTextField = MUIOutlet(this, "document-date-tf", "MUITextField");
        this.setupDocumentDateTextField(this.documentDateTextField);
        this.documentDateButton = MUIOutlet(this, "document-date-btn", 'MUIButton');
        this.setupCalendarButton(this.documentDateButton, NoteDetailHeaderViewDateType.DocumentDate);

        this.validationDateLabel = MUIOutlet(this, "validation-date-lbl", "MUILabel");
        this.validationDateTextField = MUIOutlet(this, "validation-date-tf", "MUITextField");
        this.setupValidationDateTextField(this.validationDateTextField);
        this.validationDateButton = MUIOutlet(this, "validation-date-btn", "MUIButton");
        this.setupCalendarButton(this.validationDateButton, NoteDetailHeaderViewDateType.ValidationDate);
    }
    
    set note(value:StockNote){
        this.stockNote = value;

        this.documentIDLabel.text = value.documentID;
        
        let documentDateString = this.dtf.stringFromDate(value.documentDate);
        if (this.documentDateLabel != null) this.documentDateLabel.text = documentDateString;
        if (this.documentDateTextField != null) this.documentDateTextField.text = documentDateString;

        let stockDateString = this.dtf.stringFromDate(value.stockDate);
        if (this.validationDateLabel != null) this.validationDateLabel.text = stockDateString;
        if (this.validationDateTextField != null) this.validationDateTextField.text = stockDateString;

        if (this.referenceTextField != null) this.referenceTextField.text = value.externalReference;
        if (this.referenceLabel != null) this.referenceLabel.text = value.externalReference;
    }

    protected dateDidChange(type:NoteDetailHeaderViewDateType, date:Date){
        switch(type){
            case NoteDetailHeaderViewDateType.DocumentDate:
            this.documentDateTextField.text = this.dtf.stringFromDate(date);
            break;

            case NoteDetailHeaderViewDateType.ValidationDate:
            this.validationDateTextField.text = this.dtf.stringFromDate(date);
            break;
        }
    }

    protected customStockNoteBeforeChangeStatus(status:number) : boolean {
        // Check if all lines has the supplier assigned

        if (status != StockNoteStatus.Processed) return true;

        let ok = true;
        for (let index = 0; index < this.stockNote.lines.count; index++) {
            let l = this.stockNote.lines.objectAtIndex(index) as StockNoteLine;
            if (l.legalEntity == null) {
                ok = false;
                // Add red line
            }
        }

        if (ok == false) {
            AppHelper.showErrorMessage(null, "ERROR", "THERE'S LINES WITHOUT SUPPLIER");            
        }   

        return ok;
    }

}