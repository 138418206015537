
class ProductAdminListViewController extends BaseTableViewController
{
    static newInstance() : ProductAdminListViewController {
        let vc = new ProductAdminListViewController('product-admin-view');
        vc.initWithResource("layout/productadmin/ProductAdminView.html");
        return vc;
    }

    private importButton:MUIButton = null; 

    viewDidLoad(){
        super.viewDidLoad();

        this.importButton = MUIOutlet(this, "import-btn", "MUIButton");
        this.importButton.setAction(this, function(){
            this.openPanel();
        });      

        this.tableView = MUIOutlet(this, "table-view", "MUITableView");
        this.tableView.dataSource = this;
        this.tableView.delegate = this;        
    }

    titleForHeaderInSection(tableView:MUITableView, section:number) {
        let ip = MIOIndexPath.indexForRowInSection(0, section);
        let item = this.fetchedResultsController.objectAtIndexPath(ip) as ProductComponentItem;
        return item.categoryName;
    }

    cellAtIndexPath(tableview, indexPath:MIOIndexPath){
        let item = this.fetchedResultsController.objectAtIndexPath(indexPath) as ProductComponentItem;
        
        let cell:ProductAdminCell = null;
        if (item.productID == item.parentID && item.formatName == null) {
            cell = tableview.dequeueReusableCellWithIdentifier("ProductCell") as ProductAdminCell;
        }
        else if (item.formatName != null){
            cell = tableview.dequeueReusableCellWithIdentifier("FormatCell") as ProductAdminCell;
        }
        else {
            cell = tableview.dequeueReusableCellWithIdentifier("IngredientCell") as ProductAdminCell;
        }
        
        cell.item = item;

        return cell;
    }

    didSelectCellAtIndexPath(tableView, indexPath:MIOIndexPath){

    }

    get fetchedResultsController(){
        if (this._fetchedResultsController != null) return this._fetchedResultsController;

        let ad = MUIWebApplication.sharedInstance().delegate;
        
        let fetchRequest = MIOFetchRequest.fetchRequestWithEntityName("Product");
        let sds = [ MIOSortDescriptor.sortDescriptorWithKey("category.name", true),
                    // MIOSortDescriptor.sortDescriptorWithKey("parentName", true), 
                    MIOSortDescriptor.sortDescriptorWithKey("name", true)];

        let fetchRequest = DBHelper.listFetchRequestWithEntityName("Product", sds, null);
        let fetchedResultsController = new MIOFetchedResultsController();
        fetchedResultsController.initWithFetchRequest(fetchRequest, ad.managedObjectContext, "category.name");
        fetchedResultsController.delegate = this;

        fetchedResultsController.performFetch();

        this._fetchedResultsController = fetchedResultsController;
        return this._fetchedResultsController;
    }

    private openPanel(){
        let panel = MUIOpenPanel.openPanel();
        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;

        panel.beginSheetModalForWindow(ad.window, this, function(result:number){
            if (result == MIOFileHandlingPanel.OKButton) {
               let file = panel.files[0];
               // Open  the document.
                this.importProductsFromFile(file);
            }       
         });      
    }

    private importProductsFromFile(file){
        let reader = new FileReader();

        let instance = this;
        reader.onload = function(e) {
            let data = reader.result;
            let workbook = XLSX.read(data, {
                type: 'binary'
            });
        
            workbook.SheetNames.forEach(function(sheetName) {
                // Here is your object
                let worksheet = workbook.Sheets[sheetName];                
                let result = [];
                let row;
                let rowNum;
                let colNum;
                let range = XLSX.utils.decode_range(worksheet['!ref']);
                for(rowNum = range.s.r; rowNum <= range.e.r; rowNum++){
                   row = [];
                    for(colNum=range.s.c; colNum<=range.e.c; colNum++){
                       let nextCell = worksheet[
                          XLSX.utils.encode_cell({r: rowNum, c: colNum})
                       ];
                       if( typeof nextCell === 'undefined' ){
                          row.push(void 0);
                       } else row.push(nextCell.w);
                    }
                    result.push(row);
                }                
                instance.importRows.call(instance, result);
            })
        };

        reader.onerror = function(ex) {
            console.log(ex);
        };
      
        reader.readAsBinaryString(file);  
    }

    private importRows(rows){
        let isProductRow = false;

        for (let rowIndex = 0; rowIndex < rows.length; rowIndex++){
            let row = rows[rowIndex];
                        
            if (row[0] != "GROUP" && isProductRow == false) continue;
            else if (row[0] == "GROUP") isProductRow = true;
            else this.parseRow(row);
        }
    }

    private parseRow(row){
        let subCategory = row[0];
        let category = row[1];
        let superCategory = row[2];
        let salesTax = row[3];
        let productName = row[4];
        let kitchenName = row[5];
        let contaninerQuantity = row[6];
        let measure = row[7];
        let customMeasureName = row[8];
        let purchaseTax = row[9];
        let costPrice = row[10];
        let format = row[11];
        let price = row[12];
        let tags = row[13];
        
        let isContainer = contaninerQuantity > 0 ? true : false;
        let containerMeasure = isContainer ? measure : null;
        let productMeasure = isContainer ? -1 : measure;
        
        if (isContainer) {
            MIOLog(productName + ": " + contaninerQuantity + " " + containerMeasure + " of " + customMeasureName + "(" + productMeasure + ")");
        }
        else {
            MIOLog(productName + ": " + customMeasureName);
        }
        
    }
}