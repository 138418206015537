

/// <reference path="CoreEntityUpdatedBy.ts" />

// Generated class ProductFormat_ManagedObject

class ProductFormat_ManagedObject extends CoreEntityUpdatedBy
{

    // Property: additionalCostPrice
    set additionalCostPrice(value:number) {
        this.setValueForKey(value, 'additionalCostPrice');
    }
    get additionalCostPrice():number {
        return this.valueForKey('additionalCostPrice');
    }
    set additionalCostPricePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'additionalCostPrice');
    }
    get additionalCostPricePrimitiveValue():number {
        return this.primitiveValueForKey('additionalCostPrice');
    }

    // Property: consumptionMeasureType
    set consumptionMeasureType(value:number) {
        this.setValueForKey(value, 'consumptionMeasureType');
    }
    get consumptionMeasureType():number {
        return this.valueForKey('consumptionMeasureType');
    }
    set consumptionMeasureTypePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'consumptionMeasureType');
    }
    get consumptionMeasureTypePrimitiveValue():number {
        return this.primitiveValueForKey('consumptionMeasureType');
    }

    // Property: consumptionQuantity
    set consumptionQuantity(value:number) {
        this.setValueForKey(value, 'consumptionQuantity');
    }
    get consumptionQuantity():number {
        return this.valueForKey('consumptionQuantity');
    }
    set consumptionQuantityPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'consumptionQuantity');
    }
    get consumptionQuantityPrimitiveValue():number {
        return this.primitiveValueForKey('consumptionQuantity');
    }

    // Property: costPrice
    set costPrice(value:number) {
        this.setValueForKey(value, 'costPrice');
    }
    get costPrice():number {
        return this.valueForKey('costPrice');
    }
    set costPricePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'costPrice');
    }
    get costPricePrimitiveValue():number {
        return this.primitiveValueForKey('costPrice');
    }

    // Property: groupID
    set groupID(value:string) {
        this.setValueForKey(value, 'groupID');
    }
    get groupID():string {
        return this.valueForKey('groupID');
    }
    set groupIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'groupID');
    }
    get groupIDPrimitiveValue():string {
        return this.primitiveValueForKey('groupID');
    }

    // Property: groupName
    set groupName(value:string) {
        this.setValueForKey(value, 'groupName');
    }
    get groupName():string {
        return this.valueForKey('groupName');
    }
    set groupNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'groupName');
    }
    get groupNamePrimitiveValue():string {
        return this.primitiveValueForKey('groupName');
    }

    // Property: groupPrice
    set groupPrice(value:number) {
        this.setValueForKey(value, 'groupPrice');
    }
    get groupPrice():number {
        return this.valueForKey('groupPrice');
    }
    set groupPricePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'groupPrice');
    }
    get groupPricePrimitiveValue():number {
        return this.primitiveValueForKey('groupPrice');
    }

    // Property: invitationValue
    set invitationValue(value:number) {
        this.setValueForKey(value, 'invitationValue');
    }
    get invitationValue():number {
        return this.valueForKey('invitationValue');
    }
    set invitationValuePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'invitationValue');
    }
    get invitationValuePrimitiveValue():number {
        return this.primitiveValueForKey('invitationValue');
    }

    // Property: isDefaultFormat
    set isDefaultFormat(value:boolean) {
        this.setValueForKey(value, 'isDefaultFormat');
    }
    get isDefaultFormat():boolean {
        return this.valueForKey('isDefaultFormat');
    }
    set isDefaultFormatPrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'isDefaultFormat');
    }
    get isDefaultFormatPrimitiveValue():boolean {
        return this.primitiveValueForKey('isDefaultFormat');
    }

    // Property: isEnableForSell
    set isEnableForSell(value:boolean) {
        this.setValueForKey(value, 'isEnableForSell');
    }
    get isEnableForSell():boolean {
        return this.valueForKey('isEnableForSell');
    }
    set isEnableForSellPrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'isEnableForSell');
    }
    get isEnableForSellPrimitiveValue():boolean {
        return this.primitiveValueForKey('isEnableForSell');
    }

    // Property: lossMeasureType
    set lossMeasureType(value:number) {
        this.setValueForKey(value, 'lossMeasureType');
    }
    get lossMeasureType():number {
        return this.valueForKey('lossMeasureType');
    }
    set lossMeasureTypePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'lossMeasureType');
    }
    get lossMeasureTypePrimitiveValue():number {
        return this.primitiveValueForKey('lossMeasureType');
    }

    // Property: lossQuantity
    set lossQuantity(value:number) {
        this.setValueForKey(value, 'lossQuantity');
    }
    get lossQuantity():number {
        return this.valueForKey('lossQuantity');
    }
    set lossQuantityPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'lossQuantity');
    }
    get lossQuantityPrimitiveValue():number {
        return this.primitiveValueForKey('lossQuantity');
    }

    // Property: modifiersRequired
    set modifiersRequired(value:boolean) {
        this.setValueForKey(value, 'modifiersRequired');
    }
    get modifiersRequired():boolean {
        return this.valueForKey('modifiersRequired');
    }
    set modifiersRequiredPrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'modifiersRequired');
    }
    get modifiersRequiredPrimitiveValue():boolean {
        return this.primitiveValueForKey('modifiersRequired');
    }

    // Property: originalPrice
    set originalPrice(value:number) {
        this.setValueForKey(value, 'originalPrice');
    }
    get originalPrice():number {
        return this.valueForKey('originalPrice');
    }
    set originalPricePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'originalPrice');
    }
    get originalPricePrimitiveValue():number {
        return this.primitiveValueForKey('originalPrice');
    }

    // Property: price
    set price(value:number) {
        this.setValueForKey(value, 'price');
    }
    get price():number {
        return this.valueForKey('price');
    }
    set pricePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'price');
    }
    get pricePrimitiveValue():number {
        return this.primitiveValueForKey('price');
    }

    // Property: totalConsumptionMeasureType
    set totalConsumptionMeasureType(value:number) {
        this.setValueForKey(value, 'totalConsumptionMeasureType');
    }
    get totalConsumptionMeasureType():number {
        return this.valueForKey('totalConsumptionMeasureType');
    }
    set totalConsumptionMeasureTypePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'totalConsumptionMeasureType');
    }
    get totalConsumptionMeasureTypePrimitiveValue():number {
        return this.primitiveValueForKey('totalConsumptionMeasureType');
    }

    // Property: totalConsumptionQuantity
    set totalConsumptionQuantity(value:number) {
        this.setValueForKey(value, 'totalConsumptionQuantity');
    }
    get totalConsumptionQuantity():number {
        return this.valueForKey('totalConsumptionQuantity');
    }
    set totalConsumptionQuantityPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'totalConsumptionQuantity');
    }
    get totalConsumptionQuantityPrimitiveValue():number {
        return this.primitiveValueForKey('totalConsumptionQuantity');
    }

    // Relationship: cheffLines
    protected _cheffLines:MIOManagedObjectSet = null;
    get cheffLines():MIOManagedObjectSet {
        return this.valueForKey('cheffLines');
    }
    addCheffLinesObject(value:CheffLine) {
        this._addObjectForKey(value, 'cheffLines');
    }
    removeCheffLinesObject(value:CheffLine) {
        this._removeObjectForKey(value, 'cheffLines');
    }

    // Relationship: customComponents
    protected _customComponents:MIOManagedObjectSet = null;
    get customComponents():MIOManagedObjectSet {
        return this.valueForKey('customComponents');
    }
    addCustomComponentsObject(value:Component) {
        this._addObjectForKey(value, 'customComponents');
    }
    removeCustomComponentsObject(value:Component) {
        this._removeObjectForKey(value, 'customComponents');
    }
    // Relationship: ellaborationCenter
    set ellaborationCenter(value:EllaborationCenter) {
        this.setValueForKey(value, 'ellaborationCenter');
    }
    get ellaborationCenter():EllaborationCenter {
        return this.valueForKey('ellaborationCenter') as EllaborationCenter;
    }

    // Relationship: extraComponents
    protected _extraComponents:MIOManagedObjectSet = null;
    get extraComponents():MIOManagedObjectSet {
        return this.valueForKey('extraComponents');
    }
    addExtraComponentsObject(value:Component) {
        this._addObjectForKey(value, 'extraComponents');
    }
    removeExtraComponentsObject(value:Component) {
        this._removeObjectForKey(value, 'extraComponents');
    }
    // Relationship: format
    set format(value:Format) {
        this.setValueForKey(value, 'format');
    }
    get format():Format {
        return this.valueForKey('format') as Format;
    }
    // Relationship: linkedMenu
    set linkedMenu(value:Menu) {
        this.setValueForKey(value, 'linkedMenu');
    }
    get linkedMenu():Menu {
        return this.valueForKey('linkedMenu') as Menu;
    }
    // Relationship: product
    set product(value:Product) {
        this.setValueForKey(value, 'product');
    }
    get product():Product {
        return this.valueForKey('product') as Product;
    }

    // Relationship: productFormatRates
    protected _productFormatRates:MIOManagedObjectSet = null;
    get productFormatRates():MIOManagedObjectSet {
        return this.valueForKey('productFormatRates');
    }
    addProductFormatRatesObject(value:ProductFormatRate) {
        this._addObjectForKey(value, 'productFormatRates');
    }
    removeProductFormatRatesObject(value:ProductFormatRate) {
        this._removeObjectForKey(value, 'productFormatRates');
    }

    // Relationship: productMenus
    protected _productMenus:MIOManagedObjectSet = null;
    get productMenus():MIOManagedObjectSet {
        return this.valueForKey('productMenus');
    }
    addProductMenusObject(value:ProductMenu) {
        this._addObjectForKey(value, 'productMenus');
    }
    removeProductMenusObject(value:ProductMenu) {
        this._removeObjectForKey(value, 'productMenus');
    }

    // Relationship: productModifierCategories
    protected _productModifierCategories:MIOManagedObjectSet = null;
    get productModifierCategories():MIOManagedObjectSet {
        return this.valueForKey('productModifierCategories');
    }
    addProductModifierCategoriesObject(value:ProductModifierCategory) {
        this._addObjectForKey(value, 'productModifierCategories');
    }
    removeProductModifierCategoriesObject(value:ProductModifierCategory) {
        this._removeObjectForKey(value, 'productModifierCategories');
    }

    // Relationship: productModifiers
    protected _productModifiers:MIOManagedObjectSet = null;
    get productModifiers():MIOManagedObjectSet {
        return this.valueForKey('productModifiers');
    }
    addProductModifiersObject(value:ProductModifier) {
        this._addObjectForKey(value, 'productModifiers');
    }
    removeProductModifiersObject(value:ProductModifier) {
        this._removeObjectForKey(value, 'productModifiers');
    }
    // Relationship: productSource
    set productSource(value:ProductSource) {
        this.setValueForKey(value, 'productSource');
    }
    get productSource():ProductSource {
        return this.valueForKey('productSource') as ProductSource;
    }
}
