//
// Generated class Product
//

/// <reference path="Product_ManagedObject.ts" />

enum ProductType 
{
    None,    
    Sales,
    Stock,
    Both    
}

enum StockElaborationType
{
    None,
    BeforeSale,
    AtSale
}

enum StockBlockType
{
    None,
    Product,
    Components
}

enum ProductCostType
{
    LastPrice = 0,
    Average = 1,
    FixedCost = 2,
    Components = 3
}

class Product extends Product_ManagedObject
{
    static stockElabotarationStringByType(type:StockElaborationType){
        switch (type){
            case StockElaborationType.None:
            return MIOLocalizeString("NO ELABORATION", "NO ELABORATION");

            case StockElaborationType.BeforeSale:
            return MIOLocalizeString("ELABORATION BEFORE SALE", "ELABORATION BEFORE SALE");

            case StockElaborationType.AtSale:
            return MIOLocalizeString("ELABORATION AT SALE", "ELABORATION AT SALE");
        }

        return null;
    }

    static parseStockElabotarationTypeValue(value:any){
        let result = StockElaborationType.None;
        if ( typeof value === 'string' ) {
            switch (value.toUpperCase()) {
                case "NO ELABORATION": 
                case "NONE": 
                case "0":
                result = StockElaborationType.None; 
                break;

                case "ELABORATION BEFORE SALE":
                case "BEFORE SALE":
                case "1":
                result = StockElaborationType.BeforeSale; 
                break;

                case "ELABORATION AT SALE":
                case "AT SALE":
                case "2":
                result = StockElaborationType.AtSale; 
                break;
            }
        }
        else if ( typeof value === 'number' ) {
            switch (value) {
                case 0: result = StockElaborationType.None; break;
                case 1: result = StockElaborationType.BeforeSale; break;
                case 2: result = StockElaborationType.AtSale; break;
            }
        }

        return result;
    }

    static parseCostTypeValue(value:any){
        let result = ProductCostType.FixedCost;

        if ( typeof value === 'string' ) {
            switch (value.toUpperCase()) {
                case "LAST PRICE":
                case "0":
                result = ProductCostType.LastPrice; 
                break;

                case "AVERAGE":
                case "1":
                result = ProductCostType.Average; 
                break;

                case "FIXED COST":
                case "2":
                result = ProductCostType.FixedCost; 
                break;

                case "COMPONENTS":
                case "3":
                result = ProductCostType.Components; 
                break;    
            }
        }
        else if ( typeof value === 'number' ) {
            switch (value) {
                case 0: result = ProductCostType.LastPrice; break;
                case 1: result = ProductCostType.Average; break;
                case 2: result = ProductCostType.FixedCost; break;
                case 3: result = ProductCostType.Components; break;
            }
        }

        return result;
    }

    static stockBlockStringByType(type:StockBlockType){
        switch(type){
            case StockBlockType.None:
            return MIOLocalizeString("NO BLOCK", "NO BLOCK");

            case StockBlockType.Product:
            return MIOLocalizeString("BLOCK BY PRODUCT", "BLOCK BY PRODUCT");

            case StockBlockType.Components:
            return MIOLocalizeString("BLOCK BY INGREDIENTS", "BLOCK BY INGREDIENTS");
        }

        return null;
    }

    get currentStockString(): string {
            
        let nf = MUIWebApplication.sharedInstance().delegate.numberFormatter as MIONumberFormatter;
        let stock = this.currentStock?.totalStock ?? 0;

        if (this.isContainer == true) {
            let q = this.containerQuantity * MeasureUnits.meassureFactor( this.containerMeasureType, MeasureUnits.minUnits(this.containerMeasureType));
            stock = stock / q;
            return nf.stringFromNumber(stock) + " " + (this.stockContainerName != null ? this.stockContainerName : MeasureUnits.toString(MeasureUnitType.Container));
        }
        else {
            stock = stock * MeasureUnits.meassureFactor( MeasureUnits.minUnits(this.measureType), this.measureType);
            return nf.stringFromNumber(stock) + " " + MeasureUnits.toString(this.measureUnitType);
        }

        return null;
    }

    // get avgCost(){
    //     let quantity = this.quantity ? this.quantity  : 1;      
    //     return (this.currentExistences ? parseFloat(this.currentExistences['avgcost']) * quantity : 0);
    // }

    get imageUrl(){
        // let url = '';
        // if(this.imageURL) return this.imageURL;
        // if(this.image) {
        //     let image = this.image;
        //     url = image['url'];
        // } 
        // return url;
        return "";
    }

    public getAllergies(flags){
        if (flags == null) return;
        let allergies = [];
        for (let index = 0; index < 14; index++){
            let b = (flags >> index) & 0x1;
            let item;
            if (b == 1 && (item = this._addAllergies(index))) {

                 allergies.push(item);
            }
        }
        return allergies;
    }

    private _addAllergies(index){
        let item = null;
        switch (index)
        {
            case 0: // Gluten
                item = "Gluten";
                break;

            case 1: // Crustaceans
                item = "Crustaceans";
                break;

            case 2: // Eggs
                item = "Eggs";
                break;

            case 3: // Fish
                item = "Fish";
                break;

            case 4: // Peanuts
                item = "Peanuts";
                break;

            case 5: // Soya
                item = "Soya";
                break;

            case 6: // Milk
                item = "Milk";
                break;

            case 7: // Nuts
                item = "Nuts";
                break;

            case 8: // Celery
                item = "Celery";
                break;

            case 9: // Mustard
                item = "Mustard";
                break;

            case 10: // Sesame
                item = "Sesame";
                break;

            case 11: // Sulphates
                item = "Sulphates";
                break;

            case 12: // Mollusk
                item = "Mollusk";
                break;

            case 13: // Lupins
                item = "Lupins";
                break;
        }

        return item;
    }    

    get defaultTax():Tax{
        let tax:Tax = this.tax;
        if (tax != null) return tax;

        if (this.category == null) return tax;

        tax = this.category.tax;
        return tax;
    }

    taxString():string {
        let tax = this.defaultTax;
        if (tax != null) return tax.name;

        return null;
    }

    set fixedCostPriceForMinimumMeasure(price){
        let q:MIODecimalNumber = MIODecimalNumber.numberWithFloat(this.quantity == null ? 1 : this.quantity);
        let p:MIODecimalNumber = MIODecimalNumber.numberWithFloat(price);

        switch(this.measureUnitType)
        {
            case MeasureUnitType.Unit:
            case MeasureUnitType.VolumeCentilitre:
            case MeasureUnitType.MassGram:
                this.costPrice = p.decimalNumberByDividingBy(q).decimalValue;
                //this.costPrice = price / quantity;
                break;
            case MeasureUnitType.MassKilogram:
                this.costPrice = p.decimalNumberByDividingBy(q.decimalNumberByMultiplyingBy(MIODecimalNumber.numberWithInteger(1000))).decimalValue;
                break;
               
            case MeasureUnitType.VolumeLitre:
            this.costPrice = p.decimalNumberByDividingBy(q.decimalNumberByMultiplyingBy(MIODecimalNumber.numberWithInteger(100))).decimalValue;
                break;
            default:
                throw new Error('Unexpected measure unit');
        }
    }

    get fixedCostPriceFromMinimumMeasure(){
        let quantity = MIODecimalNumber.numberWithFloat(this.quantity == null ? 1 : this.quantity);
        let costPrice = MIODecimalNumber.numberWithFloat(this.costPrice);
        let price = null;

        switch(this.measureUnitType)
        {
            case MeasureUnitType.Unit:
            case MeasureUnitType.VolumeCentilitre:
            case MeasureUnitType.MassGram:
                price = costPrice.decimalNumberByMultiplyingBy(quantity).decimalValue;
                break;

            case MeasureUnitType.MassKilogram:
                price = costPrice.decimalNumberByMultiplyingBy(quantity).decimalNumberByMultiplyingBy(MIODecimalNumber.numberWithInteger(1000)).decimalValue;
                break;
               
            case MeasureUnitType.VolumeLitre:            
                price = costPrice.decimalNumberByMultiplyingBy(quantity).decimalNumberByMultiplyingBy(MIODecimalNumber.numberWithInteger(100)).decimalValue;
                break;
            
            default:
                throw new Error('Unexpected measure unit');
        }
        return price;
    }

    defaultInputFormatString():string{
        let type = MeasureUnits.toString(this.measureUnitType);
        if (this.quantity == null || this.quantity == 0) return type;

        return MeasureUnits.toString(MeasureUnitType.Container) + " (" + this.quantity + " " + type + ")";
    }

    get isContainer(){
        return this.quantity > 0 && this.measureUnitType != null;
    }

    // get measureType()
    // {
    //     let measureunittype = this.measureUnitType;
    //     if(measureunittype == MeasureUnitType.Unit)
    //         return MeasureType.MeasureTypeUnit;
    //     if(measureunittype == MeasureUnitType.MassGram || measureunittype == MeasureUnitType.MassKilogram)
    //         return MeasureType.MeasureTypeMass;
    //     if(measureunittype == MeasureUnitType.VolumeCentilitre || measureunittype == MeasureUnitType.VolumeLitre)
    //         return MeasureType.MeasureTypeVolume;
                
    // }

    get productMeasureString():string{         
        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
        let v:string = null;
        if (this.isContainer == true) {        
            v = "Cont. " + ad.numberFormatter.stringFromNumber(this.quantity) + " " + this.containerMeasureTypeString;
        }
        else                                 
            v = this.containerMeasureTypeString;            

        return v;
    }

    get measureType() : MeasureUnitType {
        if (this.isContainer == true) {
            return MeasureUnitType.Container;
        }
        else {
            return this.measureUnitType;
        }
    }

    get measureTypeString() : string {
        return MeasureUnits.measureUnitTypeString(this.measureType);
    }

    get containerMeasureType(){
        if (this.isContainer == true) return this.measureUnitType;
        
        return MeasureUnitType.None;
    }
    get containerMeasureTypeString():string {
        return MeasureUnits.measureUnitTypeString(this.containerMeasureType);
    }

    get containerQuantity(){
        if (this.isContainer == true) return this.quantity;

        return 0;
    }

    get stockElaborationTypeString(){
        return Product.stockElabotarationStringByType(this.stockElaborationType);
    }

    get stockBlockTypeString(){
        return Product.stockBlockStringByType(this.stockBlockType);
    }

    get productType():ProductType{
        if (this.isEnableForSell== false && this.isEnableForStock == false) return ProductType.None;
        if (this.isEnableForSell == true && this.isEnableForStock == true) return ProductType.Both;
        if (this.isEnableForSell == true) return ProductType.Sales;
        return ProductType.Stock;
    }

    get productTypeString():string {
        switch (this.productType){
            case ProductType.None: return MIOLocalizeString("NONE", "NONE");
            case ProductType.Sales: return MIOLocalizeString("ONLY SALES", "ONLY SALES");
            case ProductType.Stock: return MIOLocalizeString("ONLY STOCK", "ONLY STOCK");
            case ProductType.Both: return MIOLocalizeString("SALES & STOCK", "SALES & STOCK");
        }

        return null;
    }

    get currentCostPrice():number{
        switch (this.costType) {
            case ProductCostType.LastPrice: return this.costBalance?.lastPrice ?? 0;
            case ProductCostType.Average: return this.costBalance?.avgPrice ?? 0;
            case ProductCostType.FixedCost: return this.costBalance?.fixedPrice ?? 0;
            case ProductCostType.Components: return this.costBalance?.componentPrice ?? 0;
        }

        return 0;
    }

    productNameDescription(){        
        let name = null;
        let nf = (MUIWebApplication.sharedInstance().delegate as AppDelegate).numberFormatter;
        if (this.isContainer == true) {
            name = this.name + " (" + nf.stringFromNumber(this.containerQuantity) +  " " + MeasureUnits.toString(this.containerMeasureType) + ")";
        }
        else {
            name = this.name + " (" + MeasureUnits.toString(this.measureUnitType) + ")";
        }

        return name;        
    }

    inputFormatDescription(inputType:MeasureUnitType, inputFormat:StockInputFormat){
        if (inputFormat != null) {
            return inputFormat.name;
        } else {
            if (inputType == MeasureUnitType.Container) {
            let name = MeasureUnits.toString(inputType);
            return name + " (" + this.containerQuantity + MeasureUnits.toString(this.containerMeasureType) + ")";
            }
            else {
                return MeasureUnits.toString(inputType);
            }
        }                
    }
}
