/**
 * Created by godshadow on 30/11/2016.
 */

/// <reference path="WebService.ts" />
/// <reference path="SectionHelper.ts" />

class AppDelegate extends MIOObject 
{
    window:MUIWindow = null;
    
    selectedIdentifierType = null;
    selectedIdentifier = null;
    selectedPlace = null;
    selectedModule = null;
    selectedSection = null;

    selectedUser:User = null;
    selectedBusinessName:string = null;

    loginOK = false;

    mode = null;    
    private _dateFormatter: MIODateFormatter = null;
    private _timeFormatter: MIODateFormatter = null;    
    private _longDateTimeFormatter: MIODateFormatter = null;

    private _integerFormatter: MIONumberFormatter = null;
    private _percentNumberFormatter: MIONumberFormatter = null;

    private _persistentStoreCoordinator = null;
    private _managedObjectModel = null;
    private _managedObjectContext = null;

    private _ignoreEntities = {};

    didFinishLaunching() {        

        SettingsHelper.sharedInstance().loadAppSettings()
        DualLinkAuthKit.sharedInstance().authURL = SettingsHelper.sharedInstance().config["AuthServerURL"];

        this.window = new MUIWindow();

        if (MIOCoreIsPhone() == true) {
            let vc = new MobileViewController();
            vc.init();
            this.window.initWithRootViewController(vc);            
        }
        else {
            let vc = new DesktopViewController("desktop-view");
            vc.initWithResource("layout/desktop/DesktopView.html");                
            this.window.initWithRootViewController(vc);
        }

        MIONotificationCenter.defaultCenter().addObserver(this, "ConfigurationDidChange", this.configDidChangeNotification);
        this.managedObjectContext.reset();
    }

    private _webService:WebService = null;
    get webService():WebService {
        if (this._webService != null) return this._webService;

        this._webService = new WebService();
        this._webService.init(); 

        let data = MIOBundle.mainBundle().pathForResourceOfType("config", "plist");
        let config = MIOPropertyListSerialization.propertyListWithData(data, 0, 0, null);

        this.updateWebServiceURLs(config)

        return this._webService;
    }
    
    private _ignore_auth_urls = false;

    private updateWebServiceURLs(config:any){
        if (config == null) return;
        
        if (config["APIServerURL"] != null) SettingsHelper.sharedInstance().config["APIServerURL"] = config["APIServerURL"];
        if (config["ScriptServerURL"] != null) SettingsHelper.sharedInstance().config["ScriptServerURL"] = config["ScriptServerURL"];
        if (config["TemplateServerURL"] != null) SettingsHelper.sharedInstance().config["TemplateServerURL"] = config["TemplateServerURL"];
        if (config["ToolServerURL"] != null) SettingsHelper.sharedInstance().config["ToolServerURL"] = config["ToolServerURL"];
        
        //this.webService.apiURL = config["WebServiceAPIURL"];
        //this.webService.api2URL = config["WebServiceAPI2URL"];
        //this.webService.apiURL = config["APIServerURL"];
        //this.webService.scriptURL = config["WebServiceScriptURL"];
        //this.webService.scriptURL = config["ScriptServerURL"];
        // this.webService.clientID = config["WebServiceOauthClientID"];
        // this.webService.clientSecret = config["WebServiceOauthClientSecret"];
        // this.webService.clientGrantType = config["WebServiceOauthGrantType"];
    }
    
    private _dl:boolean = false;
    get dlOptionEnabled():boolean {
        return this._dl;
    }

    setBusiness(item, target?, completion?){                

        let id = item["id"].toUpperCase();
        let schema = item["id_productdb"] ? item["id_productdb"].toUpperCase() : id;

        this._dl = item["options"]["_dl_"] ?? false;

        this.selectedIdentifier = id;
        this.selectedIdentifierType = "place";
        this.webService.identifier = id;
        this.webService.identifierType = "place";
        this.selectedBusinessName = item["name"];
        this.webService.scheme = schema;

        if (SettingsHelper.sharedInstance().ignoreServerAuthURLs == false) this.updateWebServiceURLs( item ["urls"] );

        AppHelper.sharedInstance().defaultCountry = null;
        AppHelper.sharedInstance().defaultWarehouse = null;
        AppHelper.sharedInstance().defaultBusinessArea = null;
        AppHelper.sharedInstance().defaultVAT = null;

        this.managedObjectContext.reset();

        SettingsHelper.sharedInstance().loadConfig(this, function(config){
            this.updateLocale(config);
        });

        SettingsHelper.sharedInstance().loadPermissions(this, function(this:AppDelegate, user:User){
            // Set up the configuration            
            this.selectedUser = user;
            this.setDBWorkspace(item, null);
            if (target != null && completion != null) completion.call(target);
        });
    }

    private currencyStyle = MIONumberFormatterStyle.CurrencyStyle;
    setDBWorkspace(item:any, config:any){        

        // Save the actual DB
        let useremail = MIOUserDefaults.standardUserDefaults().valueForKey("LastLoginUser");
        let userDBString = MIOUserDefaults.standardUserDefaults().valueForKey("UserDB");
        let userDB = userDBString != null ? JSON.parse(userDBString) : {};        
        userDB[useremail] = {};
        userDB[useremail]["lastDBIdentifier"] = item["id"];
        userDB[useremail]["lastDBIdentifierType"] = "place";
        userDB[useremail]["lastDBSchema"] = item["id_productdb"];
        
        MIOUserDefaults.standardUserDefaults().setValueForKey("UserDB", JSON.stringify(userDB));        

        DBCacheHelper.sharedInstance().removeCacheEntityName("Tax");
        DBCacheHelper.sharedInstance().removeCacheEntityName("Department");
        DBCacheHelper.sharedInstance().removeCacheEntityName("WorkerRole");
        DBCacheHelper.sharedInstance().removeCacheEntityName("BookingZone");
        DBCacheHelper.sharedInstance().removeCacheEntityName("TimeRange");
        DBCacheHelper.sharedInstance().removeCacheEntityName("Language");
        DBCacheHelper.sharedInstance().removeCacheEntityName("Country");
        DBCacheHelper.sharedInstance().removeCacheEntityName("BusinessArea");
        DBCacheHelper.sharedInstance().removeCacheEntityName("Warehouse");
        DBCacheHelper.sharedInstance().removeCacheEntityName("Configuration");
        DBCacheHelper.sharedInstance().removeCacheEntityName("Place");
        DBCacheHelper.sharedInstance().removeCacheEntityName("WineCellar"); 
        DBCacheHelper.sharedInstance().removeCacheEntityName("ProductCategory"); 
        
        SectionHelper.sharedInstance().reset();
        AppHelper.sharedInstance().resetViewControllers();

        // HACK: Download several entities to cache data
        DBCacheHelper.sharedInstance().cacheEntityName("Tax");
        DBCacheHelper.sharedInstance().cacheEntityName("Department");
        DBCacheHelper.sharedInstance().cacheEntityName("WorkerRole");
        DBCacheHelper.sharedInstance().cacheEntityName("BookingZone");
        DBCacheHelper.sharedInstance().cacheEntityName("TimeRange");
        DBCacheHelper.sharedInstance().cacheEntityName("Language");
        DBCacheHelper.sharedInstance().cacheEntityName("Country");
        DBCacheHelper.sharedInstance().cacheEntityName("BusinessArea");
        DBCacheHelper.sharedInstance().cacheEntityName("Warehouse");
        DBCacheHelper.sharedInstance().cacheEntityName("Configuration");
        DBCacheHelper.sharedInstance().cacheEntityName("Place");        
        DBCacheHelper.sharedInstance().cacheEntityName("WineCellar");
        DBCacheHelper.sharedInstance().cacheEntityName("ProductCategory");

        MIONotificationCenter.defaultCenter().postNotification('WorkspaceDidChange', item);
    }

    private updateLocale(config){        

        // Set locale
        let region = config["region-locale"] ? config["region-locale"].replace(/\"/g, "") : "ES";
        let language = MIOCoreGetBrowserLanguage();
        MIOLocale._setCurrentLocale(language + "_" + region);
        
        let currencyISOStyle = config["show-currency-iso-code"];
        if (currencyISOStyle) this.currencyStyle = MIONumberFormatterStyle.CurrencyISOCodeStyle;
        else this.currencyStyle = MIONumberFormatterStyle.CurrencyStyle;

        // Reset number formatter
        this._currencyFormatter = null;
        this._longCurrencyFormatter = null;
        this._dateFormatter = null;
        this._dateTimeFormatter = null;
        this._timeFormatter = null;
        this._longDateTimeFormatter = null;


        DBHelper.queryObjectsWithCompletion("Country", null, MIOPredicate.predicateWithFormat("iso2 == " + region), [], this, function(countries){
            if (countries.length > 0) {
                let country = countries[0] as Country;
                AppHelper.sharedInstance().defaultCountry = country;
            }
        });

        DBHelper.queryObjectsWithCompletion("Warehouse", null, MIOPredicate.predicateWithFormat("identifier == " + this.selectedIdentifier), [], this, function(warehouses){
            if (warehouses.length > 0) {
                let wh = warehouses[0] as Warehouse;
                AppHelper.sharedInstance().defaultWarehouse = wh;
            }
        });

        DBHelper.queryObjectsWithCompletion("Tax", null, MIOPredicate.predicateWithFormat("isDefault == true"), [], this, function(taxes:Tax[]){
            if (taxes.length > 0) {
                let t = taxes[0];
                AppHelper.sharedInstance().defaultVAT = t;
            }
        });

        DBHelper.queryObjectsWithCompletion("BusinessArea", null, MIOPredicate.predicateWithFormat("identifier == " + this.selectedIdentifier), [], this, function(businessAreas:BusinessArea[]){
            if (businessAreas.length > 0) {
                let ba = businessAreas[0];
                AppHelper.sharedInstance().defaultBusinessArea = ba;
            }
        });

        DBHelper.queryObjectsWithCompletion( "Configuration", null, MIOPredicate.predicateWithFormat("name == 'additional-languages'"), [], this, function(configs:Configuration[]){
            if (configs.length > 0) {
                AppHelper.sharedInstance().aditionalLanguages = configs[0].value.split(",").map( function(lang:string){ return lang.trim(); });
            }
        } );
    }

    configDidChangeNotification(note:MIONotification){
        let config = note.object;
        this.updateLocale(config);        
    }

    registerForRemoteServers(identifier, identifierType){
        
        //let token = this.webService.token;
        // let company = (item instanceof Company) ? item.identifier : item.company.identifier;
        // let place = (item instanceof Place) ? item.identifier : null;

        // Register for remote events
        // DLRemoteNotificationServer.sharedInstance().login(token, company, place);
        // DLScriptingServer.sharedInstance().login(token, identifier, identifierType);
    }

    get dateFormatter(): MIODateFormatter {

        if (this._dateFormatter != null)
            return this._dateFormatter;

        this._dateFormatter = new MIODateFormatter();
        this._dateFormatter.init();
        this._dateFormatter.timeStyle = MIODateFormatterStyle.NoStyle;

        return this._dateFormatter;
    }

    private _dateTimeFormatter: MIODateFormatter = null;
    get dateTimeFormatter(): MIODateFormatter {

        if (this._dateTimeFormatter != null)
            return this._dateTimeFormatter;

        this._dateTimeFormatter = new MIODateFormatter();
        this._dateTimeFormatter.init();

        return this._dateTimeFormatter;
    }

    get longDateTimeFormatter():MIODateFormatter {

        if (this._longDateTimeFormatter != null)
            return this._longDateTimeFormatter;

        this._longDateTimeFormatter = new MIODateFormatter();
        this._longDateTimeFormatter.init();
        this._longDateTimeFormatter.dateStyle = MIODateFormatterStyle.FullStyle;

        return this._longDateTimeFormatter;
    }

    get longDateFormatter():MIODateFormatter {

        if (this._longDateTimeFormatter != null)
            return this._longDateTimeFormatter;

        this._longDateTimeFormatter = new MIODateFormatter();
        this._longDateTimeFormatter.init();
        this._longDateTimeFormatter.dateStyle = MIODateFormatterStyle.FullStyle;
        this._longDateTimeFormatter.timeStyle = MIODateFormatterStyle.NoStyle;

        return this._longDateTimeFormatter;
    }    

    get timeFormatter(): MIODateFormatter {

        if (this._timeFormatter != null)
            return this._timeFormatter;

        this._timeFormatter = new MIODateFormatter();
        this._timeFormatter.init();
        this._timeFormatter.dateStyle = MIODateFormatterStyle.NoStyle;

        return this._timeFormatter;
    }

    private _serverDateFormatter:MIOISO8601DateFormatter = null;
    get serverDateFormatter(): MIODateFormatter {

        if (this._serverDateFormatter != null)
            return this._serverDateFormatter;

        this._serverDateFormatter = new MIOISO8601DateFormatter();
        this._serverDateFormatter.init();
        this._serverDateFormatter.timeStyle = MIODateFormatterStyle.NoStyle;

        return this._serverDateFormatter;
    }

    private _serverTimeFormatter:MIOISO8601DateFormatter = null;
    get serverTimeFormatter(): MIODateFormatter {

        if (this._serverTimeFormatter != null)
            return this._serverTimeFormatter;

        this._serverTimeFormatter = new MIOISO8601DateFormatter();
        this._serverTimeFormatter.init();
        this._serverTimeFormatter.dateStyle = MIODateFormatterStyle.NoStyle;

        return this._serverTimeFormatter;
    }

    private _serverDateTimeFormatter:MIOISO8601DateFormatter = null;
    get serverDateTimeFormatter(): MIODateFormatter {

        if (this._serverDateTimeFormatter != null)
            return this._serverDateTimeFormatter;

        this._serverDateTimeFormatter = new MIOISO8601DateFormatter();
        this._serverDateTimeFormatter.init();

        return this._serverDateTimeFormatter;
    }

    private _currencyFormatter: MIONumberFormatter = null;
    get currencyFormatter(): MIONumberFormatter {
        if (this._currencyFormatter != null) return this._currencyFormatter;

        this._currencyFormatter = new MIONumberFormatter();
        this._currencyFormatter.init();
        this._currencyFormatter.numberStyle = this.currencyStyle;
        this._currencyFormatter.minimumFractionDigits = 2;
        this._currencyFormatter.maximumFractionDigits = 2;

        return this._currencyFormatter;
    }

    private _longCurrencyFormatter: MIONumberFormatter = null;
    get longCurrencyFormatter(): MIONumberFormatter {
        if (this._longCurrencyFormatter != null) return this._longCurrencyFormatter;

        this._longCurrencyFormatter = new MIONumberFormatter();
        this._longCurrencyFormatter.init();
        this._longCurrencyFormatter.numberStyle = this.currencyStyle;
        this._longCurrencyFormatter.minimumFractionDigits = 2;
        this._longCurrencyFormatter.maximumFractionDigits = 6;

        return this._longCurrencyFormatter;
    }

    private _numberFormatter: MIONumberFormatter = null;
    get numberFormatter(): MIONumberFormatter {

        if (this._numberFormatter != null)
            return this._numberFormatter;

        this._numberFormatter = new MIONumberFormatter();
        this._numberFormatter.init();
        this._numberFormatter.numberStyle = MIONumberFormatterStyle.DecimalStyle;
        this._numberFormatter.minimumFractionDigits = 0;
        this._numberFormatter.maximumFractionDigits = 3;

        return this._numberFormatter;
    }

    get integerFormatter(): MIONumberFormatter {

        if (this._integerFormatter != null)
            return this._integerFormatter;

        this._integerFormatter = new MIONumberFormatter();
        this._integerFormatter.init();
        this._integerFormatter.numberStyle = MIONumberFormatterStyle.DecimalStyle;
        this._integerFormatter.minimumFractionDigits = 0;
        this._integerFormatter.maximumFractionDigits = 0;

        return this._integerFormatter;
    }

    get percentNumberFormatter(): MIONumberFormatter {

        if (this._percentNumberFormatter != null)
            return this._percentNumberFormatter;

        this._percentNumberFormatter = new MIONumberFormatter();
        this._percentNumberFormatter.init();
        this._percentNumberFormatter.numberStyle = MIONumberFormatterStyle.PercentStyle;
        this._percentNumberFormatter.minimumFractionDigits = 2;
        this._percentNumberFormatter.maximumFractionDigits = 4;

        return this._percentNumberFormatter;
    }

    get managedObjectContext(): MIOManagedObjectContext {

        if (this._managedObjectContext != null)
            return this._managedObjectContext;

        this._managedObjectContext = new MIOManagedObjectContext();
        this._managedObjectContext.init();

        let coordinator = this.persistentStoreCoordinator;
        this._managedObjectContext.persistentStoreCoordinator = coordinator;                

        return this._managedObjectContext;
    }

    get managedObjectModel(): MIOManagedObjectModel {

        if (this._managedObjectModel)
            return this._managedObjectModel;

        this._managedObjectModel = new MIOManagedObjectModel();
        this._managedObjectModel.initWithContentsOfURL(MIOURL.urlWithString("datamodel.xml"));

        return this._managedObjectModel;
    }

    get persistentStoreCoordinator(): MIOPersistentStoreCoordinator {

        if (this._persistentStoreCoordinator)
            return this._persistentStoreCoordinator;

        let mom = this.managedObjectModel;
        if (!mom) throw new Error("No model to generate a store from app delegate"); //[self class], NSStringFromSelector(_cmd));

        let coordinator = new MIOPersistentStoreCoordinator();
        coordinator.initWithManagedObjectModel(mom);

        MIOPersistentStoreCoordinator.registerStoreClassForStoreType("MWSPersistentStore", MWSPersistentStore.type);

        let storeURL = MIOURL.urlWithString("store.dual-link.com");
        let store = coordinator.addPersistentStoreWithType(MWSPersistentStore.type, null, storeURL, null);
        // Add delegate to build url requests and cache
        this._webPersistentStore = store as MWSPersistentStore;
        this._webPersistentStore.delegate = this.webService;
        this._webPersistentStore.useSaveBlocks = true; //SettingsHelper.sharedInstance().isNewSync;

        this._persistentStoreCoordinator = coordinator;
        return this._persistentStoreCoordinator;
    }
    
    private _webPersistentStore:MWSPersistentStore = null;
    get webPersistentStore(): MWSPersistentStore {
        return this._webPersistentStore;
    }

    private ignoreEntities() {

        this._ignoreEntities["DashItem"] = true;
        this._ignoreEntities["Module"] = true;
        this._ignoreEntities["User"] = true;
        this._ignoreEntities["Company"] = true;
        this._ignoreEntities["Place"] = true;
        this._ignoreEntities["Section"] = true;
        this._ignoreEntities["Permission"] = true;
        this._ignoreEntities["CashDeskEntity"] = true;
        this._ignoreEntities["CashDeskSummaryItem"] = true;
        this._ignoreEntities["CashDeskSessionSummaryItem"] = true;
        this._ignoreEntities["Ticket"] = true;
        this._ignoreEntities["TicketGroupLine"] = true;
        this._ignoreEntities["TicketLine"] = true;
        this._ignoreEntities["StockProduct"] = true;
        this._ignoreEntities["LapsopayLocation"] = true;
        this._ignoreEntities["LapsopayPlace"] = true;
        
    }

    // WebPersistentStore delegate

    canQueryOnServerForEntityName(entityName: string) {

        let ignoreQuery = this._ignoreEntities[entityName];
        return ignoreQuery == true ? false : true;
    }

    predicateFetchOnServerForEntityName(entityName: string, predicate: MIOPredicate) {

        if (entityName == "ClientListItem"
            || entityName == 'StockProduct') return null;

        return predicate;
    }

    filterServerAttributeKey(entityName, property, value, comparator) {
       
        return null;
    }

}

