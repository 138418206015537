
interface ColumnFilterControllerDelegate 
{
    filterPredicateDidChange?(controller:ColumnFilterController);
}


class ColumnFilterController extends MIOObject
{
    private delegate = null;

    initWithDelegate(delegate){
        super.init();
        this.delegate = delegate;
    }

    private filtersByKey = {};    
    changeFilterPredicateForKey(key:string, filterPredicateFormat:string){
        if (filterPredicateFormat == null){
            delete this.filtersByKey[key];
        }
        else {
            this.filtersByKey[key] = filterPredicateFormat;
        }

        if (this.delegate != null && (typeof this.delegate.filterPredicateDidChange === "function")){
            this.delegate.filterPredicateDidChange(this);
        }
    }

    filterPredicateFormat(){
        let predicateFormat = "";

        let first = true;
        for (let key in this.filtersByKey){
            let filterFormat = this.filtersByKey[key];
            if (first == true){
                predicateFormat += "(" + filterFormat + ")";
                first = false;
            }
            else 
                predicateFormat += " AND (" + filterFormat + ")";
        }

        return predicateFormat.length > 0 ? predicateFormat : null;;
    }
}