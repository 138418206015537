
function DMRegisterModelClasses()
{
	 MIOCoreRegisterClassByName('AccountAnnotation_ManagedObject', AccountAnnotation_ManagedObject);
	 MIOCoreRegisterClassByName('AccountAnnotation', AccountAnnotation);
	 MIOCoreRegisterClassByName('AccountBalance_ManagedObject', AccountBalance_ManagedObject);
	 MIOCoreRegisterClassByName('AccountBalance', AccountBalance);
	 MIOCoreRegisterClassByName('AccountingEntry_ManagedObject', AccountingEntry_ManagedObject);
	 MIOCoreRegisterClassByName('AccountingEntry', AccountingEntry);
	 MIOCoreRegisterClassByName('AccountingJournalEntry_ManagedObject', AccountingJournalEntry_ManagedObject);
	 MIOCoreRegisterClassByName('AccountingJournalEntry', AccountingJournalEntry);
	 MIOCoreRegisterClassByName('AccountingSequence_ManagedObject', AccountingSequence_ManagedObject);
	 MIOCoreRegisterClassByName('AccountingSequence', AccountingSequence);
	 MIOCoreRegisterClassByName('AccountingType_ManagedObject', AccountingType_ManagedObject);
	 MIOCoreRegisterClassByName('AccountingType', AccountingType);
	 MIOCoreRegisterClassByName('AccountLine_ManagedObject', AccountLine_ManagedObject);
	 MIOCoreRegisterClassByName('AccountLine', AccountLine);
	 MIOCoreRegisterClassByName('Address_ManagedObject', Address_ManagedObject);
	 MIOCoreRegisterClassByName('Address', Address);
	 MIOCoreRegisterClassByName('AdyenBusinessLicense_ManagedObject', AdyenBusinessLicense_ManagedObject);
	 MIOCoreRegisterClassByName('AdyenBusinessLicense', AdyenBusinessLicense);
	 MIOCoreRegisterClassByName('AdyenDevicePaymentTransaction_ManagedObject', AdyenDevicePaymentTransaction_ManagedObject);
	 MIOCoreRegisterClassByName('AdyenDevicePaymentTransaction', AdyenDevicePaymentTransaction);
	 MIOCoreRegisterClassByName('AdyenPaymentDevice_ManagedObject', AdyenPaymentDevice_ManagedObject);
	 MIOCoreRegisterClassByName('AdyenPaymentDevice', AdyenPaymentDevice);
	 MIOCoreRegisterClassByName('AdyenPaymentEntity_ManagedObject', AdyenPaymentEntity_ManagedObject);
	 MIOCoreRegisterClassByName('AdyenPaymentEntity', AdyenPaymentEntity);
	 MIOCoreRegisterClassByName('AdyenPaymentEntityPaymethod_ManagedObject', AdyenPaymentEntityPaymethod_ManagedObject);
	 MIOCoreRegisterClassByName('AdyenPaymentEntityPaymethod', AdyenPaymentEntityPaymethod);
	 MIOCoreRegisterClassByName('AdyenPaymentEntityStore_ManagedObject', AdyenPaymentEntityStore_ManagedObject);
	 MIOCoreRegisterClassByName('AdyenPaymentEntityStore', AdyenPaymentEntityStore);
	 MIOCoreRegisterClassByName('AnalyticAccount_ManagedObject', AnalyticAccount_ManagedObject);
	 MIOCoreRegisterClassByName('AnalyticAccount', AnalyticAccount);
	 MIOCoreRegisterClassByName('Annotation_ManagedObject', Annotation_ManagedObject);
	 MIOCoreRegisterClassByName('Annotation', Annotation);
	 MIOCoreRegisterClassByName('AppArea_ManagedObject', AppArea_ManagedObject);
	 MIOCoreRegisterClassByName('AppArea', AppArea);
	 MIOCoreRegisterClassByName('AppConfiguration_ManagedObject', AppConfiguration_ManagedObject);
	 MIOCoreRegisterClassByName('AppConfiguration', AppConfiguration);
	 MIOCoreRegisterClassByName('Application_ManagedObject', Application_ManagedObject);
	 MIOCoreRegisterClassByName('Application', Application);
	 MIOCoreRegisterClassByName('ArchivedDocument_ManagedObject', ArchivedDocument_ManagedObject);
	 MIOCoreRegisterClassByName('ArchivedDocument', ArchivedDocument);
	 MIOCoreRegisterClassByName('ArchivedDocumentTemplate_ManagedObject', ArchivedDocumentTemplate_ManagedObject);
	 MIOCoreRegisterClassByName('ArchivedDocumentTemplate', ArchivedDocumentTemplate);
	 MIOCoreRegisterClassByName('ArchivedInvoice_ManagedObject', ArchivedInvoice_ManagedObject);
	 MIOCoreRegisterClassByName('ArchivedInvoice', ArchivedInvoice);
	 MIOCoreRegisterClassByName('ArchivedTicket_ManagedObject', ArchivedTicket_ManagedObject);
	 MIOCoreRegisterClassByName('ArchivedTicket', ArchivedTicket);
	 MIOCoreRegisterClassByName('ArchivedTicketLine_ManagedObject', ArchivedTicketLine_ManagedObject);
	 MIOCoreRegisterClassByName('ArchivedTicketLine', ArchivedTicketLine);
	 MIOCoreRegisterClassByName('ArchivedTicketLineModifier_ManagedObject', ArchivedTicketLineModifier_ManagedObject);
	 MIOCoreRegisterClassByName('ArchivedTicketLineModifier', ArchivedTicketLineModifier);
	 MIOCoreRegisterClassByName('ArchivedTicketLinesGroup_ManagedObject', ArchivedTicketLinesGroup_ManagedObject);
	 MIOCoreRegisterClassByName('ArchivedTicketLinesGroup', ArchivedTicketLinesGroup);
	 MIOCoreRegisterClassByName('Bank_ManagedObject', Bank_ManagedObject);
	 MIOCoreRegisterClassByName('Bank', Bank);
	 MIOCoreRegisterClassByName('BankAccount_ManagedObject', BankAccount_ManagedObject);
	 MIOCoreRegisterClassByName('BankAccount', BankAccount);
	 MIOCoreRegisterClassByName('BankAccountInfo_ManagedObject', BankAccountInfo_ManagedObject);
	 MIOCoreRegisterClassByName('BankAccountInfo', BankAccountInfo);
	 MIOCoreRegisterClassByName('BankLoan_ManagedObject', BankLoan_ManagedObject);
	 MIOCoreRegisterClassByName('BankLoan', BankLoan);
	 MIOCoreRegisterClassByName('BankMovement_ManagedObject', BankMovement_ManagedObject);
	 MIOCoreRegisterClassByName('BankMovement', BankMovement);
	 MIOCoreRegisterClassByName('BluetoothPrinter_ManagedObject', BluetoothPrinter_ManagedObject);
	 MIOCoreRegisterClassByName('BluetoothPrinter', BluetoothPrinter);
	 MIOCoreRegisterClassByName('Booking_ManagedObject', Booking_ManagedObject);
	 MIOCoreRegisterClassByName('Booking', Booking);
	 MIOCoreRegisterClassByName('Booking_BookingLocation_ManagedObject', Booking_BookingLocation_ManagedObject);
	 MIOCoreRegisterClassByName('Booking_BookingLocation', Booking_BookingLocation);
	 MIOCoreRegisterClassByName('Booking_BookingModifier_ManagedObject', Booking_BookingModifier_ManagedObject);
	 MIOCoreRegisterClassByName('Booking_BookingModifier', Booking_BookingModifier);
	 MIOCoreRegisterClassByName('Booking_BookingService_ManagedObject', Booking_BookingService_ManagedObject);
	 MIOCoreRegisterClassByName('Booking_BookingService', Booking_BookingService);
	 MIOCoreRegisterClassByName('Booking_BookingWorker_ManagedObject', Booking_BookingWorker_ManagedObject);
	 MIOCoreRegisterClassByName('Booking_BookingWorker', Booking_BookingWorker);
	 MIOCoreRegisterClassByName('Booking_BookingZone_ManagedObject', Booking_BookingZone_ManagedObject);
	 MIOCoreRegisterClassByName('Booking_BookingZone', Booking_BookingZone);
	 MIOCoreRegisterClassByName('BookingChannel_ManagedObject', BookingChannel_ManagedObject);
	 MIOCoreRegisterClassByName('BookingChannel', BookingChannel);
	 MIOCoreRegisterClassByName('BookingGroup_ManagedObject', BookingGroup_ManagedObject);
	 MIOCoreRegisterClassByName('BookingGroup', BookingGroup);
	 MIOCoreRegisterClassByName('BookingGuest_ManagedObject', BookingGuest_ManagedObject);
	 MIOCoreRegisterClassByName('BookingGuest', BookingGuest);
	 MIOCoreRegisterClassByName('BookingGuestList_ManagedObject', BookingGuestList_ManagedObject);
	 MIOCoreRegisterClassByName('BookingGuestList', BookingGuestList);
	 MIOCoreRegisterClassByName('BookingLog_ManagedObject', BookingLog_ManagedObject);
	 MIOCoreRegisterClassByName('BookingLog', BookingLog);
	 MIOCoreRegisterClassByName('BookingModifier_ManagedObject', BookingModifier_ManagedObject);
	 MIOCoreRegisterClassByName('BookingModifier', BookingModifier);
	 MIOCoreRegisterClassByName('BookingModifierCategory_ManagedObject', BookingModifierCategory_ManagedObject);
	 MIOCoreRegisterClassByName('BookingModifierCategory', BookingModifierCategory);
	 MIOCoreRegisterClassByName('BookingModifierCategory_BookingZone_ManagedObject', BookingModifierCategory_BookingZone_ManagedObject);
	 MIOCoreRegisterClassByName('BookingModifierCategory_BookingZone', BookingModifierCategory_BookingZone);
	 MIOCoreRegisterClassByName('BookingPrescriptor_ManagedObject', BookingPrescriptor_ManagedObject);
	 MIOCoreRegisterClassByName('BookingPrescriptor', BookingPrescriptor);
	 MIOCoreRegisterClassByName('BookingRecommendation_ManagedObject', BookingRecommendation_ManagedObject);
	 MIOCoreRegisterClassByName('BookingRecommendation', BookingRecommendation);
	 MIOCoreRegisterClassByName('BookingSource_ManagedObject', BookingSource_ManagedObject);
	 MIOCoreRegisterClassByName('BookingSource', BookingSource);
	 MIOCoreRegisterClassByName('BookingTimeRangePresetConfiguration_ManagedObject', BookingTimeRangePresetConfiguration_ManagedObject);
	 MIOCoreRegisterClassByName('BookingTimeRangePresetConfiguration', BookingTimeRangePresetConfiguration);
	 MIOCoreRegisterClassByName('BookingZone_ManagedObject', BookingZone_ManagedObject);
	 MIOCoreRegisterClassByName('BookingZone', BookingZone);
	 MIOCoreRegisterClassByName('BookingZoneConfiguration_ManagedObject', BookingZoneConfiguration_ManagedObject);
	 MIOCoreRegisterClassByName('BookingZoneConfiguration', BookingZoneConfiguration);
	 MIOCoreRegisterClassByName('BusinessArea_ManagedObject', BusinessArea_ManagedObject);
	 MIOCoreRegisterClassByName('BusinessArea', BusinessArea);
	 MIOCoreRegisterClassByName('BusinessLicense_ManagedObject', BusinessLicense_ManagedObject);
	 MIOCoreRegisterClassByName('BusinessLicense', BusinessLicense);
	 MIOCoreRegisterClassByName('BusinessLog_ManagedObject', BusinessLog_ManagedObject);
	 MIOCoreRegisterClassByName('BusinessLog', BusinessLog);
	 MIOCoreRegisterClassByName('BuyOrder_ManagedObject', BuyOrder_ManagedObject);
	 MIOCoreRegisterClassByName('BuyOrder', BuyOrder);
	 MIOCoreRegisterClassByName('BuyOrderLine_ManagedObject', BuyOrderLine_ManagedObject);
	 MIOCoreRegisterClassByName('BuyOrderLine', BuyOrderLine);
	 MIOCoreRegisterClassByName('Card_ManagedObject', Card_ManagedObject);
	 MIOCoreRegisterClassByName('Card', Card);
	 MIOCoreRegisterClassByName('CashDesk_ManagedObject', CashDesk_ManagedObject);
	 MIOCoreRegisterClassByName('CashDesk', CashDesk);
	 MIOCoreRegisterClassByName('CashDeskBalance_ManagedObject', CashDeskBalance_ManagedObject);
	 MIOCoreRegisterClassByName('CashDeskBalance', CashDeskBalance);
	 MIOCoreRegisterClassByName('CashDeskCurrency_ManagedObject', CashDeskCurrency_ManagedObject);
	 MIOCoreRegisterClassByName('CashDeskCurrency', CashDeskCurrency);
	 MIOCoreRegisterClassByName('CashDeskLine_ManagedObject', CashDeskLine_ManagedObject);
	 MIOCoreRegisterClassByName('CashDeskLine', CashDeskLine);
	 MIOCoreRegisterClassByName('CashDeskOperation_ManagedObject', CashDeskOperation_ManagedObject);
	 MIOCoreRegisterClassByName('CashDeskOperation', CashDeskOperation);
	 MIOCoreRegisterClassByName('CashDeskSession_ManagedObject', CashDeskSession_ManagedObject);
	 MIOCoreRegisterClassByName('CashDeskSession', CashDeskSession);
	 MIOCoreRegisterClassByName('CashDeskSessionBalance_ManagedObject', CashDeskSessionBalance_ManagedObject);
	 MIOCoreRegisterClassByName('CashDeskSessionBalance', CashDeskSessionBalance);
	 MIOCoreRegisterClassByName('CashOnDeliveryPaymentEntity_ManagedObject', CashOnDeliveryPaymentEntity_ManagedObject);
	 MIOCoreRegisterClassByName('CashOnDeliveryPaymentEntity', CashOnDeliveryPaymentEntity);
	 MIOCoreRegisterClassByName('CategoryPlace_ManagedObject', CategoryPlace_ManagedObject);
	 MIOCoreRegisterClassByName('CategoryPlace', CategoryPlace);
	 MIOCoreRegisterClassByName('CategoryTag_ManagedObject', CategoryTag_ManagedObject);
	 MIOCoreRegisterClassByName('CategoryTag', CategoryTag);
	 MIOCoreRegisterClassByName('Changelog_ManagedObject', Changelog_ManagedObject);
	 MIOCoreRegisterClassByName('Changelog', Changelog);
	 MIOCoreRegisterClassByName('Channel_ManagedObject', Channel_ManagedObject);
	 MIOCoreRegisterClassByName('Channel', Channel);
	 MIOCoreRegisterClassByName('CheckPoint_ManagedObject', CheckPoint_ManagedObject);
	 MIOCoreRegisterClassByName('CheckPoint', CheckPoint);
	 MIOCoreRegisterClassByName('CheffLine_ManagedObject', CheffLine_ManagedObject);
	 MIOCoreRegisterClassByName('CheffLine', CheffLine);
	 MIOCoreRegisterClassByName('Client_ManagedObject', Client_ManagedObject);
	 MIOCoreRegisterClassByName('Client', Client);
	 MIOCoreRegisterClassByName('ClientAccountBalance_ManagedObject', ClientAccountBalance_ManagedObject);
	 MIOCoreRegisterClassByName('ClientAccountBalance', ClientAccountBalance);
	 MIOCoreRegisterClassByName('ClientAction_ManagedObject', ClientAction_ManagedObject);
	 MIOCoreRegisterClassByName('ClientAction', ClientAction);
	 MIOCoreRegisterClassByName('ClientDebtBalance_ManagedObject', ClientDebtBalance_ManagedObject);
	 MIOCoreRegisterClassByName('ClientDebtBalance', ClientDebtBalance);
	 MIOCoreRegisterClassByName('ClientLicenseOffer_ManagedObject', ClientLicenseOffer_ManagedObject);
	 MIOCoreRegisterClassByName('ClientLicenseOffer', ClientLicenseOffer);
	 MIOCoreRegisterClassByName('ClientLoyaltyBalance_ManagedObject', ClientLoyaltyBalance_ManagedObject);
	 MIOCoreRegisterClassByName('ClientLoyaltyBalance', ClientLoyaltyBalance);
	 MIOCoreRegisterClassByName('ClientPaymentInstallment_ManagedObject', ClientPaymentInstallment_ManagedObject);
	 MIOCoreRegisterClassByName('ClientPaymentInstallment', ClientPaymentInstallment);
	 MIOCoreRegisterClassByName('CommunicationTemplate_ManagedObject', CommunicationTemplate_ManagedObject);
	 MIOCoreRegisterClassByName('CommunicationTemplate', CommunicationTemplate);
	 MIOCoreRegisterClassByName('Company_ManagedObject', Company_ManagedObject);
	 MIOCoreRegisterClassByName('Company', Company);
	 MIOCoreRegisterClassByName('Component_ManagedObject', Component_ManagedObject);
	 MIOCoreRegisterClassByName('Component', Component);
	 MIOCoreRegisterClassByName('Configuration_ManagedObject', Configuration_ManagedObject);
	 MIOCoreRegisterClassByName('Configuration', Configuration);
	 MIOCoreRegisterClassByName('ConsumptionProfile_ManagedObject', ConsumptionProfile_ManagedObject);
	 MIOCoreRegisterClassByName('ConsumptionProfile', ConsumptionProfile);
	 MIOCoreRegisterClassByName('Contact_ManagedObject', Contact_ManagedObject);
	 MIOCoreRegisterClassByName('Contact', Contact);
	 MIOCoreRegisterClassByName('ContainerCustom_ManagedObject', ContainerCustom_ManagedObject);
	 MIOCoreRegisterClassByName('ContainerCustom', ContainerCustom);
	 MIOCoreRegisterClassByName('ContainerProduct_ManagedObject', ContainerProduct_ManagedObject);
	 MIOCoreRegisterClassByName('ContainerProduct', ContainerProduct);
	 MIOCoreRegisterClassByName('ContainerUnits_ManagedObject', ContainerUnits_ManagedObject);
	 MIOCoreRegisterClassByName('ContainerUnits', ContainerUnits);
	 MIOCoreRegisterClassByName('CoreAccounting_ManagedObject', CoreAccounting_ManagedObject);
	 MIOCoreRegisterClassByName('CoreAccounting', CoreAccounting);
	 MIOCoreRegisterClassByName('CoreAnnotation_ManagedObject', CoreAnnotation_ManagedObject);
	 MIOCoreRegisterClassByName('CoreAnnotation', CoreAnnotation);
	 MIOCoreRegisterClassByName('CoreEntity_ManagedObject', CoreEntity_ManagedObject);
	 MIOCoreRegisterClassByName('CoreEntity', CoreEntity);
	 MIOCoreRegisterClassByName('CoreEntityUpdatedBy_ManagedObject', CoreEntityUpdatedBy_ManagedObject);
	 MIOCoreRegisterClassByName('CoreEntityUpdatedBy', CoreEntityUpdatedBy);
	 MIOCoreRegisterClassByName('Country_ManagedObject', Country_ManagedObject);
	 MIOCoreRegisterClassByName('Country', Country);
	 MIOCoreRegisterClassByName('CronJob_ManagedObject', CronJob_ManagedObject);
	 MIOCoreRegisterClassByName('CronJob', CronJob);
	 MIOCoreRegisterClassByName('Currency_ManagedObject', Currency_ManagedObject);
	 MIOCoreRegisterClassByName('Currency', Currency);
	 MIOCoreRegisterClassByName('CustomConcept_ManagedObject', CustomConcept_ManagedObject);
	 MIOCoreRegisterClassByName('CustomConcept', CustomConcept);
	 MIOCoreRegisterClassByName('CustomInputNote_ManagedObject', CustomInputNote_ManagedObject);
	 MIOCoreRegisterClassByName('CustomInputNote', CustomInputNote);
	 MIOCoreRegisterClassByName('CustomInputNoteLine_ManagedObject', CustomInputNoteLine_ManagedObject);
	 MIOCoreRegisterClassByName('CustomInputNoteLine', CustomInputNoteLine);
	 MIOCoreRegisterClassByName('CustomLine_ManagedObject', CustomLine_ManagedObject);
	 MIOCoreRegisterClassByName('CustomLine', CustomLine);
	 MIOCoreRegisterClassByName('CustomOutputNote_ManagedObject', CustomOutputNote_ManagedObject);
	 MIOCoreRegisterClassByName('CustomOutputNote', CustomOutputNote);
	 MIOCoreRegisterClassByName('CustomOutputNoteLine_ManagedObject', CustomOutputNoteLine_ManagedObject);
	 MIOCoreRegisterClassByName('CustomOutputNoteLine', CustomOutputNoteLine);
	 MIOCoreRegisterClassByName('Dashboard_ManagedObject', Dashboard_ManagedObject);
	 MIOCoreRegisterClassByName('Dashboard', Dashboard);
	 MIOCoreRegisterClassByName('DashBoardItem_ManagedObject', DashBoardItem_ManagedObject);
	 MIOCoreRegisterClassByName('DashBoardItem', DashBoardItem);
	 MIOCoreRegisterClassByName('DBDocument_ManagedObject', DBDocument_ManagedObject);
	 MIOCoreRegisterClassByName('DBDocument', DBDocument);
	 MIOCoreRegisterClassByName('DBDocumentLine_ManagedObject', DBDocumentLine_ManagedObject);
	 MIOCoreRegisterClassByName('DBDocumentLine', DBDocumentLine);
	 MIOCoreRegisterClassByName('DBDocumentTitleLine_ManagedObject', DBDocumentTitleLine_ManagedObject);
	 MIOCoreRegisterClassByName('DBDocumentTitleLine', DBDocumentTitleLine);
	 MIOCoreRegisterClassByName('DBImage_ManagedObject', DBImage_ManagedObject);
	 MIOCoreRegisterClassByName('DBImage', DBImage);
	 MIOCoreRegisterClassByName('DebtLine_ManagedObject', DebtLine_ManagedObject);
	 MIOCoreRegisterClassByName('DebtLine', DebtLine);
	 MIOCoreRegisterClassByName('DeliveryNote_ManagedObject', DeliveryNote_ManagedObject);
	 MIOCoreRegisterClassByName('DeliveryNote', DeliveryNote);
	 MIOCoreRegisterClassByName('DeliveryNoteLine_ManagedObject', DeliveryNoteLine_ManagedObject);
	 MIOCoreRegisterClassByName('DeliveryNoteLine', DeliveryNoteLine);
	 MIOCoreRegisterClassByName('DeliveryPostalCode_ManagedObject', DeliveryPostalCode_ManagedObject);
	 MIOCoreRegisterClassByName('DeliveryPostalCode', DeliveryPostalCode);
	 MIOCoreRegisterClassByName('DeliveryRoute_ManagedObject', DeliveryRoute_ManagedObject);
	 MIOCoreRegisterClassByName('DeliveryRoute', DeliveryRoute);
	 MIOCoreRegisterClassByName('DeliveryTimeRangePresetConfiguration_ManagedObject', DeliveryTimeRangePresetConfiguration_ManagedObject);
	 MIOCoreRegisterClassByName('DeliveryTimeRangePresetConfiguration', DeliveryTimeRangePresetConfiguration);
	 MIOCoreRegisterClassByName('Department_ManagedObject', Department_ManagedObject);
	 MIOCoreRegisterClassByName('Department', Department);
	 MIOCoreRegisterClassByName('DevicePaymentTransaction_ManagedObject', DevicePaymentTransaction_ManagedObject);
	 MIOCoreRegisterClassByName('DevicePaymentTransaction', DevicePaymentTransaction);
	 MIOCoreRegisterClassByName('DevicePrintProfileConfiguration_ManagedObject', DevicePrintProfileConfiguration_ManagedObject);
	 MIOCoreRegisterClassByName('DevicePrintProfileConfiguration', DevicePrintProfileConfiguration);
	 MIOCoreRegisterClassByName('Distributor_ManagedObject', Distributor_ManagedObject);
	 MIOCoreRegisterClassByName('Distributor', Distributor);
	 MIOCoreRegisterClassByName('DockPrinter_ManagedObject', DockPrinter_ManagedObject);
	 MIOCoreRegisterClassByName('DockPrinter', DockPrinter);
	 MIOCoreRegisterClassByName('DocumentSequence_ManagedObject', DocumentSequence_ManagedObject);
	 MIOCoreRegisterClassByName('DocumentSequence', DocumentSequence);
	 MIOCoreRegisterClassByName('DocumentTemplate_ManagedObject', DocumentTemplate_ManagedObject);
	 MIOCoreRegisterClassByName('DocumentTemplate', DocumentTemplate);
	 MIOCoreRegisterClassByName('EconomicAccountLine_ManagedObject', EconomicAccountLine_ManagedObject);
	 MIOCoreRegisterClassByName('EconomicAccountLine', EconomicAccountLine);
	 MIOCoreRegisterClassByName('EconomicEntity_ManagedObject', EconomicEntity_ManagedObject);
	 MIOCoreRegisterClassByName('EconomicEntity', EconomicEntity);
	 MIOCoreRegisterClassByName('EllaborationCenter_ManagedObject', EllaborationCenter_ManagedObject);
	 MIOCoreRegisterClassByName('EllaborationCenter', EllaborationCenter);
	 MIOCoreRegisterClassByName('EllaborationConsumptionWarehouse_ManagedObject', EllaborationConsumptionWarehouse_ManagedObject);
	 MIOCoreRegisterClassByName('EllaborationConsumptionWarehouse', EllaborationConsumptionWarehouse);
	 MIOCoreRegisterClassByName('EmailTemplate_ManagedObject', EmailTemplate_ManagedObject);
	 MIOCoreRegisterClassByName('EmailTemplate', EmailTemplate);
	 MIOCoreRegisterClassByName('Employee_ManagedObject', Employee_ManagedObject);
	 MIOCoreRegisterClassByName('Employee', Employee);
	 MIOCoreRegisterClassByName('EmployeeLocation_ManagedObject', EmployeeLocation_ManagedObject);
	 MIOCoreRegisterClassByName('EmployeeLocation', EmployeeLocation);
	 MIOCoreRegisterClassByName('EmployeePayroll_ManagedObject', EmployeePayroll_ManagedObject);
	 MIOCoreRegisterClassByName('EmployeePayroll', EmployeePayroll);
	 MIOCoreRegisterClassByName('EmployeePayrollConcept_ManagedObject', EmployeePayrollConcept_ManagedObject);
	 MIOCoreRegisterClassByName('EmployeePayrollConcept', EmployeePayrollConcept);
	 MIOCoreRegisterClassByName('EmployeePayrollTemplate_ManagedObject', EmployeePayrollTemplate_ManagedObject);
	 MIOCoreRegisterClassByName('EmployeePayrollTemplate', EmployeePayrollTemplate);
	 MIOCoreRegisterClassByName('EmployeeSalaryConcept_ManagedObject', EmployeeSalaryConcept_ManagedObject);
	 MIOCoreRegisterClassByName('EmployeeSalaryConcept', EmployeeSalaryConcept);
	 MIOCoreRegisterClassByName('EmployeeSalaryTransaction_ManagedObject', EmployeeSalaryTransaction_ManagedObject);
	 MIOCoreRegisterClassByName('EmployeeSalaryTransaction', EmployeeSalaryTransaction);
	 MIOCoreRegisterClassByName('ErrorLog_ManagedObject', ErrorLog_ManagedObject);
	 MIOCoreRegisterClassByName('ErrorLog', ErrorLog);
	 MIOCoreRegisterClassByName('EventInvitationRule_ManagedObject', EventInvitationRule_ManagedObject);
	 MIOCoreRegisterClassByName('EventInvitationRule', EventInvitationRule);
	 MIOCoreRegisterClassByName('EventTicket_ManagedObject', EventTicket_ManagedObject);
	 MIOCoreRegisterClassByName('EventTicket', EventTicket);
	 MIOCoreRegisterClassByName('Expense_ManagedObject', Expense_ManagedObject);
	 MIOCoreRegisterClassByName('Expense', Expense);
	 MIOCoreRegisterClassByName('ExpenseReconcileTransaction_ManagedObject', ExpenseReconcileTransaction_ManagedObject);
	 MIOCoreRegisterClassByName('ExpenseReconcileTransaction', ExpenseReconcileTransaction);
	 MIOCoreRegisterClassByName('FavouriteProduct_ManagedObject', FavouriteProduct_ManagedObject);
	 MIOCoreRegisterClassByName('FavouriteProduct', FavouriteProduct);
	 MIOCoreRegisterClassByName('FavouriteProductModifier_ManagedObject', FavouriteProductModifier_ManagedObject);
	 MIOCoreRegisterClassByName('FavouriteProductModifier', FavouriteProductModifier);
	 MIOCoreRegisterClassByName('FileAttachment_ManagedObject', FileAttachment_ManagedObject);
	 MIOCoreRegisterClassByName('FileAttachment', FileAttachment);
	 MIOCoreRegisterClassByName('FileContent_ManagedObject', FileContent_ManagedObject);
	 MIOCoreRegisterClassByName('FileContent', FileContent);
	 MIOCoreRegisterClassByName('FileURL_ManagedObject', FileURL_ManagedObject);
	 MIOCoreRegisterClassByName('FileURL', FileURL);
	 MIOCoreRegisterClassByName('FixedDiscount_ManagedObject', FixedDiscount_ManagedObject);
	 MIOCoreRegisterClassByName('FixedDiscount', FixedDiscount);
	 MIOCoreRegisterClassByName('Format_ManagedObject', Format_ManagedObject);
	 MIOCoreRegisterClassByName('Format', Format);
	 MIOCoreRegisterClassByName('FreeProduct_ManagedObject', FreeProduct_ManagedObject);
	 MIOCoreRegisterClassByName('FreeProduct', FreeProduct);
	 MIOCoreRegisterClassByName('GeneratePromotionalCode_ManagedObject', GeneratePromotionalCode_ManagedObject);
	 MIOCoreRegisterClassByName('GeneratePromotionalCode', GeneratePromotionalCode);
	 MIOCoreRegisterClassByName('GenericEventInvitationRule_ManagedObject', GenericEventInvitationRule_ManagedObject);
	 MIOCoreRegisterClassByName('GenericEventInvitationRule', GenericEventInvitationRule);
	 MIOCoreRegisterClassByName('GenericInvitationRule_ManagedObject', GenericInvitationRule_ManagedObject);
	 MIOCoreRegisterClassByName('GenericInvitationRule', GenericInvitationRule);
	 MIOCoreRegisterClassByName('IntallmentReconcileTransaction_ManagedObject', IntallmentReconcileTransaction_ManagedObject);
	 MIOCoreRegisterClassByName('IntallmentReconcileTransaction', IntallmentReconcileTransaction);
	 MIOCoreRegisterClassByName('Integrator_ManagedObject', Integrator_ManagedObject);
	 MIOCoreRegisterClassByName('Integrator', Integrator);
	 MIOCoreRegisterClassByName('IntegratorConfig_ManagedObject', IntegratorConfig_ManagedObject);
	 MIOCoreRegisterClassByName('IntegratorConfig', IntegratorConfig);
	 MIOCoreRegisterClassByName('IntegratorConfigTags_ManagedObject', IntegratorConfigTags_ManagedObject);
	 MIOCoreRegisterClassByName('IntegratorConfigTags', IntegratorConfigTags);
	 MIOCoreRegisterClassByName('IntegratorLog_ManagedObject', IntegratorLog_ManagedObject);
	 MIOCoreRegisterClassByName('IntegratorLog', IntegratorLog);
	 MIOCoreRegisterClassByName('IntegratorMailchimpQueue_ManagedObject', IntegratorMailchimpQueue_ManagedObject);
	 MIOCoreRegisterClassByName('IntegratorMailchimpQueue', IntegratorMailchimpQueue);
	 MIOCoreRegisterClassByName('IntegratorMapping_ManagedObject', IntegratorMapping_ManagedObject);
	 MIOCoreRegisterClassByName('IntegratorMapping', IntegratorMapping);
	 MIOCoreRegisterClassByName('IntegratorMappingInfo_ManagedObject', IntegratorMappingInfo_ManagedObject);
	 MIOCoreRegisterClassByName('IntegratorMappingInfo', IntegratorMappingInfo);
	 MIOCoreRegisterClassByName('IntegratorMappingTargetEntity_ManagedObject', IntegratorMappingTargetEntity_ManagedObject);
	 MIOCoreRegisterClassByName('IntegratorMappingTargetEntity', IntegratorMappingTargetEntity);
	 MIOCoreRegisterClassByName('IntegratorMappingView_ManagedObject', IntegratorMappingView_ManagedObject);
	 MIOCoreRegisterClassByName('IntegratorMappingView', IntegratorMappingView);
	 MIOCoreRegisterClassByName('IntegratorRawQueue_ManagedObject', IntegratorRawQueue_ManagedObject);
	 MIOCoreRegisterClassByName('IntegratorRawQueue', IntegratorRawQueue);
	 MIOCoreRegisterClassByName('IntegratorSAPStockNoteLine_ManagedObject', IntegratorSAPStockNoteLine_ManagedObject);
	 MIOCoreRegisterClassByName('IntegratorSAPStockNoteLine', IntegratorSAPStockNoteLine);
	 MIOCoreRegisterClassByName('IntegratorSAPStockNoteLineQueue_ManagedObject', IntegratorSAPStockNoteLineQueue_ManagedObject);
	 MIOCoreRegisterClassByName('IntegratorSAPStockNoteLineQueue', IntegratorSAPStockNoteLineQueue);
	 MIOCoreRegisterClassByName('IntegratorSync_ManagedObject', IntegratorSync_ManagedObject);
	 MIOCoreRegisterClassByName('IntegratorSync', IntegratorSync);
	 MIOCoreRegisterClassByName('IntegratorSyncEntityQueue_ManagedObject', IntegratorSyncEntityQueue_ManagedObject);
	 MIOCoreRegisterClassByName('IntegratorSyncEntityQueue', IntegratorSyncEntityQueue);
	 MIOCoreRegisterClassByName('Intermediary_ManagedObject', Intermediary_ManagedObject);
	 MIOCoreRegisterClassByName('Intermediary', Intermediary);
	 MIOCoreRegisterClassByName('IntermediaryLicenseRate_ManagedObject', IntermediaryLicenseRate_ManagedObject);
	 MIOCoreRegisterClassByName('IntermediaryLicenseRate', IntermediaryLicenseRate);
	 MIOCoreRegisterClassByName('Interval_ManagedObject', Interval_ManagedObject);
	 MIOCoreRegisterClassByName('Interval', Interval);
	 MIOCoreRegisterClassByName('InventoryNote_ManagedObject', InventoryNote_ManagedObject);
	 MIOCoreRegisterClassByName('InventoryNote', InventoryNote);
	 MIOCoreRegisterClassByName('InventoryNoteLine_ManagedObject', InventoryNoteLine_ManagedObject);
	 MIOCoreRegisterClassByName('InventoryNoteLine', InventoryNoteLine);
	 MIOCoreRegisterClassByName('InvitationLine_ManagedObject', InvitationLine_ManagedObject);
	 MIOCoreRegisterClassByName('InvitationLine', InvitationLine);
	 MIOCoreRegisterClassByName('InvitationResponsible_ManagedObject', InvitationResponsible_ManagedObject);
	 MIOCoreRegisterClassByName('InvitationResponsible', InvitationResponsible);
	 MIOCoreRegisterClassByName('InvitationRule_ManagedObject', InvitationRule_ManagedObject);
	 MIOCoreRegisterClassByName('InvitationRule', InvitationRule);
	 MIOCoreRegisterClassByName('InvitationRulePreset_ManagedObject', InvitationRulePreset_ManagedObject);
	 MIOCoreRegisterClassByName('InvitationRulePreset', InvitationRulePreset);
	 MIOCoreRegisterClassByName('Invoice_ManagedObject', Invoice_ManagedObject);
	 MIOCoreRegisterClassByName('Invoice', Invoice);
	 MIOCoreRegisterClassByName('InvoiceLine_ManagedObject', InvoiceLine_ManagedObject);
	 MIOCoreRegisterClassByName('InvoiceLine', InvoiceLine);
	 MIOCoreRegisterClassByName('JobTask_ManagedObject', JobTask_ManagedObject);
	 MIOCoreRegisterClassByName('JobTask', JobTask);
	 MIOCoreRegisterClassByName('Language_ManagedObject', Language_ManagedObject);
	 MIOCoreRegisterClassByName('Language', Language);
	 MIOCoreRegisterClassByName('LeaveReasonLog_ManagedObject', LeaveReasonLog_ManagedObject);
	 MIOCoreRegisterClassByName('LeaveReasonLog', LeaveReasonLog);
	 MIOCoreRegisterClassByName('LeaveReasonType_ManagedObject', LeaveReasonType_ManagedObject);
	 MIOCoreRegisterClassByName('LeaveReasonType', LeaveReasonType);
	 MIOCoreRegisterClassByName('LedgerAccount_ManagedObject', LedgerAccount_ManagedObject);
	 MIOCoreRegisterClassByName('LedgerAccount', LedgerAccount);
	 MIOCoreRegisterClassByName('LedgerAccountBalance_ManagedObject', LedgerAccountBalance_ManagedObject);
	 MIOCoreRegisterClassByName('LedgerAccountBalance', LedgerAccountBalance);
	 MIOCoreRegisterClassByName('LegalEntity_ManagedObject', LegalEntity_ManagedObject);
	 MIOCoreRegisterClassByName('LegalEntity', LegalEntity);
	 MIOCoreRegisterClassByName('LegalEntityBankAccountInfo_ManagedObject', LegalEntityBankAccountInfo_ManagedObject);
	 MIOCoreRegisterClassByName('LegalEntityBankAccountInfo', LegalEntityBankAccountInfo);
	 MIOCoreRegisterClassByName('LegalEntityLoan_ManagedObject', LegalEntityLoan_ManagedObject);
	 MIOCoreRegisterClassByName('LegalEntityLoan', LegalEntityLoan);
	 MIOCoreRegisterClassByName('LegalEntityPaymentInstallment_ManagedObject', LegalEntityPaymentInstallment_ManagedObject);
	 MIOCoreRegisterClassByName('LegalEntityPaymentInstallment', LegalEntityPaymentInstallment);
	 MIOCoreRegisterClassByName('LegalTax_ManagedObject', LegalTax_ManagedObject);
	 MIOCoreRegisterClassByName('LegalTax', LegalTax);
	 MIOCoreRegisterClassByName('LegalTaxType_ManagedObject', LegalTaxType_ManagedObject);
	 MIOCoreRegisterClassByName('LegalTaxType', LegalTaxType);
	 MIOCoreRegisterClassByName('License_ManagedObject', License_ManagedObject);
	 MIOCoreRegisterClassByName('License', License);
	 MIOCoreRegisterClassByName('LicenseFeeInvoiceLine_ManagedObject', LicenseFeeInvoiceLine_ManagedObject);
	 MIOCoreRegisterClassByName('LicenseFeeInvoiceLine', LicenseFeeInvoiceLine);
	 MIOCoreRegisterClassByName('LicenseInvoice_ManagedObject', LicenseInvoice_ManagedObject);
	 MIOCoreRegisterClassByName('LicenseInvoice', LicenseInvoice);
	 MIOCoreRegisterClassByName('LicenseInvoiceBusinessLine_ManagedObject', LicenseInvoiceBusinessLine_ManagedObject);
	 MIOCoreRegisterClassByName('LicenseInvoiceBusinessLine', LicenseInvoiceBusinessLine);
	 MIOCoreRegisterClassByName('LicenseInvoiceLine_ManagedObject', LicenseInvoiceLine_ManagedObject);
	 MIOCoreRegisterClassByName('LicenseInvoiceLine', LicenseInvoiceLine);
	 MIOCoreRegisterClassByName('LicensePaymentInstallment_ManagedObject', LicensePaymentInstallment_ManagedObject);
	 MIOCoreRegisterClassByName('LicensePaymentInstallment', LicensePaymentInstallment);
	 MIOCoreRegisterClassByName('LicenseProduct_ManagedObject', LicenseProduct_ManagedObject);
	 MIOCoreRegisterClassByName('LicenseProduct', LicenseProduct);
	 MIOCoreRegisterClassByName('LicenseToken_ManagedObject', LicenseToken_ManagedObject);
	 MIOCoreRegisterClassByName('LicenseToken', LicenseToken);
	 MIOCoreRegisterClassByName('LicenseType_ManagedObject', LicenseType_ManagedObject);
	 MIOCoreRegisterClassByName('LicenseType', LicenseType);
	 MIOCoreRegisterClassByName('LoadEconomicAccount_ManagedObject', LoadEconomicAccount_ManagedObject);
	 MIOCoreRegisterClassByName('LoadEconomicAccount', LoadEconomicAccount);
	 MIOCoreRegisterClassByName('Loan_ManagedObject', Loan_ManagedObject);
	 MIOCoreRegisterClassByName('Loan', Loan);
	 MIOCoreRegisterClassByName('LoanPaymentInstallment_ManagedObject', LoanPaymentInstallment_ManagedObject);
	 MIOCoreRegisterClassByName('LoanPaymentInstallment', LoanPaymentInstallment);
	 MIOCoreRegisterClassByName('LocalizedName_ManagedObject', LocalizedName_ManagedObject);
	 MIOCoreRegisterClassByName('LocalizedName', LocalizedName);
	 MIOCoreRegisterClassByName('LocalizedValue_ManagedObject', LocalizedValue_ManagedObject);
	 MIOCoreRegisterClassByName('LocalizedValue', LocalizedValue);
	 MIOCoreRegisterClassByName('Location_ManagedObject', Location_ManagedObject);
	 MIOCoreRegisterClassByName('Location', Location);
	 MIOCoreRegisterClassByName('LocationCategory_ManagedObject', LocationCategory_ManagedObject);
	 MIOCoreRegisterClassByName('LocationCategory', LocationCategory);
	 MIOCoreRegisterClassByName('LocationCategoryAutoProduct_ManagedObject', LocationCategoryAutoProduct_ManagedObject);
	 MIOCoreRegisterClassByName('LocationCategoryAutoProduct', LocationCategoryAutoProduct);
	 MIOCoreRegisterClassByName('LocationGroupRule_ManagedObject', LocationGroupRule_ManagedObject);
	 MIOCoreRegisterClassByName('LocationGroupRule', LocationGroupRule);
	 MIOCoreRegisterClassByName('LoyaltyAccountLine_ManagedObject', LoyaltyAccountLine_ManagedObject);
	 MIOCoreRegisterClassByName('LoyaltyAccountLine', LoyaltyAccountLine);
	 MIOCoreRegisterClassByName('ManagerSection_ManagedObject', ManagerSection_ManagedObject);
	 MIOCoreRegisterClassByName('ManagerSection', ManagerSection);
	 MIOCoreRegisterClassByName('ManagerStockAnnotation_ManagedObject', ManagerStockAnnotation_ManagedObject);
	 MIOCoreRegisterClassByName('ManagerStockAnnotation', ManagerStockAnnotation);
	 MIOCoreRegisterClassByName('Margin_ManagedObject', Margin_ManagedObject);
	 MIOCoreRegisterClassByName('Margin', Margin);
	 MIOCoreRegisterClassByName('MarginProducts_ManagedObject', MarginProducts_ManagedObject);
	 MIOCoreRegisterClassByName('MarginProducts', MarginProducts);
	 MIOCoreRegisterClassByName('MemoLog_ManagedObject', MemoLog_ManagedObject);
	 MIOCoreRegisterClassByName('MemoLog', MemoLog);
	 MIOCoreRegisterClassByName('Menu_ManagedObject', Menu_ManagedObject);
	 MIOCoreRegisterClassByName('Menu', Menu);
	 MIOCoreRegisterClassByName('MenuCategory_ManagedObject', MenuCategory_ManagedObject);
	 MIOCoreRegisterClassByName('MenuCategory', MenuCategory);
	 MIOCoreRegisterClassByName('MenuLine_ManagedObject', MenuLine_ManagedObject);
	 MIOCoreRegisterClassByName('MenuLine', MenuLine);
	 MIOCoreRegisterClassByName('MenuProductLine_ManagedObject', MenuProductLine_ManagedObject);
	 MIOCoreRegisterClassByName('MenuProductLine', MenuProductLine);
	 MIOCoreRegisterClassByName('MenuRate_ManagedObject', MenuRate_ManagedObject);
	 MIOCoreRegisterClassByName('MenuRate', MenuRate);
	 MIOCoreRegisterClassByName('Migrate_ManagedObject', Migrate_ManagedObject);
	 MIOCoreRegisterClassByName('Migrate', Migrate);
	 MIOCoreRegisterClassByName('ModifierLine_ManagedObject', ModifierLine_ManagedObject);
	 MIOCoreRegisterClassByName('ModifierLine', ModifierLine);
	 MIOCoreRegisterClassByName('MovementNote_ManagedObject', MovementNote_ManagedObject);
	 MIOCoreRegisterClassByName('MovementNote', MovementNote);
	 MIOCoreRegisterClassByName('MovementNoteLine_ManagedObject', MovementNoteLine_ManagedObject);
	 MIOCoreRegisterClassByName('MovementNoteLine', MovementNoteLine);
	 MIOCoreRegisterClassByName('NumberSerialSequence_ManagedObject', NumberSerialSequence_ManagedObject);
	 MIOCoreRegisterClassByName('NumberSerialSequence', NumberSerialSequence);
	 MIOCoreRegisterClassByName('NumberSeries_ManagedObject', NumberSeries_ManagedObject);
	 MIOCoreRegisterClassByName('NumberSeries', NumberSeries);
	 MIOCoreRegisterClassByName('Offer_ManagedObject', Offer_ManagedObject);
	 MIOCoreRegisterClassByName('Offer', Offer);
	 MIOCoreRegisterClassByName('OfferProduct_ManagedObject', OfferProduct_ManagedObject);
	 MIOCoreRegisterClassByName('OfferProduct', OfferProduct);
	 MIOCoreRegisterClassByName('OnlineBookingPaymentTransaction_ManagedObject', OnlineBookingPaymentTransaction_ManagedObject);
	 MIOCoreRegisterClassByName('OnlineBookingPaymentTransaction', OnlineBookingPaymentTransaction);
	 MIOCoreRegisterClassByName('OnlineOrder_ManagedObject', OnlineOrder_ManagedObject);
	 MIOCoreRegisterClassByName('OnlineOrder', OnlineOrder);
	 MIOCoreRegisterClassByName('OnlineOrderLine_ManagedObject', OnlineOrderLine_ManagedObject);
	 MIOCoreRegisterClassByName('OnlineOrderLine', OnlineOrderLine);
	 MIOCoreRegisterClassByName('OnlineOrderPaymentTransaction_ManagedObject', OnlineOrderPaymentTransaction_ManagedObject);
	 MIOCoreRegisterClassByName('OnlineOrderPaymentTransaction', OnlineOrderPaymentTransaction);
	 MIOCoreRegisterClassByName('OnlinePlatform_ManagedObject', OnlinePlatform_ManagedObject);
	 MIOCoreRegisterClassByName('OnlinePlatform', OnlinePlatform);
	 MIOCoreRegisterClassByName('OnlinePreset_ManagedObject', OnlinePreset_ManagedObject);
	 MIOCoreRegisterClassByName('OnlinePreset', OnlinePreset);
	 MIOCoreRegisterClassByName('Order_ManagedObject', Order_ManagedObject);
	 MIOCoreRegisterClassByName('Order', Order);
	 MIOCoreRegisterClassByName('OrderAnswer_ManagedObject', OrderAnswer_ManagedObject);
	 MIOCoreRegisterClassByName('OrderAnswer', OrderAnswer);
	 MIOCoreRegisterClassByName('OrderPaymentTransaction_ManagedObject', OrderPaymentTransaction_ManagedObject);
	 MIOCoreRegisterClassByName('OrderPaymentTransaction', OrderPaymentTransaction);
	 MIOCoreRegisterClassByName('OrderQuestion_ManagedObject', OrderQuestion_ManagedObject);
	 MIOCoreRegisterClassByName('OrderQuestion', OrderQuestion);
	 MIOCoreRegisterClassByName('OrderQuestionOptions_ManagedObject', OrderQuestionOptions_ManagedObject);
	 MIOCoreRegisterClassByName('OrderQuestionOptions', OrderQuestionOptions);
	 MIOCoreRegisterClassByName('Party_ManagedObject', Party_ManagedObject);
	 MIOCoreRegisterClassByName('Party', Party);
	 MIOCoreRegisterClassByName('PartyEvent_ManagedObject', PartyEvent_ManagedObject);
	 MIOCoreRegisterClassByName('PartyEvent', PartyEvent);
	 MIOCoreRegisterClassByName('PartyImage_ManagedObject', PartyImage_ManagedObject);
	 MIOCoreRegisterClassByName('PartyImage', PartyImage);
	 MIOCoreRegisterClassByName('PaymentAction_ManagedObject', PaymentAction_ManagedObject);
	 MIOCoreRegisterClassByName('PaymentAction', PaymentAction);
	 MIOCoreRegisterClassByName('PaymentActionInterval_ManagedObject', PaymentActionInterval_ManagedObject);
	 MIOCoreRegisterClassByName('PaymentActionInterval', PaymentActionInterval);
	 MIOCoreRegisterClassByName('PaymentDevice_ManagedObject', PaymentDevice_ManagedObject);
	 MIOCoreRegisterClassByName('PaymentDevice', PaymentDevice);
	 MIOCoreRegisterClassByName('PaymentEntity_ManagedObject', PaymentEntity_ManagedObject);
	 MIOCoreRegisterClassByName('PaymentEntity', PaymentEntity);
	 MIOCoreRegisterClassByName('PaymentInstallment_ManagedObject', PaymentInstallment_ManagedObject);
	 MIOCoreRegisterClassByName('PaymentInstallment', PaymentInstallment);
	 MIOCoreRegisterClassByName('PaymentInstallmentTemplate_ManagedObject', PaymentInstallmentTemplate_ManagedObject);
	 MIOCoreRegisterClassByName('PaymentInstallmentTemplate', PaymentInstallmentTemplate);
	 MIOCoreRegisterClassByName('PaymentTransaction_ManagedObject', PaymentTransaction_ManagedObject);
	 MIOCoreRegisterClassByName('PaymentTransaction', PaymentTransaction);
	 MIOCoreRegisterClassByName('PayMethod_ManagedObject', PayMethod_ManagedObject);
	 MIOCoreRegisterClassByName('PayMethod', PayMethod);
	 MIOCoreRegisterClassByName('PayMethodConfig_ManagedObject', PayMethodConfig_ManagedObject);
	 MIOCoreRegisterClassByName('PayMethodConfig', PayMethodConfig);
	 MIOCoreRegisterClassByName('PhoneNumber_ManagedObject', PhoneNumber_ManagedObject);
	 MIOCoreRegisterClassByName('PhoneNumber', PhoneNumber);
	 MIOCoreRegisterClassByName('Place_ManagedObject', Place_ManagedObject);
	 MIOCoreRegisterClassByName('Place', Place);
	 MIOCoreRegisterClassByName('PlaceConfiguration_ManagedObject', PlaceConfiguration_ManagedObject);
	 MIOCoreRegisterClassByName('PlaceConfiguration', PlaceConfiguration);
	 MIOCoreRegisterClassByName('PosClientDevice_ManagedObject', PosClientDevice_ManagedObject);
	 MIOCoreRegisterClassByName('PosClientDevice', PosClientDevice);
	 MIOCoreRegisterClassByName('Preset_ManagedObject', Preset_ManagedObject);
	 MIOCoreRegisterClassByName('Preset', Preset);
	 MIOCoreRegisterClassByName('PresetBlank_ManagedObject', PresetBlank_ManagedObject);
	 MIOCoreRegisterClassByName('PresetBlank', PresetBlank);
	 MIOCoreRegisterClassByName('PresetCategory_ManagedObject', PresetCategory_ManagedObject);
	 MIOCoreRegisterClassByName('PresetCategory', PresetCategory);
	 MIOCoreRegisterClassByName('PresetGroup_ManagedObject', PresetGroup_ManagedObject);
	 MIOCoreRegisterClassByName('PresetGroup', PresetGroup);
	 MIOCoreRegisterClassByName('PresetItem_ManagedObject', PresetItem_ManagedObject);
	 MIOCoreRegisterClassByName('PresetItem', PresetItem);
	 MIOCoreRegisterClassByName('PresetMenu_ManagedObject', PresetMenu_ManagedObject);
	 MIOCoreRegisterClassByName('PresetMenu', PresetMenu);
	 MIOCoreRegisterClassByName('PresetProduct_ManagedObject', PresetProduct_ManagedObject);
	 MIOCoreRegisterClassByName('PresetProduct', PresetProduct);
	 MIOCoreRegisterClassByName('PresetProductModifier_ManagedObject', PresetProductModifier_ManagedObject);
	 MIOCoreRegisterClassByName('PresetProductModifier', PresetProductModifier);
	 MIOCoreRegisterClassByName('PresetRelation_ManagedObject', PresetRelation_ManagedObject);
	 MIOCoreRegisterClassByName('PresetRelation', PresetRelation);
	 MIOCoreRegisterClassByName('PriceGroup_ManagedObject', PriceGroup_ManagedObject);
	 MIOCoreRegisterClassByName('PriceGroup', PriceGroup);
	 MIOCoreRegisterClassByName('PriceGroupConfiguration_ManagedObject', PriceGroupConfiguration_ManagedObject);
	 MIOCoreRegisterClassByName('PriceGroupConfiguration', PriceGroupConfiguration);
	 MIOCoreRegisterClassByName('Printer_ManagedObject', Printer_ManagedObject);
	 MIOCoreRegisterClassByName('Printer', Printer);
	 MIOCoreRegisterClassByName('PrinterLink_ManagedObject', PrinterLink_ManagedObject);
	 MIOCoreRegisterClassByName('PrinterLink', PrinterLink);
	 MIOCoreRegisterClassByName('PrintProfile_ManagedObject', PrintProfile_ManagedObject);
	 MIOCoreRegisterClassByName('PrintProfile', PrintProfile);
	 MIOCoreRegisterClassByName('PrintProfileConfiguration_ManagedObject', PrintProfileConfiguration_ManagedObject);
	 MIOCoreRegisterClassByName('PrintProfileConfiguration', PrintProfileConfiguration);
	 MIOCoreRegisterClassByName('Product_ManagedObject', Product_ManagedObject);
	 MIOCoreRegisterClassByName('Product', Product);
	 MIOCoreRegisterClassByName('ProductAttribute_ManagedObject', ProductAttribute_ManagedObject);
	 MIOCoreRegisterClassByName('ProductAttribute', ProductAttribute);
	 MIOCoreRegisterClassByName('ProductAttributeType_ManagedObject', ProductAttributeType_ManagedObject);
	 MIOCoreRegisterClassByName('ProductAttributeType', ProductAttributeType);
	 MIOCoreRegisterClassByName('ProductCategory_ManagedObject', ProductCategory_ManagedObject);
	 MIOCoreRegisterClassByName('ProductCategory', ProductCategory);
	 MIOCoreRegisterClassByName('ProductCombination_ManagedObject', ProductCombination_ManagedObject);
	 MIOCoreRegisterClassByName('ProductCombination', ProductCombination);
	 MIOCoreRegisterClassByName('ProductCostBalance_ManagedObject', ProductCostBalance_ManagedObject);
	 MIOCoreRegisterClassByName('ProductCostBalance', ProductCostBalance);
	 MIOCoreRegisterClassByName('ProductCurrentStock_ManagedObject', ProductCurrentStock_ManagedObject);
	 MIOCoreRegisterClassByName('ProductCurrentStock', ProductCurrentStock);
	 MIOCoreRegisterClassByName('ProductFormat_ManagedObject', ProductFormat_ManagedObject);
	 MIOCoreRegisterClassByName('ProductFormat', ProductFormat);
	 MIOCoreRegisterClassByName('ProductFormatRate_ManagedObject', ProductFormatRate_ManagedObject);
	 MIOCoreRegisterClassByName('ProductFormatRate', ProductFormatRate);
	 MIOCoreRegisterClassByName('ProductImage_ManagedObject', ProductImage_ManagedObject);
	 MIOCoreRegisterClassByName('ProductImage', ProductImage);
	 MIOCoreRegisterClassByName('ProductInventory_ManagedObject', ProductInventory_ManagedObject);
	 MIOCoreRegisterClassByName('ProductInventory', ProductInventory);
	 MIOCoreRegisterClassByName('ProductLine_ManagedObject', ProductLine_ManagedObject);
	 MIOCoreRegisterClassByName('ProductLine', ProductLine);
	 MIOCoreRegisterClassByName('ProductMenu_ManagedObject', ProductMenu_ManagedObject);
	 MIOCoreRegisterClassByName('ProductMenu', ProductMenu);
	 MIOCoreRegisterClassByName('ProductModifier_ManagedObject', ProductModifier_ManagedObject);
	 MIOCoreRegisterClassByName('ProductModifier', ProductModifier);
	 MIOCoreRegisterClassByName('ProductModifierCategory_ManagedObject', ProductModifierCategory_ManagedObject);
	 MIOCoreRegisterClassByName('ProductModifierCategory', ProductModifierCategory);
	 MIOCoreRegisterClassByName('ProductModifierRate_ManagedObject', ProductModifierRate_ManagedObject);
	 MIOCoreRegisterClassByName('ProductModifierRate', ProductModifierRate);
	 MIOCoreRegisterClassByName('ProductPlace_ManagedObject', ProductPlace_ManagedObject);
	 MIOCoreRegisterClassByName('ProductPlace', ProductPlace);
	 MIOCoreRegisterClassByName('ProductRate_ManagedObject', ProductRate_ManagedObject);
	 MIOCoreRegisterClassByName('ProductRate', ProductRate);
	 MIOCoreRegisterClassByName('ProductSource_ManagedObject', ProductSource_ManagedObject);
	 MIOCoreRegisterClassByName('ProductSource', ProductSource);
	 MIOCoreRegisterClassByName('ProductWarehouse_ManagedObject', ProductWarehouse_ManagedObject);
	 MIOCoreRegisterClassByName('ProductWarehouse', ProductWarehouse);
	 MIOCoreRegisterClassByName('ProductWarehouseLocation_ManagedObject', ProductWarehouseLocation_ManagedObject);
	 MIOCoreRegisterClassByName('ProductWarehouseLocation', ProductWarehouseLocation);
	 MIOCoreRegisterClassByName('ProfileSummary_ManagedObject', ProfileSummary_ManagedObject);
	 MIOCoreRegisterClassByName('ProfileSummary', ProfileSummary);
	 MIOCoreRegisterClassByName('ProfileSummarySource_ManagedObject', ProfileSummarySource_ManagedObject);
	 MIOCoreRegisterClassByName('ProfileSummarySource', ProfileSummarySource);
	 MIOCoreRegisterClassByName('PromotionalCode_ManagedObject', PromotionalCode_ManagedObject);
	 MIOCoreRegisterClassByName('PromotionalCode', PromotionalCode);
	 MIOCoreRegisterClassByName('PromotionalCodeLine_ManagedObject', PromotionalCodeLine_ManagedObject);
	 MIOCoreRegisterClassByName('PromotionalCodeLine', PromotionalCodeLine);
	 MIOCoreRegisterClassByName('ProxyPaymentEntity_ManagedObject', ProxyPaymentEntity_ManagedObject);
	 MIOCoreRegisterClassByName('ProxyPaymentEntity', ProxyPaymentEntity);
	 MIOCoreRegisterClassByName('ProxyPaymentEntityPlatform_ManagedObject', ProxyPaymentEntityPlatform_ManagedObject);
	 MIOCoreRegisterClassByName('ProxyPaymentEntityPlatform', ProxyPaymentEntityPlatform);
	 MIOCoreRegisterClassByName('ProxyPaymentTransaction_ManagedObject', ProxyPaymentTransaction_ManagedObject);
	 MIOCoreRegisterClassByName('ProxyPaymentTransaction', ProxyPaymentTransaction);
	 MIOCoreRegisterClassByName('PurchaseInvoice_ManagedObject', PurchaseInvoice_ManagedObject);
	 MIOCoreRegisterClassByName('PurchaseInvoice', PurchaseInvoice);
	 MIOCoreRegisterClassByName('PurchaseInvoiceLine_ManagedObject', PurchaseInvoiceLine_ManagedObject);
	 MIOCoreRegisterClassByName('PurchaseInvoiceLine', PurchaseInvoiceLine);
	 MIOCoreRegisterClassByName('Rate_ManagedObject', Rate_ManagedObject);
	 MIOCoreRegisterClassByName('Rate', Rate);
	 MIOCoreRegisterClassByName('RateInterval_ManagedObject', RateInterval_ManagedObject);
	 MIOCoreRegisterClassByName('RateInterval', RateInterval);
	 MIOCoreRegisterClassByName('ReconliceTransaction_ManagedObject', ReconliceTransaction_ManagedObject);
	 MIOCoreRegisterClassByName('ReconliceTransaction', ReconliceTransaction);
	 MIOCoreRegisterClassByName('RenderTemplate_ManagedObject', RenderTemplate_ManagedObject);
	 MIOCoreRegisterClassByName('RenderTemplate', RenderTemplate);
	 MIOCoreRegisterClassByName('ReplicaConfig_ManagedObject', ReplicaConfig_ManagedObject);
	 MIOCoreRegisterClassByName('ReplicaConfig', ReplicaConfig);
	 MIOCoreRegisterClassByName('Report_ManagedObject', Report_ManagedObject);
	 MIOCoreRegisterClassByName('Report', Report);
	 MIOCoreRegisterClassByName('ReportSettingsMenu_ManagedObject', ReportSettingsMenu_ManagedObject);
	 MIOCoreRegisterClassByName('ReportSettingsMenu', ReportSettingsMenu);
	 MIOCoreRegisterClassByName('Reseller_ManagedObject', Reseller_ManagedObject);
	 MIOCoreRegisterClassByName('Reseller', Reseller);
	 MIOCoreRegisterClassByName('Room_ManagedObject', Room_ManagedObject);
	 MIOCoreRegisterClassByName('Room', Room);
	 MIOCoreRegisterClassByName('RoomBooking_ManagedObject', RoomBooking_ManagedObject);
	 MIOCoreRegisterClassByName('RoomBooking', RoomBooking);
	 MIOCoreRegisterClassByName('RoomBookingGroup_ManagedObject', RoomBookingGroup_ManagedObject);
	 MIOCoreRegisterClassByName('RoomBookingGroup', RoomBookingGroup);
	 MIOCoreRegisterClassByName('RoomGuest_ManagedObject', RoomGuest_ManagedObject);
	 MIOCoreRegisterClassByName('RoomGuest', RoomGuest);
	 MIOCoreRegisterClassByName('RoomZone_ManagedObject', RoomZone_ManagedObject);
	 MIOCoreRegisterClassByName('RoomZone', RoomZone);
	 MIOCoreRegisterClassByName('Rule_ManagedObject', Rule_ManagedObject);
	 MIOCoreRegisterClassByName('Rule', Rule);
	 MIOCoreRegisterClassByName('RuleKit_ManagedObject', RuleKit_ManagedObject);
	 MIOCoreRegisterClassByName('RuleKit', RuleKit);
	 MIOCoreRegisterClassByName('RuleResult_ManagedObject', RuleResult_ManagedObject);
	 MIOCoreRegisterClassByName('RuleResult', RuleResult);
	 MIOCoreRegisterClassByName('RuleResultOnDate_ManagedObject', RuleResultOnDate_ManagedObject);
	 MIOCoreRegisterClassByName('RuleResultOnDate', RuleResultOnDate);
	 MIOCoreRegisterClassByName('RuleSet_ManagedObject', RuleSet_ManagedObject);
	 MIOCoreRegisterClassByName('RuleSet', RuleSet);
	 MIOCoreRegisterClassByName('RuleSetGuard_ManagedObject', RuleSetGuard_ManagedObject);
	 MIOCoreRegisterClassByName('RuleSetGuard', RuleSetGuard);
	 MIOCoreRegisterClassByName('SalaryReconcileTransaction_ManagedObject', SalaryReconcileTransaction_ManagedObject);
	 MIOCoreRegisterClassByName('SalaryReconcileTransaction', SalaryReconcileTransaction);
	 MIOCoreRegisterClassByName('SalesAnnotation_ManagedObject', SalesAnnotation_ManagedObject);
	 MIOCoreRegisterClassByName('SalesAnnotation', SalesAnnotation);
	 MIOCoreRegisterClassByName('SalesComponentAnnotation_ManagedObject', SalesComponentAnnotation_ManagedObject);
	 MIOCoreRegisterClassByName('SalesComponentAnnotation', SalesComponentAnnotation);
	 MIOCoreRegisterClassByName('SalesInvoice_ManagedObject', SalesInvoice_ManagedObject);
	 MIOCoreRegisterClassByName('SalesInvoice', SalesInvoice);
	 MIOCoreRegisterClassByName('SalesInvoiceLine_ManagedObject', SalesInvoiceLine_ManagedObject);
	 MIOCoreRegisterClassByName('SalesInvoiceLine', SalesInvoiceLine);
	 MIOCoreRegisterClassByName('SalesInvoiceTemplate_ManagedObject', SalesInvoiceTemplate_ManagedObject);
	 MIOCoreRegisterClassByName('SalesInvoiceTemplate', SalesInvoiceTemplate);
	 MIOCoreRegisterClassByName('SalesMenuAnnotation_ManagedObject', SalesMenuAnnotation_ManagedObject);
	 MIOCoreRegisterClassByName('SalesMenuAnnotation', SalesMenuAnnotation);
	 MIOCoreRegisterClassByName('SalesModifierAnnotation_ManagedObject', SalesModifierAnnotation_ManagedObject);
	 MIOCoreRegisterClassByName('SalesModifierAnnotation', SalesModifierAnnotation);
	 MIOCoreRegisterClassByName('SaleSource_ManagedObject', SaleSource_ManagedObject);
	 MIOCoreRegisterClassByName('SaleSource', SaleSource);
	 MIOCoreRegisterClassByName('SeparatedMenuLine_ManagedObject', SeparatedMenuLine_ManagedObject);
	 MIOCoreRegisterClassByName('SeparatedMenuLine', SeparatedMenuLine);
	 MIOCoreRegisterClassByName('ServerStatus_ManagedObject', ServerStatus_ManagedObject);
	 MIOCoreRegisterClassByName('ServerStatus', ServerStatus);
	 MIOCoreRegisterClassByName('Service_ManagedObject', Service_ManagedObject);
	 MIOCoreRegisterClassByName('Service', Service);
	 MIOCoreRegisterClassByName('ServiceCategory_ManagedObject', ServiceCategory_ManagedObject);
	 MIOCoreRegisterClassByName('ServiceCategory', ServiceCategory);
	 MIOCoreRegisterClassByName('SMSTemplate_ManagedObject', SMSTemplate_ManagedObject);
	 MIOCoreRegisterClassByName('SMSTemplate', SMSTemplate);
	 MIOCoreRegisterClassByName('SourceMessage_ManagedObject', SourceMessage_ManagedObject);
	 MIOCoreRegisterClassByName('SourceMessage', SourceMessage);
	 MIOCoreRegisterClassByName('SourcePrinterLink_ManagedObject', SourcePrinterLink_ManagedObject);
	 MIOCoreRegisterClassByName('SourcePrinterLink', SourcePrinterLink);
	 MIOCoreRegisterClassByName('StockAnnotation_ManagedObject', StockAnnotation_ManagedObject);
	 MIOCoreRegisterClassByName('StockAnnotation', StockAnnotation);
	 MIOCoreRegisterClassByName('StockAnnotationSummary_ManagedObject', StockAnnotationSummary_ManagedObject);
	 MIOCoreRegisterClassByName('StockAnnotationSummary', StockAnnotationSummary);
	 MIOCoreRegisterClassByName('StockCategory_ManagedObject', StockCategory_ManagedObject);
	 MIOCoreRegisterClassByName('StockCategory', StockCategory);
	 MIOCoreRegisterClassByName('StockConsumptionAnnotation_ManagedObject', StockConsumptionAnnotation_ManagedObject);
	 MIOCoreRegisterClassByName('StockConsumptionAnnotation', StockConsumptionAnnotation);
	 MIOCoreRegisterClassByName('StockCoreAnnotation_ManagedObject', StockCoreAnnotation_ManagedObject);
	 MIOCoreRegisterClassByName('StockCoreAnnotation', StockCoreAnnotation);
	 MIOCoreRegisterClassByName('StockCostAnnotation_ManagedObject', StockCostAnnotation_ManagedObject);
	 MIOCoreRegisterClassByName('StockCostAnnotation', StockCostAnnotation);
	 MIOCoreRegisterClassByName('StockCustomConcept_ManagedObject', StockCustomConcept_ManagedObject);
	 MIOCoreRegisterClassByName('StockCustomConcept', StockCustomConcept);
	 MIOCoreRegisterClassByName('StockInputFormat_ManagedObject', StockInputFormat_ManagedObject);
	 MIOCoreRegisterClassByName('StockInputFormat', StockInputFormat);
	 MIOCoreRegisterClassByName('StockNote_ManagedObject', StockNote_ManagedObject);
	 MIOCoreRegisterClassByName('StockNote', StockNote);
	 MIOCoreRegisterClassByName('StockNoteLine_ManagedObject', StockNoteLine_ManagedObject);
	 MIOCoreRegisterClassByName('StockNoteLine', StockNoteLine);
	 MIOCoreRegisterClassByName('StockTemplate_ManagedObject', StockTemplate_ManagedObject);
	 MIOCoreRegisterClassByName('StockTemplate', StockTemplate);
	 MIOCoreRegisterClassByName('StockTemplateCategoryItem_ManagedObject', StockTemplateCategoryItem_ManagedObject);
	 MIOCoreRegisterClassByName('StockTemplateCategoryItem', StockTemplateCategoryItem);
	 MIOCoreRegisterClassByName('StockTemplateItem_ManagedObject', StockTemplateItem_ManagedObject);
	 MIOCoreRegisterClassByName('StockTemplateItem', StockTemplateItem);
	 MIOCoreRegisterClassByName('StockTemplateProductItem_ManagedObject', StockTemplateProductItem_ManagedObject);
	 MIOCoreRegisterClassByName('StockTemplateProductItem', StockTemplateProductItem);
	 MIOCoreRegisterClassByName('StockTemplateStockCategoryItem_ManagedObject', StockTemplateStockCategoryItem_ManagedObject);
	 MIOCoreRegisterClassByName('StockTemplateStockCategoryItem', StockTemplateStockCategoryItem);
	 MIOCoreRegisterClassByName('SubCashDesk_ManagedObject', SubCashDesk_ManagedObject);
	 MIOCoreRegisterClassByName('SubCashDesk', SubCashDesk);
	 MIOCoreRegisterClassByName('SubTax_ManagedObject', SubTax_ManagedObject);
	 MIOCoreRegisterClassByName('SubTax', SubTax);
	 MIOCoreRegisterClassByName('Supplier_ManagedObject', Supplier_ManagedObject);
	 MIOCoreRegisterClassByName('Supplier', Supplier);
	 MIOCoreRegisterClassByName('SupplierNote_ManagedObject', SupplierNote_ManagedObject);
	 MIOCoreRegisterClassByName('SupplierNote', SupplierNote);
	 MIOCoreRegisterClassByName('SupplierNoteLine_ManagedObject', SupplierNoteLine_ManagedObject);
	 MIOCoreRegisterClassByName('SupplierNoteLine', SupplierNoteLine);
	 MIOCoreRegisterClassByName('SupplierOrder_ManagedObject', SupplierOrder_ManagedObject);
	 MIOCoreRegisterClassByName('SupplierOrder', SupplierOrder);
	 MIOCoreRegisterClassByName('SupplierOrderLine_ManagedObject', SupplierOrderLine_ManagedObject);
	 MIOCoreRegisterClassByName('SupplierOrderLine', SupplierOrderLine);
	 MIOCoreRegisterClassByName('SupplierPaymentInstallment_ManagedObject', SupplierPaymentInstallment_ManagedObject);
	 MIOCoreRegisterClassByName('SupplierPaymentInstallment', SupplierPaymentInstallment);
	 MIOCoreRegisterClassByName('SupplierProduct_ManagedObject', SupplierProduct_ManagedObject);
	 MIOCoreRegisterClassByName('SupplierProduct', SupplierProduct);
	 MIOCoreRegisterClassByName('SupportChatMessage_ManagedObject', SupportChatMessage_ManagedObject);
	 MIOCoreRegisterClassByName('SupportChatMessage', SupportChatMessage);
	 MIOCoreRegisterClassByName('SupportSession_ManagedObject', SupportSession_ManagedObject);
	 MIOCoreRegisterClassByName('SupportSession', SupportSession);
	 MIOCoreRegisterClassByName('SupportStream_ManagedObject', SupportStream_ManagedObject);
	 MIOCoreRegisterClassByName('SupportStream', SupportStream);
	 MIOCoreRegisterClassByName('SupportTicket_ManagedObject', SupportTicket_ManagedObject);
	 MIOCoreRegisterClassByName('SupportTicket', SupportTicket);
	 MIOCoreRegisterClassByName('SupportTicketLog_ManagedObject', SupportTicketLog_ManagedObject);
	 MIOCoreRegisterClassByName('SupportTicketLog', SupportTicketLog);
	 MIOCoreRegisterClassByName('SyncLog_ManagedObject', SyncLog_ManagedObject);
	 MIOCoreRegisterClassByName('SyncLog', SyncLog);
	 MIOCoreRegisterClassByName('Tab_ManagedObject', Tab_ManagedObject);
	 MIOCoreRegisterClassByName('Tab', Tab);
	 MIOCoreRegisterClassByName('Tag_ManagedObject', Tag_ManagedObject);
	 MIOCoreRegisterClassByName('Tag', Tag);
	 MIOCoreRegisterClassByName('TakeAwayTimeRangePresetConfiguration_ManagedObject', TakeAwayTimeRangePresetConfiguration_ManagedObject);
	 MIOCoreRegisterClassByName('TakeAwayTimeRangePresetConfiguration', TakeAwayTimeRangePresetConfiguration);
	 MIOCoreRegisterClassByName('Tax_ManagedObject', Tax_ManagedObject);
	 MIOCoreRegisterClassByName('Tax', Tax);
	 MIOCoreRegisterClassByName('TaxAnnotation_ManagedObject', TaxAnnotation_ManagedObject);
	 MIOCoreRegisterClassByName('TaxAnnotation', TaxAnnotation);
	 MIOCoreRegisterClassByName('TaxMenuAnnotation_ManagedObject', TaxMenuAnnotation_ManagedObject);
	 MIOCoreRegisterClassByName('TaxMenuAnnotation', TaxMenuAnnotation);
	 MIOCoreRegisterClassByName('TaxModifierAnnotation_ManagedObject', TaxModifierAnnotation_ManagedObject);
	 MIOCoreRegisterClassByName('TaxModifierAnnotation', TaxModifierAnnotation);
	 MIOCoreRegisterClassByName('TCPPrinter_ManagedObject', TCPPrinter_ManagedObject);
	 MIOCoreRegisterClassByName('TCPPrinter', TCPPrinter);
	 MIOCoreRegisterClassByName('Ticket_ManagedObject', Ticket_ManagedObject);
	 MIOCoreRegisterClassByName('Ticket', Ticket);
	 MIOCoreRegisterClassByName('TicketChannel_ManagedObject', TicketChannel_ManagedObject);
	 MIOCoreRegisterClassByName('TicketChannel', TicketChannel);
	 MIOCoreRegisterClassByName('TicketLine_ManagedObject', TicketLine_ManagedObject);
	 MIOCoreRegisterClassByName('TicketLine', TicketLine);
	 MIOCoreRegisterClassByName('TicketPrinterLink_ManagedObject', TicketPrinterLink_ManagedObject);
	 MIOCoreRegisterClassByName('TicketPrinterLink', TicketPrinterLink);
	 MIOCoreRegisterClassByName('TicketPromotionalCodeTransactions_ManagedObject', TicketPromotionalCodeTransactions_ManagedObject);
	 MIOCoreRegisterClassByName('TicketPromotionalCodeTransactions', TicketPromotionalCodeTransactions);
	 MIOCoreRegisterClassByName('TimeRange_ManagedObject', TimeRange_ManagedObject);
	 MIOCoreRegisterClassByName('TimeRange', TimeRange);
	 MIOCoreRegisterClassByName('TimeRangeGroup_ManagedObject', TimeRangeGroup_ManagedObject);
	 MIOCoreRegisterClassByName('TimeRangeGroup', TimeRangeGroup);
	 MIOCoreRegisterClassByName('TimeRangePreset_ManagedObject', TimeRangePreset_ManagedObject);
	 MIOCoreRegisterClassByName('TimeRangePreset', TimeRangePreset);
	 MIOCoreRegisterClassByName('TimeRangePresetConfiguration_ManagedObject', TimeRangePresetConfiguration_ManagedObject);
	 MIOCoreRegisterClassByName('TimeRangePresetConfiguration', TimeRangePresetConfiguration);
	 MIOCoreRegisterClassByName('TimetableSession_ManagedObject', TimetableSession_ManagedObject);
	 MIOCoreRegisterClassByName('TimetableSession', TimetableSession);
	 MIOCoreRegisterClassByName('Tip_ManagedObject', Tip_ManagedObject);
	 MIOCoreRegisterClassByName('Tip', Tip);
	 MIOCoreRegisterClassByName('TipConfiguration_ManagedObject', TipConfiguration_ManagedObject);
	 MIOCoreRegisterClassByName('TipConfiguration', TipConfiguration);
	 MIOCoreRegisterClassByName('User_ManagedObject', User_ManagedObject);
	 MIOCoreRegisterClassByName('User', User);
	 MIOCoreRegisterClassByName('UserReportAccess_ManagedObject', UserReportAccess_ManagedObject);
	 MIOCoreRegisterClassByName('UserReportAccess', UserReportAccess);
	 MIOCoreRegisterClassByName('UserReportOption_ManagedObject', UserReportOption_ManagedObject);
	 MIOCoreRegisterClassByName('UserReportOption', UserReportOption);
	 MIOCoreRegisterClassByName('VenueEvent_ManagedObject', VenueEvent_ManagedObject);
	 MIOCoreRegisterClassByName('VenueEvent', VenueEvent);
	 MIOCoreRegisterClassByName('Warehouse_ManagedObject', Warehouse_ManagedObject);
	 MIOCoreRegisterClassByName('Warehouse', Warehouse);
	 MIOCoreRegisterClassByName('WarehouseDeliveryNote_ManagedObject', WarehouseDeliveryNote_ManagedObject);
	 MIOCoreRegisterClassByName('WarehouseDeliveryNote', WarehouseDeliveryNote);
	 MIOCoreRegisterClassByName('WarehouseDeliveryNoteLine_ManagedObject', WarehouseDeliveryNoteLine_ManagedObject);
	 MIOCoreRegisterClassByName('WarehouseDeliveryNoteLine', WarehouseDeliveryNoteLine);
	 MIOCoreRegisterClassByName('WarehouseInputNote_ManagedObject', WarehouseInputNote_ManagedObject);
	 MIOCoreRegisterClassByName('WarehouseInputNote', WarehouseInputNote);
	 MIOCoreRegisterClassByName('WarehouseInputNoteLine_ManagedObject', WarehouseInputNoteLine_ManagedObject);
	 MIOCoreRegisterClassByName('WarehouseInputNoteLine', WarehouseInputNoteLine);
	 MIOCoreRegisterClassByName('WarehouseLocation_ManagedObject', WarehouseLocation_ManagedObject);
	 MIOCoreRegisterClassByName('WarehouseLocation', WarehouseLocation);
	 MIOCoreRegisterClassByName('WarehouseOrder_ManagedObject', WarehouseOrder_ManagedObject);
	 MIOCoreRegisterClassByName('WarehouseOrder', WarehouseOrder);
	 MIOCoreRegisterClassByName('WarehouseOrderLine_ManagedObject', WarehouseOrderLine_ManagedObject);
	 MIOCoreRegisterClassByName('WarehouseOrderLine', WarehouseOrderLine);
	 MIOCoreRegisterClassByName('WarehousePrinterLink_ManagedObject', WarehousePrinterLink_ManagedObject);
	 MIOCoreRegisterClassByName('WarehousePrinterLink', WarehousePrinterLink);
	 MIOCoreRegisterClassByName('WhatsappTemplate_ManagedObject', WhatsappTemplate_ManagedObject);
	 MIOCoreRegisterClassByName('WhatsappTemplate', WhatsappTemplate);
	 MIOCoreRegisterClassByName('WineCellar_ManagedObject', WineCellar_ManagedObject);
	 MIOCoreRegisterClassByName('WineCellar', WineCellar);
	 MIOCoreRegisterClassByName('WorkerHoliday_ManagedObject', WorkerHoliday_ManagedObject);
	 MIOCoreRegisterClassByName('WorkerHoliday', WorkerHoliday);
	 MIOCoreRegisterClassByName('WorkerLocation_ManagedObject', WorkerLocation_ManagedObject);
	 MIOCoreRegisterClassByName('WorkerLocation', WorkerLocation);
	 MIOCoreRegisterClassByName('WorkerRole_ManagedObject', WorkerRole_ManagedObject);
	 MIOCoreRegisterClassByName('WorkerRole', WorkerRole);
	 MIOCoreRegisterClassByName('WorkSession_ManagedObject', WorkSession_ManagedObject);
	 MIOCoreRegisterClassByName('WorkSession', WorkSession);
	 MIOCoreRegisterClassByName('ZonePrintProfileConfiguration_ManagedObject', ZonePrintProfileConfiguration_ManagedObject);
	 MIOCoreRegisterClassByName('ZonePrintProfileConfiguration', ZonePrintProfileConfiguration);
}
