class AccountingEntryViewController extends MUIViewController implements AccountingEntryCellDelegate
{
    
    static newInstance() : AccountingEntryViewController {
        let vc = new AccountingEntryViewController("accounting-entity-view");
        vc.initWithResource("layout/accounting_entries/AccountingEntryView.html");
        return vc;
    }
    
    private lockButton:MUIButton = null;
    private unlockButton:MUIButton = null;

    private entryNumber:MUILabel = null;
    private balanceLabel:MUILabel = null;

    private tableView:UITableView = null;

    viewDidLoad(){
        super.viewDidLoad();

        this.lockButton = MUIOutlet(this, "lock-btn", "MUIButton");
        this.lockButton.setAction(this, this.lockEntries);

        this.unlockButton = MUIOutlet(this, "unlock-btn", "MUIButton");
        this.unlockButton.setAction(this, this.lockEntries);

        this.entryNumber = MUIOutlet(this, "entry-num-lbl", "MUILabel");
        this.balanceLabel = MUIOutlet(this, "balance-lbl", "MUILabel");

        this.tableView = MUIOutlet(this, "table-view", "UITableView");
        this.tableView.dataSource = this;
        this.tableView.delegate = this;
    }

    viewWillAppear(animated?: boolean): void {
        super.viewWillAppear(animated);
        this.updateUI();
    }

    private _journal_entry:AccountingJournalEntry = null;
    private _remainig_amount:number = 0;
    private _item:CoreAccounting = null;
    set item(value:CoreAccounting)
    {
        this._item = value;
        
        this._journal_entry = this._item.journalEntry;
        this._remainig_amount = 0;
        
        this.updateUI();        
    }

    private updateUI(){
        if (this._item == null || this.viewIsLoaded == false) return;

        this.entryNumber.text = "-";
        this.balanceLabel.text = "-";

        this.lockButton.hidden = this._item.journalEntryLocked;
        this.unlockButton.hidden = !this._item.journalEntryLocked;
        
        if (this._journal_entry?.journalIndex != null) this.entryNumber.text = this._journal_entry.journalIndex.toString();
        // this.balanceLabel.text = entry.journalEntry.balance.toString();                    

        this.fetchedResultsController = null;
        // this.calulateRemainigAmount();
        this.tableView.reloadData();
    }

    numberOfSections(tableView:UITableView)
    {
        let sections = this.fetchedResultsController.sections.count;
        if (!this._item.journalEntryLocked) sections++;
        return sections;
    }
    
    numberOfRowsInSection(tableView:UITableView, section:number)
    {
        if (section == this.fetchedResultsController.sections.count) return 1;
        
        let sec = this.fetchedResultsController.sections[section];
        return sec.numberOfObjects();
    }

    cellAtIndexPath(tableView:UITableView, indexPath:MIOIndexPath) 
    {
        let cell:AccountingEntryCell = null;
        
        if (indexPath.section == this.fetchedResultsController.sections.count) 
        {
            cell = tableView.dequeueReusableCellWithIdentifier( "AddAccountingEntryCell" ) as AccountingEntryCell;
            cell.delegate = this;
            if (this.fetchedResultsController.fetchedObjects.length == 0) {
                let acc = this._item instanceof BankMovement ? this._item.bankAccount.ledgerAccount : null;
                let amount = this._item instanceof BankMovement ? this._item.amount : 0;
                cell.updateValues( amount, acc );
            }
            else {
                cell.amount = this._remainig_amount;
            }

        }
        else {            
            if (this._item.journalEntryLocked == false) {
                cell = tableView.dequeueReusableCellWithIdentifier( "EditAccountingEntryCell" ) as AccountingEntryCell;
                cell.delegate = this;
            }
            else {
                cell = tableView.dequeueReusableCellWithIdentifier( "InfoAccountingEntryCell" ) as AccountingEntryCell;
            }
            let item = this.fetchedResultsController.objectAtIndexPath(indexPath) as AccountingEntry;            
            cell.item = item;

        }

        return cell;
    }

    editingStyleForRowAtIndexPath(tableView:UITableView, indexPath:MIOIndexPath) {   
        if (indexPath.section == this.fetchedResultsController.sections.count) return UITableViewCellEditingStyle.Insert;
        return UITableViewCellEditingStyle.Delete;
    }

    commitEditingStyleForRowAtIndexPath(tableView:UITableView, editingStyle:UITableViewCellEditingStyle, indexPath:MIOIndexPath) {

        if (editingStyle == MUITableViewCellEditingStyle.Delete) {
            let item = this.fetchedResultsController.objectAtIndexPath(indexPath);
            this.deleteEntry(item);

        }
        else if (editingStyle == MUITableViewCellEditingStyle.Insert) {
            let cell = tableView.cellAtIndexPath(indexPath) as AccountingEntryCell;
            this.addEntryDidPress(cell);
        }
    } 

    set fetchedResultsController(value){
        if (value == null && this._fetchedResultsController != null)
            this._fetchedResultsController.delegate = null;
    
        this._fetchedResultsController = value;
    }

    get fetchedResultsController()
    {
        if (this._item == null) return null;
        if (this._fetchedResultsController != null) return this._fetchedResultsController;            
        
        let ad = MUIWebApplication.sharedInstance().delegate;

        let sortDescriptors = [
            MIOSortDescriptor.sortDescriptorWithKey('createdAt', false)
        ];
        
        let predicateFormat = "relationEntityID == " + this._item.identifier + " AND relationEntityType == '" + this._item.entity.name + "'";
        let fetchRequest = DBHelper.listFetchRequestWithEntityName( "AccountingEntry", sortDescriptors, predicateFormat );
        fetchRequest.relationshipKeyPathsForPrefetching = ["account", "journalEntry"];
        
        let fetchedResultsController = new MIOFetchedResultsController();
        fetchedResultsController.initWithFetchRequest(fetchRequest, ad.managedObjectContext, null);
        fetchedResultsController.delegate = this;
    
        fetchedResultsController.performFetch();
    
        this._fetchedResultsController = fetchedResultsController;    
        return this._fetchedResultsController;
    }

    controllerDidChangeContent(controller:MIOFetchedResultsController) {        
        this.calulateRemainigAmount();
        this.tableView.reloadData();
    }

    private calulateRemainigAmount()
    {
        //TODO: remove this calculation and use the balance reference
        this._remainig_amount = 0;
        
        if (this.fetchedResultsController.fetchedObjects.length > 0) this._remainig_amount = 0;
        for( let e of this.fetchedResultsController.fetchedObjects as AccountingEntry[] ) {
            this._remainig_amount += (e.debitAmount - e.creditAmount);
        }
        
    };

    private lockEntries(){
        if (this._remainig_amount <= -0.001 || this._remainig_amount >= 0.001) {
            AppHelper.showErrorMessage(this, MIOLocalizeString("ERROR","ERROR"), MIOLocalizeString("THE ENTRIES ARE NOT BALANCED","THE ENTRIES ARE NOT BALANCED"));
            return;
        }

        if (this._journal_entry == null) {
            let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
            this._journal_entry = MIOEntityDescription.insertNewObjectForEntityForName( "AccountingJournalEntry", ad.managedObjectContext ) as AccountingJournalEntry;            
            this._item.journalEntry = this._journal_entry;
        }        

        for (let e of this.fetchedResultsController.fetchedObjects as AccountingEntry[]) {
            e.journalEntry = this._journal_entry;
        }

        this._journal_entry.locked = true;
        this._item.journalEntryLocked = this._journal_entry.locked;        
        
        DBHelper.saveMainContextWithCompletion(this, function(){
            this.updateUI();
        });
    }

    private selectAccountEntryTypeAction(){
        let avc = new MUIAlertViewController();
        avc.initWithTitle(MIOLocalizeString("ACTIONS","ACTIONS"), MIOLocalizeString("CHOOSE A TYPE","CHOOSE A TYPE"), MUIAlertViewStyle.Default);
 
        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString("SALES INVOICE","SALES INVOICE"), MUIAlertActionStyle.Default, this, function(){
            this.selectEntityViewController(AccountEntintyType.SalesInvoice);
        }));

        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString("PURSCHASE INVOICE","PURSCHASE INVOICE"), MUIAlertActionStyle.Default, this, function(){
            this.selectEntityViewController(AccountEntintyType.PurchaseInvoice);
        }));

        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString("CLIENT","CLIENT"), MUIAlertActionStyle.Default, this, function(){
            this.selectEntityViewController(AccountEntintyType.Client);
        }));

        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString("SUPPLIER","SUPPLIER"), MUIAlertActionStyle.Default, this, function(){
            this.selectEntityViewController(AccountEntintyType.Supplier);
        }));

        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString("CANCEL","CANCEL"), MUIAlertActionStyle.Cancel, null, null));

        this.presentViewController(avc, true);
    }

    private selectEntityViewController(type:AccountEntintyType){
        switch (type) 
        {   
            case AccountEntintyType.SalesInvoice:
                this.showSelectDocumentViewController(SelectDocumentViewControllerType.salesInvoice);
                break;

            case AccountEntintyType.PurchaseInvoice:
                this.showSelectDocumentViewController(SelectDocumentViewControllerType.purchaseInvoice);
                break;    

            case AccountEntintyType.Client:
                AppHelper.sharedInstance().showSelectClientViewControllerFromView(null, null, true, this, this.addClientAccountEntry, null, null);
                break;

            case AccountEntintyType.Supplier:
                AppHelper.sharedInstance().showSelectSupplierViewControllerFromView(null, null, true, this, this.addSupplierAccountEntry);
                break;
        }
    }

    private showSelectDocumentViewController(type:SelectDocumentViewControllerType){
        let vc = SelectDocumentViewController.newInstance();
        vc.documentType = type;
        this.presentViewController(vc, true);
    }

    private addClientAccountEntry(controller:any, client:Client){
        let account = client.ledgerAccount;
        this.addLedgerAccountEntry(controller, account);
    }

    private addSupplierAccountEntry(controller:any, supplier:Supplier){
        let account = supplier.ledgerAccount;
        this.addLedgerAccountEntry(controller, account);
    }

    private addPurchaseInvoiceAccountEntry(controller:any, purchaseInvoices:PurchaseInvoice[]){
        let supplier = purchaseInvoices[0].supplier;
        this.addLedgerAccountEntry(null, supplier.ledgerAccount);

        for (let i = 0; i < purchaseInvoices.length; i++){
            let pi = purchaseInvoices[i];
            this.addLedgerAccountEntry(null, pi.ledgerAccount);
        }
    }

    private addLedgerAccountEntry(account:LedgerAccount, reference:string, amount:number) {

        if ( account == null || amount == 0 ) return;

        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
        let entry = MIOEntityDescription.insertNewObjectForEntityForName( "AccountingEntry", ad.managedObjectContext ) as AccountingEntry;

        if (this._item instanceof BankMovement) {
            entry.date = this._item.date;
            entry.relationEntityType = this._item.entity.name;
        }        
        entry.relationEntityID = this._item.identifier;
        entry.account = account;
        entry.accountNumber = account.prefix;
        entry.accountName = account.name;        
        entry.reference = reference;
        entry.setAmount( amount, account );                            

        DBHelper.saveMainContext();

        // this._item.accountingBalance = this._remainig_amount;
        // this._item.accountingEntries = this.fetchedResultsController.fetchedObjects.length;
    }

    private deleteEntry(entry:AccountingEntry){
        DBHelper.deleteObjectFromMainContext(entry, true);

        // this._item.accountingBalance = this._remainig_amount;
        // this._item.accountingEntries = this.fetchedResultsController.fetchedObjects.length;
    }

    addEntryDidPress(cell: AccountingEntryCell): void {
        this.addLedgerAccountEntry(cell.account, cell.reference, cell.amount); 
    }

    amountDidUpdate(cell: AccountingEntryCell): void {
        this.calculateRemainingAmount();
        // this._item.accountingBalance = this._remainig_amount;        

        // Update add cell with the remaining amount
        let index_path = MIOIndexPath.indexForRowInSection(0, this.fetchedResultsController.sections.count);
        let add_cell = this.tableView.cellAtIndexPath(index_path) as AccountingEntryCell;
        if (add_cell) add_cell.amount = this._remainig_amount;
    }
}

enum AccountEntintyType 
{
    SalesInvoice,
    PurchaseInvoice,
    Client,
    Supplier,
    Loan
}
