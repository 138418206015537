
class SupplierNoteDetailViewController extends NoteDetailViewController
{
    private allReceiptButton:MUIButton = null;

    private noEditHeaderView:SupplierNoteDetailHeader = null;
    private editHeaderView:SupplierNoteDetailHeader = null;
    
    private warehouseLabel:MUILabel = null;
    private warehouseButton:MUIButton = null;

    private titleView:MUIView = null;
    private titleViewNoQuantityView:MUIView = null;

    viewDidLoad(){
        super.viewDidLoad();

        this.allReceiptButton = MUIOutlet(this, "all-received-btn", "MUIButton");
        this.allReceiptButton.setAction(this, function(){
            this.markAllAsReceivedAction();
        });

        this.noEditHeaderView = MUIOutlet(this, "no-edit-header-view", "SupplierNoteDetailHeader");
        this.editHeaderView = MUIOutlet(this, "edit-header-view", "SupplierNoteDetailHeader");

        this.warehouseLabel = MUIOutlet(this, "warehouse-lbl", "MUILabel");
        this.warehouseButton = MUIOutlet(this, "warehouse-btn", "MUIButton");
        this.setupWarehouseButton( this.warehouseButton, this, function (this:SupplierNoteDetailViewController, controller:SelectEntityViewController, warehouse:Warehouse) {
            this.stockNote.overrideWarehouse = warehouse;
            this.stockNote.overrideWarehouseName = warehouse != null ? warehouse.name : MIOLocalizeString("PRODUCT DEFAULT WAREHOUSE", "PRODUCT DEFAULT WAREHOUSE");
            this.warehouseLabel.text = this.stockNote.overrideWarehouseName;
            this.applyOverrideWarehose(warehouse);
        });

        this.searchTextField = MUIOutlet(this, "search-bar", "MUITextField");
        this.setupSearchTextField();

        this.titleView = MUIOutlet(this, "titlebar", "MUIView");
        this.titleViewNoQuantityView = MUIOutlet(this, "titlebar-no-orderquantity", "MUIView");        
    }

    protected updateUI(){        
        this.allReceiptButton.hidden = this.stockNote.status == StockNoteStatus.Pending ? false : true;
        this.editHeaderView.note = this.stockNote;
        this.noEditHeaderView.note = this.stockNote;
        this.editHeaderView.hidden = this.stockNote.status == StockNoteStatus.Processed ? true : false;
        this.noEditHeaderView.hidden = this.stockNote.status == StockNoteStatus.Processed ? false : true;        
        
        this.warehouseLabel.text = this.stockNote.overrideWarehouseName ?? MIOLocalizeString("PRODUCT DEFAULT WAREHOUSE", "PRODUCT DEFAULT WAREHOUSE");
        this.warehouseButton.hidden = this.stockNote.status == StockNoteStatus.Processed ? true : false;

        this.titleView.hidden = this.stockNote.status == StockNoteStatus.None ? true : false;
        this.titleViewNoQuantityView.hidden = this.stockNote.status == StockNoteStatus.None ? false : true;
        
        this.invalidateData();
        this.tableView.reloadData();
    }

    set fetchedResultsController(value){
        if (value == null && this._fetchedResultsController != null)
            this._fetchedResultsController.delegate = null;
    
        this._fetchedResultsController = value;
    }

    get fetchedResultsController(){
        if (this._fetchedResultsController != null)
            return this._fetchedResultsController;
        
        let ad = MUIWebApplication.sharedInstance().delegate;

        let sortDescriptors = [MIOSortDescriptor.sortDescriptorWithKey('orderIndex', true)];

        let predicateFormat = 'note.identifier == ' + this.stockNote.identifier;
        if (this.searchString != null) {
            predicateFormat += " AND productName CONTAINS '" + this.searchString + "'";
        }

        let fetchRequest = DBHelper.listFetchRequestWithEntityName("SupplierNoteLine", sortDescriptors, predicateFormat);
        fetchRequest.relationshipKeyPathsForPrefetching = ["tax", "taxBuy", "inputFormat", "product", "product.defaultWarehouse"];
        
        let fetchedResultsController = new MIOFetchedResultsController();
        fetchedResultsController.initWithFetchRequest(fetchRequest, ad.managedObjectContext, null);
        fetchedResultsController.delegate = this;
    
        fetchedResultsController.performFetch();
    
        this._fetchedResultsController = fetchedResultsController;    
        return this._fetchedResultsController;
    }
    
    // controllerDidChangeContent(controller:MIOFetchedResultsController){
    //     super.controllerDidChangeContent(controller);
    //     this.updateTotal();        
    // }

    //
    // NoteLine Delegate
    //

    supplierFromNote(sender){
        return this.stockNote.originEntity;
    }
    
    private markAllAsReceivedAction(){
        let lines = this.fetchedResultsController.fetchedObjects;
        for (let index = 0; index < lines.length; index++){
            let l = lines[index] as StockNoteLine;
            l.quantity = l.estimatedQuantity;
            l.price = l.estimatedPrice;
                        
            // [l.productQuantity, l.measureQuantity] = DBHelper.calculateQuantityFromStockLine(l.quantity, l.inputFormat, l.measureType, l.productMeasureType, l.productContainerMeasureType, l.productContainerQuantity);        
            // [l.total, l.discountValue] = DBHelper.calculateTotalFromStockLine(l.quantity, l.price, l.discountString);    
        }
        
        DBHelper.saveMainContext();        
    }

    protected setupWarehouseButton(button:MUIButton, target, completion){
        if (button == null) return;
        button.setAction(this, function(){
            AppHelper.sharedInstance().showSelectWarehouseViewControllerFromView(button, this.stockNote.overrideWarehouse, true, false, target, completion);
        });   
    }

    private applyOverrideWarehose(warehouse:Warehouse) {
        for (let index = 0; index < this.fetchedResultsController.fetchedObjects.length; index++) {
            let line = this.fetchedResultsController.objectAtIndexPath(MIOIndexPath.indexForRowInSection(index, 0)) as StockNoteLine;
            
            if (warehouse != null) {
                line.warehouse = warehouse;
                line.warehouseName = warehouse.name;
            }
            else if (line.product.defaultWarehouse != null){ 
                line.warehouse = line.product.defaultWarehouse;
                line.warehouseName = line.product.defaultWarehouse.name;
            }
            else {
                line.warehouse = AppHelper.sharedInstance().defaultWarehouse;
                line.warehouseName = AppHelper.sharedInstance().defaultWarehouse?.name;
            }
        }
        DBHelper.saveMainContext();
        // this.tableView.reloadData();
    }    

    protected customActionsForStatus(status:StockNoteStatus) : any[]{
        
        let actions = [];

        if (status == StockNoteStatus.Processed) {
            let item = {"Type": "Action", "Action" : this.invoiceAction};
            actions.addObject(item);
        }

        if (status == StockNoteStatus.None || status == StockNoteStatus.Pending || status == StockNoteStatus.Unprocessed){
            let deleteItem = {"Type": "Action", "Action": this.deleteAction};
            actions.addObject(deleteItem);
        }
        // else if (status == StockNoteStatus.Processed){
        //     let syncItem = {"Type": "Action", "Action" : this.syncAction};
        //     actions.addObject(syncItem);
        // }

        return actions;
    }

    get invoiceAction(){
        return MUIAlertAction.alertActionWithTitle(MIOLocalizeString('GENERATE PURCHASE INVOICE', 'GENERATE PURCHASE INVOICE'), MUIAlertActionStyle.Default, this, function(){        
            this.showInvoiceFields();
        });   
    }

    private showInvoiceFields() {
        let avc = new MUIAlertViewController();
        avc.initWithTitle(MIOLocalizeString("INFO", "INFO"), MIOLocalizeString("PLEASE FILL THE REQUIRED INFO", "PLEASE FILL THE REQUIRED INFO"), MUIAlertViewStyle.Default);

        avc.addTextFieldWithConfigurationHandler( this, function(this:SupplierNoteDetailViewController, textField:MUITextField) {
            if (this.stockNote.externalReference != null) textField.text = this.stockNote.externalReference;
            else textField.placeholderText = MIOLocalizeString("DOCUMENT NUMBER", "DOCUMENT NUMBER");
        });

        avc.addTextFieldWithConfigurationHandler( this, function(this:SupplierNoteDetailViewController, textField:MUITextField) {
            let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
            if (this.stockNote.stockDate != null) textField.text = ad.dateTimeFormatter.stringFromDate( this.stockNote.stockDate );
            else textField.placeholderText = MIOLocalizeString("DOCUMENT DATE", "DOCUMENT DATE");
        });

        avc.addAction( MUIAlertAction.alertActionWithTitle(MIOLocalizeString("OK", "OK"), MUIAlertActionStyle.Default, this, function(this:SupplierNoteDetailViewController){
            let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
            let ref = avc.textFields[0].text;
            let date = ad.dateTimeFormatter.dateFromString(avc.textFields[1].text);

            this.generateInvoice(ref, date);
        }));

        avc.addAction( MUIAlertAction.alertActionWithTitle(MIOLocalizeString("OK", "OK"), MUIAlertActionStyle.Default, null, null) );

        this.presentViewController(avc, true);
    }

    private generateInvoice(reference:string, date:Date){
        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
        ad.webService.createInvoiceFromMultipleSupplierNotes([this.stockNote], this.stockNote.originEntity as Supplier, reference, date, (result:boolean) => {
            if (result) AppHelper.showInfoMessage(this, MIOLocalizeString("INFO", "INFO"), MIOLocalizeString("THE INVOICE WAS CREATED", "THE INVOICE WAS CREATED"));
            else AppHelper.showErrorMessage(this, MIOLocalizeString("ERROR", "ERROR"), MIOLocalizeString("SOMETHING WENT WRONG!", "SOMETHING WENT WRONG!"));
        });
    }

    overrideWarehouse() : Warehouse {
        return this.stockNote.overrideWarehouse;
    }

}