class BuyOrderListViewController extends NoteListViewController
{
    protected get sectionControllerID():string {
        return SectionViewControllerType.BuyOrder;
    }

    viewWillAppear(animated:boolean){
        super.viewWillAppear(animated);

        //use the notification for the processed stockNote, recieve the object(the processed stockNote) and if "processed" reload listView
        MIONotificationCenter.defaultCenter().addObserver(this, "StockNoteDidProcess", function(notification:MIONotification){
            let note = notification.object;
            this.fetchedResultsController = null;
            this.tableView.reloadData();
        });
    }

    viewWillDisappear(animated:boolean){
        super.viewWillDisappear(animated);
        MIONotificationCenter.defaultCenter().removeObserver(this, "StockNoteDidProcess");
    }

    cellAtIndexPath(tableView:UITableView, indexPath:MIOIndexPath){
        let cell = tableView.dequeueReusableCellWithIdentifier('BuyOrderCell') as BuyOrderCell;
        let item = this.fetchedResultsController.objectAtIndexPath(indexPath) as StockNote;
    
        cell.item = item;
        cell.selected = this.selectedNote == item ? true : false;

        return cell;
    }
        
    set fetchedResultsController(value){
        this.setFetchedResultsController(value);
    }

    get fetchedResultsController(){
        if (this._fetchedResultsController != null) return this._fetchedResultsController;
    
        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
    
        let sortDescriptors = [MIOSortDescriptor.sortDescriptorWithKey("status", true), 
                                MIOSortDescriptor.sortDescriptorWithKey("creationDate", false)];
                
        let predicateFormat = this.selectedStatus != 0 ? "status == " + this.selectedStatus : "status == 0 OR status == 3";
        predicateFormat += " AND placeID == '" + ad.selectedIdentifier + "'";
                        
        if(this.searchString != null) {
            predicateFormat += " AND documentID CONTAINS '" + this.searchString + "'";
		}
        
		if (this.filterPredicateFormat != null) {
            predicateFormat += " AND " + this.filterPredicateFormat;
        }

        let fetchRequest = DBHelper.listFetchRequestWithEntityName("BuyOrder", sortDescriptors, predicateFormat);
        fetchRequest.fetchLimit = 50;
        //fetchRequest.relationshipKeyPathsForPrefetching = ['warehouseOrigin', 'warehouseDestination'];

        let fetchedResultsController = new MIOFetchedResultsController();
        fetchedResultsController.initWithFetchRequest(fetchRequest, ad.managedObjectContext, null);
        fetchedResultsController.delegate = this;
    
        fetchedResultsController.performFetch();
    
        this._fetchedResultsController = fetchedResultsController;    
        return this._fetchedResultsController;
    }
    
    controllerDidChangeContent(controller){
        this.tableView.reloadData();
	}
	
	private _filters = null;
    get filters(){
        if (this._filters != null) return this._filters;

		this._filters = [FilterOption.filterWithTitle("FromDate", "From day", "validationDate", FilterFieldType.Date, null, FilterControllerComparatorType.MajorOrEqualComparator, null, "00:00:00"),
		FilterOption.filterWithTitle("ToDate", "To day", "validationDate", FilterFieldType.Date, null, FilterControllerComparatorType.MinorOrEqualComparator, null, "23:59:59")];

        return this._filters;
    }
        
    protected showSelectedItem(){
        
        let vc = new BuyOrderDetailViewController("buy-order-detail-view");
        vc.initWithResource('layout/stock_buy_orders/BuyOrderDetailView.html');                   
        
        vc.note = this.selectedNote;
        this.splitViewController.showDetailViewController(vc);
    }

    protected showNoItemSelected(){
        let vc = AppHelper.sharedInstance().emptyViewControllerForIndentifier("BuyOrder", NoItemSelectedViewControllerType.BuyOrder);
        this.splitViewController.showDetailViewController(vc);
    }

    protected showAddNoteViewController(){
        let avc = new MUIAlertViewController();
        avc.initWithTitle(MIOLocalizeString("ADD BUY ORDER", "ADD BUY ORDER"), null, MUIAlertViewStyle.Default);
        
        avc.addComboBoxWithConfigurationHandler(this, function(comboBox:MUIComboBox){
            DBHelper.sharedInstance().addObserverForEntity(avc, "StockTemplate", null, null, null, function (objects) {
                comboBox.removeAllItems();
                comboBox.addItem(MIOLocalizeString("NO TEMPLATE", "NO TEMPLATE"), -1);
                let sortObjects = _MIOSortDescriptorSortObjects(objects, [MIOSortDescriptor.sortDescriptorWithKey("name", true)]);
                for (let i = 0; i < sortObjects.length; i++){
                    let id = sortObjects[i].identifier;
                    comboBox.addItem(sortObjects[i].name, i);
                }
            });  
        });

        avc.addComboBoxWithConfigurationHandler(this, function(comboBox:MUIComboBox){
            DBHelper.sharedInstance().addObserverForEntity(avc, "Warehouse", null, null, null, function (objects:Warehouse[]) {
                comboBox.removeAllItems();
                comboBox.addItem(MIOLocalizeString("PRODUCT DEFAULT WAREHOUSE", "PRODUCT DEFAULT WAREHOUSE"), -1);
                let sortObjects = _MIOSortDescriptorSortObjects(objects, [MIOSortDescriptor.sortDescriptorWithKey("name", true)]);
                for (let i = 0; i < sortObjects.length; i++){                    
                    comboBox.addItem(sortObjects[i].name, i);
                }
            });  
        });

        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString("DONE", "DONE"), MUIAlertActionStyle.Default, this, function(this:BuyOrderListViewController){            
            let templateIndex = avc.comboBoxes[0].getSelectedItem();
            let template = templateIndex == '-1' ? null : DBHelper.sharedInstance().objectAtIndexForEntityObserver(avc, templateIndex, 'StockTemplate');

            let warehouseIndex = avc.comboBoxes[1].getSelectedItem();
            let warehouse = warehouseIndex == '-1' ? null : DBHelper.sharedInstance().objectAtIndexForEntityObserver(avc, warehouseIndex, 'Warehouse');

            this.createStockNote(template, warehouse);
        }));

        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString('CANCEL', "CANCEL"), MUIAlertActionStyle.Cancel, null, null));
        avc.addCompletionHandler(this, function () {
            // Release the observers
            DBHelper.sharedInstance().removeObserverForChangesInEntity(avc, 'StockTemplate');
            DBHelper.sharedInstance().removeObserverForChangesInEntity(avc, 'Warehouse');
        });
        this.presentViewController(avc, true);
    }

    protected createStockNote(template:StockTemplate, warehouse:Warehouse){
        DBHelper.createStockNote(StockNoteType.BuyOrder, template, (note:BuyOrder) => {
            this.selectedNote = note;
            this.selectedNote.overrideWarehouse = warehouse;
            this.selectedNote.overrideWarehouseName = warehouse?.name;
            DBHelper.saveMainContext();                
            this.showSelectedItem();    
        });
    }
}