
class BuyOrderLineCell extends NoteLineCell
{
    private supplierDropdown:MUIButton = null;
    private supplierLabel:MUILabel = null;
    private estimatedLabel:MUILabel = null;
    protected maxStockQuantity = null;
    protected minStockQuantity = null;
    
    awakeFromHTML(){
        super.awakeFromHTML();

        this.supplierDropdown = MUIOutlet(this, "supplier-dd", "MUIButton");    
        this.setupSupplierDropdown(this.supplierDropdown);
        this.supplierLabel = MUIOutlet(this, "supplier-lbl", "MUILabel");    

        this.reset();
    }

    private supplier:Supplier = null;
    setLine(item:StockNoteLine){
        super.setLine(item);                
        this.supplier = item.legalEntity as Supplier;

        if (this.supplierDropdown) {
            this.supplierDropdown.enabled = true;
            this.supplierDropdown.title = this.supplier?.name;
        }

        if (this.supplierLabel) this.supplierLabel.text = this.supplier?.name;
    }
    
    productDidSelect(controller:SelectEntityViewController, product:Product, supplierProduct?:SupplierProduct){
        super.productDidSelect(controller, product, supplierProduct);

        this.supplierDropdown.enabled = true;
        this.supplierDropdown.title = MIOLocalizeString("NONE", "NONE");

        if (product.supplierProducts.count > 0) {

            let ps = product.supplierProducts.sortedArrayUsingDescriptors( [ MIOSortDescriptor.sortDescriptorWithKey("updatedAt", false) ] );
            let sp:SupplierProduct = null;
            for ( let aux of ps) {
                if ( aux.inputFormat == product.defaultInputFormat ) {
                    sp = aux;
                    break;
                }
            }            

            if (sp == null) return;

            // let sp = ps[0]; // product.supplierProducts.objectAtIndex(0) as SupplierProduct;
            this.supplierDropdown.title = sp.supplier.name;
            this.supplier = sp.supplier;

            this.productNetPriceAmount = DBHelper.calculateCostFromSupplierProduct(product, sp);
            this.discountFormat = sp.discountString;

            this.priceAmount = this.productNetPriceAmount;
                
            this.priceTextField.enabled = true;
            this.priceTextField.text = this.cf.stringFromNumber(this.priceAmount);

            // UPDATE DEFAULT FORMAT
            this.inputFormat = product.defaultInputFormat;
            this.inputFormatDropdown.title = product.defaultInputFormatName;
        }

        //if min and max stock is set, then show estimated quantity
        // if (product.minStockQuantity && product.maxStockQuantity && product.minStockQuantity > product.existences) {
        //     this.estimatedLabel.text = Math.ceil(product.maxStockQuantity - product.existences) + " " + this.inputFormatString();
        // } else {
        //     this.estimatedLabel.text = "N/A";
        // }        
    }
    
    protected setupSupplierDropdown(button:MUIButton){
        if (button == null) return;
        button.setAction(this, function(){
            AppHelper.sharedInstance().showSelectSupplierViewControllerFromView(button, null, true, this, this.supplierDidSelect);
        });
    }

    protected supplierDidSelect(controller:SelectEntityViewController, supplier:Supplier){
        this.supplierDropdown.title = supplier.name;
        this.supplier = supplier;

        [this.productNetPriceAmount, this.discountFormat] = DBHelper.costFromProductAndSupplier(this.product, supplier);

        this.priceTextField.text = this.cf.stringFromNumber(this.productNetPriceAmount);    
        this.priceDidChange(this.productNetPriceAmount);

        this.updateStockLine();
    }

    enterDidPress(){
        if (this.stockNoteLine != null) return;        
        if (this.quantity == 0 || this.quantity == null) return;
                
        let line = DBHelper.createBuyOrderStockNoteLine(this.product, this.inputFormat, this.inputType, this.quantity, this.productQuantity, this.priceAmount, this.productNetPriceAmount, this.baseAmount, this.discountFormat, this.discountAmount, this.supplier, this.stockNote);
        if (line != null) {            
            line.legalEntityName = this.supplier?.name;
            line.legalEntity = this.supplier;
            this.lineDidInserted(line);
            this.reset();
        }
    }
    
    protected reset(){
        super.reset();

        this.supplier = null;

        if (this.supplierDropdown != null){
            this.supplierDropdown.title = null;
            this.supplierDropdown.enabled = false;
        }

        if (this.supplierLabel) this.supplierLabel.text = null;
    }

    private estimatedQuantityString(){
        //if min and max stock is set, then show estimated quantity
        // if (item.product.existences && item.product.minStockQuantity && item.product.maxStockQuantity && item.product.minStockQuantity > item.product.existences) {
    
        //     let itemBaseEstimate = Math.ceil(item.product.maxStockQuantity - item.product.existences);

        //     //sort thought the different measure types
        //     if (item.productMeasureType == 1 || item.productMeasureType == -1) {
        //         this.estimatedLabel.text =  itemBaseEstimate + " " + this.inputFormatString();
        //         if (item.inputFormatQuantity && item.inputFormatQuantity != 0) {
        //             //this.quantityTextField.text = Math.ceil( itemBaseEstimate / item.inputFormatQuantity );
        //             //this.quantity = Math.ceil( itemBaseEstimate / item.inputFormatQuantity );
        //         } else { 
        //             //this.quantityTextField.text = this.nf.stringFromNumber(itemBaseEstimate);
        //             //this.quantity =  itemBaseEstimate;
        //         }

        //     } else if (item.productMeasureType == 2) {
        //         this.estimatedLabel.text =  itemBaseEstimate + " " + this.inputFormatString();

        //     } else if (item.productMeasureType == 3) {
        //         this.estimatedLabel.text =  itemBaseEstimate + " " + this.inputFormatString();
                
        //     } else if (item.productMeasureType == 4) {
        //         this.estimatedLabel.text =  itemBaseEstimate + " " + this.inputFormatString();

        //     } else if (item.productMeasureType == 5) {
        //         this.estimatedLabel.text =  itemBaseEstimate + " " + this.inputFormatString();

        //     }else if (item.productMeasureType == -100) {
        //         this.estimatedLabel.text = "N/A";
                
        //     } else {
        //         this.estimatedLabel.text = "N/A";
        //     }
        // } else {
        //     this.estimatedLabel.text = "N/A";
        // }
        
        return this.nf.stringFromNumber(this.stockNoteLine.estimatedQuantity);
    }

    updateStockLine(){

        if (this.stockNoteLine != null) {
            this.stockNoteLine.legalEntity = this.supplier;
            this.stockNoteLine.legalEntityName = this.supplier.name;
        }

        super.updateStockLine();
    }
}