

interface SelectEntityViewControllerDelegate 
{
    fetchRequestForController(controller:SelectEntityViewController):MIOFetchRequest;

    didSelectObjectFromSelectViewController(controller:SelectEntityViewController, item:MIOManagedObject):boolean;
    didSelectObjectsFromSelectViewController(controller:SelectEntityViewController, items:MIOSet):boolean;    
}
class SelectEntityViewController extends MUIViewController 
{
    identifier = null;    

    delegate:SelectEntityViewControllerDelegate = null;
    allowSearch = false;
    allowMultipleSelection = false;      
    noSelectionCell = false;
    modal = true;

    allowAddEntity = false;
    addTarget = null;
    addBlock = null;
    
    titleKey:string = null;
    defaultKey = null;
    context = null;

    protected closeButton:MUIButton = null;    
    protected doneButton:MUIButton = null;
    protected addButton:MUIButton = null;
    protected searchTextField:MUITextField = null;
    protected tableView:UITableView = null;
    
    private selectedObjects = MIOSet.set();
    selectedObject = null;    

    get preferredContentSize(){
        return new MIOSize(320, 500);
    }

    viewDidLoad() {
        super.viewDidLoad();

        this.closeButton = MUIOutlet(this, "close-btn", "MUIButton");
        this.closeButton.setAction(this, function(){
            this.dismissViewController(true);
        });

        this.doneButton = MUIOutlet(this, "done-btn", "MUIButton");
        this.doneButton.setAction(this, function(){
            
            // if (this.selectedObjects.count == 0 && this.selectedObject == null){
            //     AppHelper.showAlert(this, "Warning", "You didn't selected anything");
            //     return;
            // }

            if (this.delegate != null){
                let dismiss = this.delegate.didSelectObjectsFromSelectViewController(this, this.selectedObjects.allObjects);
                if (dismiss) this.dismissViewController(true);
            }
            else {
                this.dismissViewController(true);
            }
        });

        this.addButton = MUIOutlet(this, "add-btn", "MUIButton");
        this.addButton.setHidden(true);
        this.addButton.setAction(this, function(){
            if (this.addTarget == null || this.addBlock == null) return;
            this.addBlock.call(this.addTarget, this.searchString);
            this.dismissViewController(true);
        });

        this.searchTextField = MUIOutlet(this, "search-bar", "MUITextField");
        this.searchTextField.setOnChangeText(this, function(textfield, value){
            this.searchString = value;
        });
        
        this.tableView = MUIOutlet(this, "table-view", "UITableView");
        
        this.tableView.dataSource = this;
        this.tableView.delegate = this;          
    }

    viewWillAppear(animated?){
        super.viewWillAppear(animated);        
        this.closeButton.setHidden(this.modalPresentationStyle == MUIModalPresentationStyle.Popover ? true : false);
        this.doneButton.setHidden(!this.allowMultipleSelection);
        this.addButton.setHidden(!this.allowAddEntity);
        this.searchTextField.setHidden(!this.allowSearch);
        this.tableView.reloadData();
        this.selectFirstCell();
        
        this.registerKeyEvents();
    }

    viewWillDisappear(animated?){
        super.viewWillDisappear(animated);
        this.unregisterKeyEvents();
    }

    searchFormat:string = null;
    private delayTimer:MIOTimer = null;    
    private _searchString:string = null;    
    get searchString(){return this._searchString;}
    set searchString(value){
        if (value == null || value.length == 0) this._searchString = null;
        else this._searchString = value;
        if (this.searchTextField != null) this.searchTextField.text = this._searchString;
        if (this._fetchedResultsController == null) return;
        if (this.delayTimer != null) this.delayTimer.invalidate();
        this.delayTimer = MIOTimer.scheduledTimerWithTimeInterval(500, false, this, function(timer){
            this.delayTimer = null;
            this.fetchedResultsController = null;
            this.tableView.reloadData();
            this.selectFirstCell();
        });
    }

    private searchPredicateFormat():string {
        if (this.searchString == null) return null;
        if (this.searchFormat == null) return this.titleKey + " CONTAINS '" + this.searchString + "'";
        let re = /\%s/gi;
        let format = this.searchFormat.replace(re, this.searchString);
        return format;
    }

    pushNextViewController(vc:MUIViewController){
        this.navigationController.pushViewController(vc, true);
    }

    numberOfSections(tableview) {        
        return this.fetchedResultsController.sections.length;
    }
    
    numberOfRowsInSection(tableView:UITableView, section) {
        let sec = this.fetchedResultsController.sections[section];
        let rows = sec.numberOfObjects();
        if (this.noSelectionCell == true) rows++;
        return rows;
    }

    titleForHeaderInSection(tableView:UITableView, section:number){
        if (this.fetchedResultsController.sections.length < 2) return null;

        let sectionNameKeyPath = this.context ? this.context["SectionNameKeyPath"] : null;
        if (sectionNameKeyPath == null) return null;

        if (this.noSelectionCell == true && section == 0) return null;        

        let realIndexPath = this.noSelectionCell == false ? MIOIndexPath.indexForRowInSection(0, section) : MIOIndexPath.indexForRowInSection(1, section);
        let item = this.fetchedResultsController.objectAtIndexPath(MIOIndexPath.indexForRowInSection(0,section));

        return item.valueForKeyPath(sectionNameKeyPath);
    }
    
    cellAtIndexPath(tableview:UITableView, indexPath:MIOIndexPath) {        
        let cell = tableview.dequeueReusableCellWithIdentifier('SelectCell') as SelectCell;        
        cell.accessoryType = UITableViewCellAccessoryType.None;

        let realIndexPath = this.noSelectionCell == false ? indexPath : MIOIndexPath.indexForRowInSection(indexPath.row - 1, indexPath.section);
        
        if (this.noSelectionCell == true && indexPath.row == 0) {
            cell.title = "No " + this.fetchRequest.entityName;
            if (this.selectedObject == null) cell.accessoryType = UITableViewCellAccessoryType.Checkmark;
            return cell;
        }
        else {
            let item:MIOManagedObject = this.fetchedResultsController.objectAtIndexPath(realIndexPath);
            cell.title = this.valueForTitleKey(item);
        }
        
        if (this.allowMultipleSelection == true){
            let item:MIOManagedObject = this.fetchedResultsController.objectAtIndexPath(realIndexPath);
            if (this.selectedObjects.containsObject(item)) cell.accessoryType = UITableViewCellAccessoryType.Checkmark;
            else cell.accessoryType = UITableViewCellAccessoryType.None;
        }      
        else { 
            let item:MIOManagedObject = this.fetchedResultsController.objectAtIndexPath(realIndexPath);        
            cell.title = this.valueForTitleKey(item);
            if (this.selectedObject == item) cell.accessoryType = UITableViewCellAccessoryType.Checkmark;              
        }

        cell.selected = false;

        return cell;
    }

    private valueForTitleKey(item){
        let v = item.valueForKeyPath(this.titleKey);

        if (v == null && this.defaultKey != null) v = item.valueForKeyPath(this.defaultKey);

        return v;
    }
    
    private noSelectionItem = MIONull.nullValue();
    didSelectCellAtIndexPath(tableView:UITableView, indexPath:MIOIndexPath) {
    
        if (this.allowMultipleSelection == true) {

            let item = null;
            if (this.noSelectionCell == true && indexPath.row == 0) {
                item = this.noSelectionCell;
            }
            else if (this.noSelectionCell == true && indexPath.row > 0) {
                let ip = MIOIndexPath.indexForRowInSection(indexPath.row - 1, indexPath.section);
                item = this.fetchedResultsController.objectAtIndexPath(ip);
            }
            else {
                item = this.fetchedResultsController.objectAtIndexPath(indexPath);
            }
            
            if (this.selectedObjects.containsObject(item) == true){                
                this.selectedObjects.removeObject(item);
                let cell:UITableViewCell = tableView.cellAtIndexPath(indexPath);
                cell.accessoryType = UITableViewCellAccessoryType.None;
            }
            else {
                this.selectedObjects.addObject(item);
                let cell:UITableViewCell = tableView.cellAtIndexPath(indexPath);
                cell.accessoryType = UITableViewCellAccessoryType.Checkmark;
            }
        }
        else if (this.noSelectionCell == true && indexPath.row == 0) {            
            let dismiss = this.delegate.didSelectObjectFromSelectViewController(this, null);
            if (dismiss) this.dismissViewController(true);
        }
        else if (this.noSelectionCell == true && indexPath.row > 0) {
            let ip = MIOIndexPath.indexForRowInSection(indexPath.row - 1, indexPath.section);
            let item = this.fetchedResultsController.objectAtIndexPath(ip);
            let dismiss = this.delegate.didSelectObjectFromSelectViewController(this, item);
            if (dismiss) this.dismissViewController(true);
        }
        else {
            let item = this.fetchedResultsController.objectAtIndexPath(indexPath);
            let dismiss = this.delegate.didSelectObjectFromSelectViewController(this, item);
            if (dismiss) this.dismissViewController(true);                
        }

        tableView.deselectRowAtIndexPath(indexPath, true);
    }
    
    resetResultController(){
        if (this._fetchedResultsController != null)
            this._fetchedResultsController.delegate = null;    
        this._fetchedResultsController = null;
    }

    private _fetchRequest:MIOFetchRequest = null;
    get fetchRequest(){
        if (this._fetchRequest != null) return this._fetchRequest;

        this._fetchRequest = this.delegate.fetchRequestForController(this);
        return this._fetchRequest;
    }
    
    protected _fetchedResultsController:MIOFetchedResultsController = null;
    set fetchedResultsController(value) {
        if (value == null && this._fetchedResultsController != null)
            this._fetchedResultsController.delegate = null;
    
        this._fetchedResultsController = value;
    }
            
    get fetchedResultsController() {
        if (this._fetchedResultsController != null)
            return this._fetchedResultsController;

        let ad:AppDelegate = MUIWebApplication.sharedInstance().delegate;
        
        let fetchRequest = this.fetchRequest.copy();
        if (fetchRequest.relationshipKeyPathsForPrefetching == null) fetchRequest.relationshipKeyPathsForPrefetching = [];
        if (this.searchString != null) {        
            if (this.fetchRequest.predicate != null) {
                let predicateFormat = this.fetchRequest.predicate.predicateFormat;
                predicateFormat += " AND (" + this.searchPredicateFormat() + ")";
                fetchRequest.predicate = MIOPredicate.predicateWithFormat(predicateFormat);
            }
            else {
                let predicateFormat = this.searchPredicateFormat();
                fetchRequest.predicate = MIOPredicate.predicateWithFormat(predicateFormat);
            }
        }

        let sectionNameKeyPath = this.context ? this.context["SectionNameKeyPath"] : null;

        let frc = new MIOFetchedResultsController();
        frc.initWithFetchRequest(fetchRequest, ad.managedObjectContext, sectionNameKeyPath);
        frc.delegate = this;            

        frc.performFetch();
                
        this._fetchedResultsController = frc;
        return this._fetchedResultsController;
    }

    controllerDidChangeContent(controller) {
        this.tableView.reloadData();
        this.selectFirstCell();
    }

    private selectedIndexPath = null;
    private selectFirstCell(){
        this.selectedIndexPath = null;
        
        if (this.allowMultipleSelection == true) return;

        let ip = MIOIndexPath.indexForRowInSection(0, 0);
        let cell = this.tableView.cellAtIndexPath(ip);
        if (cell != null) {
            cell.selected = true;
            this.selectedIndexPath = ip;
        }
    }
    
    private selectNextIndexPath() {
        if (this.selectedIndexPath == null) return;        

        let sectionIndex = this.selectedIndexPath.section;
        let rowIndex = this.selectedIndexPath.row + 1;                

        let section = this.fetchedResultsController.sections[sectionIndex];
        if (rowIndex < section.numberOfObjects()) {
            this.tableView.deselectRowAtIndexPath(this.selectedIndexPath, true);        
            let ip = MIOIndexPath.indexForRowInSection(rowIndex, sectionIndex);            
            this.tableView.selectRowAtIndexPath(ip, true);
            this.selectedIndexPath = MIOIndexPath.indexForRowInSection(rowIndex, sectionIndex);
        }
        else {
            rowIndex = 0;
            sectionIndex++;
            if (sectionIndex < this.fetchedResultsController.sections.length) {
                this.tableView.deselectRowAtIndexPath(this.selectedIndexPath, true);        
                let ip = MIOIndexPath.indexForRowInSection(rowIndex, sectionIndex);
                this.tableView.selectRowAtIndexPath(ip, true);
                this.selectedIndexPath = MIOIndexPath.indexForRowInSection(rowIndex, sectionIndex);
            }
        }        
    }

    private selectPrevIndexPath() {
        if (this.selectedIndexPath == null) return;

        let sectionIndex = this.selectedIndexPath.section;
        let rowIndex = this.selectedIndexPath.row;

        if (sectionIndex == 0 && rowIndex == 0) return;

        rowIndex--;        

        if (rowIndex > -1) {
            this.tableView.deselectRowAtIndexPath(this.selectedIndexPath, true);
            let ip = MIOIndexPath.indexForRowInSection(rowIndex, sectionIndex);
            this.tableView.selectRowAtIndexPath(ip, true);
            this.selectedIndexPath = MIOIndexPath.indexForRowInSection(rowIndex, sectionIndex);
        }
        else {
            this.tableView.deselectRowAtIndexPath(this.selectedIndexPath, true);
            sectionIndex--;
            rowIndex = 0;                                            
            let ip = MIOIndexPath.indexForRowInSection(rowIndex, sectionIndex);
            this.tableView.selectRowAtIndexPath(ip, true);            
            this.selectedIndexPath = MIOIndexPath.indexForRowInSection(rowIndex, sectionIndex);
        }        
    }

    private registerKeyEvents(){
        if (this.allowMultipleSelection == true) return;
        if (this.noSelectionCell == true) return;

        MIOCoreEventRegisterObserverForType(MIOCoreEventType.KeyUp, this, function (ev:MIOCoreKeyEvent) {
            if (ev.keyCode == MIOCoreEventKeyCode.ArrowDown) {
                // Prevent propagation
                ev.cancel();                
                this.selectNextIndexPath();
            }        
            else if (ev.keyCode == MIOCoreEventKeyCode.ArrowUp) {
                // Prevent propagation
                ev.cancel();                
                this.selectPrevIndexPath();
            }
            else if (ev.keyCode == MIOCoreEventKeyCode.Enter) {
                // Prevent propagation
                ev.cancel();                
                let item = this.fetchedResultsController.objectAtIndexPath(this.selectedIndexPath);
                let dismiss = this.delegate.didSelectObjectFromSelectViewController(this, item);
                if (dismiss) this.dismissViewController(true);                    
            }        
        });
    }

    private unregisterKeyEvents(){
        if (this.allowMultipleSelection == true) return;
        if (this.noSelectionCell == true) return;
        MIOCoreEventUnregisterObserverForType(MIOCoreEventType.KeyUp, this);
    }

}
