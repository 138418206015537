
class SingleEditCell extends UITableViewCell
{
    enabledLocalizationButton:boolean = false;

    private titleTextField:MUITextField = null;
    private languageButton:MUIButton = null;

    awakeFromHTML(){
        this.titleTextField = MUIOutlet(this, "title-tf", "MUITextField");
        this.setupTextField(this.titleTextField);                

        this.languageButton = MUIOutlet(this, "language-btn", "MUIButton");
        this.languageButton.setAction(this, function(){
            let vc = ProductLanguageViewController.newInstance(); 
            vc.setItem(this._item, "name");
            
            AppHelper.sharedInstance().presentViewController( vc, true );
        });
        
        this.selectionStyle = UITableViewCellSelectionStyle.None;
    }

    private _item:MIOManagedObject = null;
    private _key:string = null;
    setItem(item:MIOManagedObject, key:string){
        this._key = key;
        this._item = item;

        this.titleTextField.text = item.valueForKey(key);
        this.languageButton.hidden = !this.enabledLocalizationButton;
    }

    private setupTextField(textField:MUITextField) {
        if (textField == null) return;

        textField.setOnChangeText(this, function (control:MUITextField, value:string){
            this.item.setValueForKey(value, this._key);
        });        
    }



}